import React from 'react';
import classNames from 'classnames';

export type HorizontalPosition =
	| { left: number }
	| { right: number }
	| { left: number; right: number };
export type VerticalPosition = { top: number } | { bottom: number };

export type DropdownPosition = HorizontalPosition & VerticalPosition;
export type DropdownSize = {
	width: number;
	height: number;
};

interface Props {
	position: DropdownPosition;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

const styles = {
	wrapper: (visible: boolean) =>
		classNames(
			'fixed',
			'z-1450',
			'top-0',
			'left-0',
			'w-full',
			'h-full',
			!visible && ['hidden', 'pointer-events-none']
		),
	dropdown: classNames(
		'fixed',
		'z-1450',
		'max-h-[calc(100%-1rem)]',
		'max-w-[calc(100%-1rem)]',
		'flex'
	),
};

export class DropdownContent extends React.PureComponent<Props> {
	private dropdown = React.createRef<HTMLDivElement>();

	public componentDidMount() {
		document.addEventListener('click', this.onClick);
		document.addEventListener('keydown', this.onKeyDown);
	}

	public componentWillUnmount() {
		document.removeEventListener('click', this.onClick);
		document.removeEventListener('keydown', this.onKeyDown);
	}

	private onClick = (e: MouseEvent) => {
		if (!this.props.open || !this.dropdown.current) {
			return;
		}

		let currentTarget = e.target;
		while (currentTarget) {
			if (currentTarget === this.dropdown.current) {
				return;
			}

			currentTarget = (currentTarget as HTMLElement).parentElement;
		}

		this.props.onClose();
	};

	private onKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			if (this.props.open) {
				this.props.onClose();
			}
		}
	};

	public calculateSize(): DropdownSize | null {
		if (!this.dropdown.current) {
			return null;
		}

		const dropdownDimensions = this.dropdown.current.getBoundingClientRect();

		return {
			width: dropdownDimensions.width,
			height: dropdownDimensions.height,
		};
	}

	public render() {
		const style: React.CSSProperties = {};

		if ('left' in this.props.position) {
			style.left = this.props.position.left;
		}

		if ('right' in this.props.position) {
			style.right = document.documentElement.clientWidth - this.props.position.right;
		}

		if ('top' in this.props.position) {
			style.top = this.props.position.top;
		} else {
			style.bottom = document.documentElement.clientHeight - this.props.position.bottom;
		}

		return (
			<div className={styles.wrapper(this.props.open)}>
				<div ref={this.dropdown} className={styles.dropdown} style={style}>
					{this.props.children}
				</div>
			</div>
		);
	}
}
