/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import React, { ComponentProps, forwardRef, useImperativeHandle } from 'react';
import { usePopper } from 'react-popper';
import { useSelect, UseSelectStateChange } from 'downshift';
import { useDisabled } from '../../contexts/disabledContext';
import { Description } from '../visualComponents/Description';
import { ErrorDescription } from '../visualComponents/ErrorDescription';
import { Label } from '../visualComponents/Label';
import { Option } from './Option';
import { OptGroup } from './OptGroup';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';
import { usePandaContext } from '../../contexts/pandaContext';

import classes from './Select.module.css';
import { useAriaId } from '../../hooks/useAriaId';

type Props = {
	/**
	 * Der `name` entspricht dem HTML-`name`-Attribut.
	 */
	name?: string;
	value: string;
	onChange: (value: string) => void;
	onBlur?: React.FocusEventHandler<HTMLElement>;
	error?: string;
} & AdditionalProps;

type ManagedProps = {
	/**
	 * Die Prop `managedField` kann genutzt werden, um die renderProps der
	 * [`ManagedForm`-Komponente](https://github.com/sipgate/web-apps/blob/main/shared/forms/Readme.md)
	 * entgegenzunehmen.
	 *
	 */
	managedField: {
		name?: string;
		value: string;
		setValue: (value: string) => void;
		onBlur: React.FocusEventHandler<HTMLElement>;
	} & ({ valid: true; error: null } | { valid: false; error: string });
} & AdditionalProps;

type AdditionalProps = {
	/**
	 * Der `title` befindet sich über dem eigentlichen Eingabefeld und
	 * beschreibt es mit 1-2 Wörtern (= semantisch das Label).
	 */
	title?: string;
	'aria-labelledby'?: string;
	'aria-label'?: string;
	/**
	 * Der `placeholder` ist sichtbar, wenn noch keine Option ausgewählt ist.
	 */
	placeholder?: string;
	/**
	 * Die `description` kann benutzt werden, um in einer zweiten Zeile
	 * unter dem `title` noch eine kurze Beschreibung hinzuzufügen.
	 */
	description?: string;
	disabled?: boolean;
	dataTestId?: string;
	children:
		| React.ReactElement<ComponentProps<typeof Option>>
		| React.ReactElement<ComponentProps<typeof OptGroup>>
		| (
				| React.ReactElement<ComponentProps<typeof Option>>
				| React.ReactElement<ComponentProps<typeof OptGroup>>
		  )[];
};

type Condition = 'open' | 'closed';

const styles = {
	div: classnames('group', 'w-full', 'relative'),
	button: (
		condition:
			| 'placeholder'
			| 'open'
			| 'error'
			| 'error-open'
			| 'error-selected'
			| 'selected'
			| 'disabled-placeholder'
			| 'disabled-selected'
	) =>
		classnames(
			'appearance-none',
			'select-none',
			'group',
			'flex',
			'items-center',
			'w-full',
			'h-40',
			'p-8',
			'm-0',
			'font-brand',
			'font-normal',
			'text-base/24',
			'text-left',
			'ring-1',
			'ring-inset',
			'box-border',
			'rounded-sm',
			'duration-150',
			'ease-in-out',
			'transition',
			'focus:outline-none',
			condition === 'placeholder' && [
				'text-neo-color-global-content-neutral-moderate',
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-neutral-moderate-default',
				'focus:ring-neo-color-global-border-primary-moderate-active',
				'focus:text-neo-color-global-content-neutral-intense',
				'hover:ring-neo-color-global-border-neutral-moderate-hover',
				'hover:text-neo-color-global-content-neutral-intense',
				'active:ring-neo-color-global-border-neutral-intense-active',
				'cursor-pointer',
			],
			condition === 'open' && [
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-primary-intense-active',
				'text-neo-color-global-content-neutral-intense',
			],
			condition === 'error' && [
				'text-neo-color-global-content-neutral-moderate',
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-neutral-moderate-default',
				'focus:ring-neo-color-global-border-critical-intense-active',
				'focus:text-neo-color-global-content-neutral-intense',
				'hover:ring-neo-color-global-border-critical-intense-hover',
				'hover:text-neo-color-global-content-neutral-intense',
				'active:ring-neo-color-global-border-critical-intense-active',
				'cursor-pointer',
			],
			condition === 'error-open' && [
				'text-neo-color-global-content-neutral-intense',
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-critical-moderate-active',
				'focus:ring-neo-color-global-border-critical-intense-active',
				'hover:ring-neo-color-global-border-critical-intense-hover',
				'active:ring-neo-color-global-border-critical-intense-active',
				'cursor-pointer',
			],
			condition === 'error-selected' && [
				'text-neo-color-global-content-neutral-intense',
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-neutral-moderate-default',
				'focus:ring-neo-color-global-border-critical-intense-active',
				'hover:ring-neo-color-global-border-critical-intense-hover',
				'active:ring-neo-color-global-border-critical-intense-active',
				'cursor-pointer',
			],
			condition === 'selected' && [
				'text-neo-color-global-content-neutral-intense',
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-neutral-moderate-default',
				'focus:ring-neo-color-global-border-primary-intense-active',
				'hover:ring-neo-color-global-border-neutral-moderate-hover',
				'active:ring-neo-color-global-border-neutral-intense-active',
				'cursor-pointer',
			],
			condition === 'disabled-placeholder' && [
				'text-neo-color-global-content-neutral-disabled',
				'bg-neo-color-global-background-neutral-soft-disabled',
				'cursor-not-allowed',
				'ring-neo-color-global-border-static-transparent',
			],
			condition === 'disabled-selected' && [
				'text-neo-color-global-content-neutral-disabled',
				'bg-neo-color-global-background-neutral-soft-disabled',
				'cursor-not-allowed',
				'ring-neo-color-global-border-static-transparent',
			]
		),

	selectedItem: classnames('grow', 'transition', 'duration-150', 'ease-in-out', 'truncate'),
	placeholder: classnames('italic', 'font-light'),
	iconGroup: classnames(
		'ml-4',
		'flex',
		'flex-none',
		'items-center',
		'gap-4',
		'float-right',
		'self-center'
	),
	iconContainer: classnames('h-24', 'w-24', 'flex', 'items-center', 'justify-center'),
	animatedIconContainer: (iconCondition: 'open' | 'closed' | 'disabled') =>
		classnames(
			'h-24',
			'w-24',
			'transition',
			'duration-150',
			'ease-in-out',
			'flex',
			'items-center',
			'justify-center',
			iconCondition === 'open' && ['scale-y-flip'],
			iconCondition === 'disabled' && ['text-neo-color-global-content-neutral-disabled']
		),
	optionList: (condition: Condition) =>
		classnames(
			classes.transitionProperties,
			classes.maxHeight,
			'absolute',
			'z-800',
			'flex',
			'flex-col',
			'items-center',
			'w-full',
			'overflow-x-hidden',
			'overflow-y-scroll',
			'list-none',
			'm-0', // Popper dynamically sets mb-4 (4px) as offset
			'px-0',
			'py-4',
			'shadow',
			'bg-neo-color-global-surface-menu',
			'ring-1',
			'ring-inset',
			'ring-neo-color-global-border-neutral-soft-default',
			'rounded-sm',
			'focus:outline-none',
			'duration-150',
			'ease-in-out',
			condition === 'open' && ['visible'],
			condition === 'closed' && ['invisible']
		),
	group: classnames('w-full', 'p-0'),
	sublist: classnames('p-0'),
	groupHeader: classnames(
		'px-8',
		'pt-8',
		'text-xs',
		'text-neo-color-global-content-neutral-moderate',
		'select-none'
	),
	option: (listItemCondition: 'default' | 'disabled' | 'highlighted') =>
		classnames(
			'px-[0.4375rem]',
			'py-8',
			'duration-150',
			'ease-in-out',
			'focus:outline-none',
			'focus-visible:outline-none',
			'font-brand',
			'font-normal',
			'text-base/24',
			'select-none',
			'text-left',
			'transition',
			'w-[calc(100%-0.125rem)]',
			'h-40',
			'flex',
			'shrink-0',
			'whitespace-nowrap',
			listItemCondition === 'default' && [
				'bg-neo-color-global-surface-menu',
				'text-neo-color-global-content-neutral-intense',
				'cursor-pointer',
			],
			/* The following condition 'highlighted' is our custom build :hover CSS state therefore some CSS states do not work */
			listItemCondition === 'highlighted' && [
				'bg-neo-color-global-background-primary-soft-hover',
				'text-neo-color-global-content-neutral-intense',
				'cursor-pointer',
				'active:bg-neo-color-global-background-primary-soft-active',
				'active:text-neo-color-global-content-neutral-intense',
			],
			listItemCondition === 'disabled' && [
				'bg-neo-color-global-background-neutral-soft-disabled',
				'cursor-not-allowed',
				'text-neo-color-global-content-neutral-disabled',
			]
		),
	optionLabel: classnames('grow', 'truncate', 'block'),
};

const Select = forwardRef(
	(
		{
			children,
			description,
			disabled: disabledProp,
			title,
			'aria-labelledby': ariaLabelledby,
			'aria-label': ariaLabel,
			placeholder,
			dataTestId,
			error,
			onChange: setValue,
			value,
			name,
			onBlur,
		}: Props,
		forwardedRef: React.ForwardedRef<{ focus: () => void }>
	): JSX.Element => {
		const id = useAriaId('select');
		const disabled = useDisabled(disabledProp);
		const { languageKeys } = usePandaContext();
		const [isOpen, setIsOpen] = React.useState(false);
		const toggleButtonRef = React.useRef<HTMLDivElement>(null);
		const popperElementRef = React.useRef(null);
		const { styles: popperStyles, update: updatePopper } = usePopper(
			toggleButtonRef.current,
			popperElementRef.current,
			{
				placement: 'bottom',
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 4],
						},
					},
				],
			}
		);

		useImperativeHandle(
			forwardedRef,
			() => ({
				focus: () => {
					toggleButtonRef.current?.focus();
				},
			}),
			[]
		);

		React.useLayoutEffect(() => {
			if (updatePopper) {
				updatePopper();
			}
		}, [updatePopper, children]);

		const childOptions = React.Children.toArray(children)
			.filter(
				/* prettier-ignore */ (React.isValidElement)<ComponentProps<typeof Option> | ComponentProps<typeof OptGroup>>
			)
			.flatMap(child => {
				if (child.type === Option) {
					return [child as React.ReactElement<ComponentProps<typeof Option>>];
				}

				if (child.type === OptGroup) {
					return React.Children.toArray(child.props.children).filter(
						/* prettier-ignore */ (React.isValidElement)<ComponentProps<typeof Option>>
					);
				}

				return [];
			});

		const itemValues = childOptions.map(item => {
			return item.props.value;
		});

		const onIsOpenChange = (changed: UseSelectStateChange<string>) => {
			setIsOpen(!!changed.isOpen);
		};

		const onSelectedItemChange = (changes: UseSelectStateChange<string>) => {
			if (changes.selectedItem !== undefined && changes.selectedItem !== null) {
				setValue(changes.selectedItem);
			}
		};

		const {
			selectedItem,
			getToggleButtonProps,
			getLabelProps,
			getMenuProps,
			highlightedIndex,
			getItemProps,
		} = useSelect({
			items: itemValues,
			selectedItem: value,
			isItemDisabled: (_, i) => !!childOptions[i].props.disabled,
			onSelectedItemChange: v => {
				/**
				 * downshift triggers this inside its render loop which breaks, because
				 * formik rerenders immediately resulting in a deadlock:
				 *
				 * https://github.com/downshift-js/downshift/issues/1447
				 */
				setTimeout(() => onSelectedItemChange(v), 0);
			},
			isOpen,
			onIsOpenChange,
		});

		const selectedItemText = childOptions.find(option => selectedItem === option.props.value)?.props
			.children;

		const getButtonCondition = () => {
			if (disabled) {
				if (selectedItemText) {
					return 'disabled-selected';
				}

				return 'disabled-placeholder';
			}

			if (error) {
				if (isOpen) {
					return 'error-open';
				}

				if (selectedItemText) {
					return 'error-selected';
				}

				return 'error';
			}

			if (isOpen) {
				return 'open';
			}

			if (selectedItemText !== undefined) {
				return 'selected';
			}

			return 'placeholder';
		};

		const getIconCondition = () => {
			if (disabled) {
				return 'disabled';
			}

			return isOpen ? 'open' : 'closed';
		};

		const renderOption = (
			option: React.ReactElement<ComponentProps<typeof Option>>,
			index: number
		) => {
			const getListItemCondition = () => {
				if (disabled || !!option.props.disabled) {
					return 'disabled';
				}

				if (highlightedIndex === index) {
					return 'highlighted';
				}

				return 'default';
			};

			return (
				<div
					{...getItemProps({
						key: index,
						item: option.props.value,
						index,
						className: styles.option(getListItemCondition()),
					})}
				>
					<span className={styles.optionLabel}>{option.props.children}</span>
					{selectedItem === option.props.value ? (
						<div className={styles.iconGroup}>
							<span className={styles.iconContainer}>
								<PandaIcon icon="check-16" />
							</span>
						</div>
					) : null}
				</div>
			);
		};

		const renderOptionGroup = (
			group: React.ReactElement<ComponentProps<typeof OptGroup>>,
			index: number
		) => {
			const groupId = `${id}-${index}`;

			return (
				<section role="group" aria-labelledby={groupId} key={index} className={styles.group}>
					<header role="presentation" className={styles.groupHeader} id={groupId}>
						{group.props.label}
					</header>

					<div className={styles.sublist}>
						{React.Children.map(group.props.children, (option, i) =>
							renderOption(option as React.ReactElement<ComponentProps<typeof Option>>, index + i)
						)}
					</div>
				</section>
			);
		};

		return (
			<div data-testid={dataTestId} className={styles.div}>
				{title ? <Label {...getLabelProps()}>{title}</Label> : null}
				{description ? (
					<Description onClick={() => setIsOpen(true)}>{description}</Description>
				) : null}
				<div
					{...getToggleButtonProps({
						className: styles.button(getButtonCondition()),
						disabled,
						ref: toggleButtonRef,
						onBlur,
						name,
						...(ariaLabelledby && { 'aria-labelledby': ariaLabelledby }),
						...(ariaLabel && { 'aria-label': ariaLabel }),
					})}
				>
					<span className={styles.selectedItem}>
						{selectedItemText || (
							<span className={styles.placeholder}>
								{placeholder || languageKeys.PANDA_SELECT_PLACEHOLDER}
							</span>
						)}
					</span>

					<div className={styles.iconGroup}>
						{error ? (
							<span className={styles.iconContainer}>
								<PandaIcon icon="exclamation_mark_circle-16" />
							</span>
						) : null}

						<div className={classnames(styles.animatedIconContainer(getIconCondition()))}>
							<PandaIcon icon="triangle_down-16" />
						</div>
					</div>
				</div>
				<div
					{...getMenuProps({
						className: styles.optionList(isOpen ? 'open' : 'closed'),
						style: popperStyles.popper,
						ref: popperElementRef,
						...(ariaLabelledby && { 'aria-labelledby': ariaLabelledby }),
						...(ariaLabel && { 'aria-label': ariaLabel }),
					})}
				>
					{children &&
						React.Children.toArray(children)
							.filter(
								(
									child
								): child is
									| React.ReactElement<ComponentProps<typeof Option>>
									| React.ReactElement<ComponentProps<typeof OptGroup>> =>
									React.isValidElement(child) && (child.type === Option || child.type === OptGroup)
							)
							.reduce(
								(
									total,
									item:
										| React.ReactElement<ComponentProps<typeof Option>>
										| React.ReactElement<ComponentProps<typeof OptGroup>>
								) => {
									if (item.type === Option) {
										return {
											index: total.index + 1,
											nodes: [
												...total.nodes,
												renderOption(
													// Assertion is neccessary because typescript only deals with types and does not
													// understand that the item.type = check above asserts that this element is specifically
													// an Option element.
													item as React.ReactElement<ComponentProps<typeof Option>>,
													total.index
												),
											],
										};
									}

									return {
										index: total.index + React.Children.count(item.props.children),
										nodes: [
											...total.nodes,
											renderOptionGroup(
												// Assertion is neccessary because typescript only deals with types and does not
												// understand that the item.type = check above asserts that this element is specifically
												// an OptGroup element.
												item as React.ReactElement<ComponentProps<typeof OptGroup>>,
												total.index
											),
										],
									};
								},
								{
									index: 0,
									nodes: [] as React.ReactElement[],
								}
							).nodes}
				</div>
				{error ? (
					<ErrorDescription onClick={() => setIsOpen(true)}>{error}</ErrorDescription>
				) : null}
			</div>
		);
	}
);

const ManagedSelect = forwardRef(
	(
		{
			managedField: { error, setValue, value, name, onBlur },
			children,
			...otherProps
		}: ManagedProps,
		forwardedRef: React.ForwardedRef<{ focus: () => void }>
	) => (
		<Select
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...otherProps}
			name={name}
			value={value}
			onChange={setValue}
			onBlur={onBlur}
			error={error === null ? undefined : error}
			ref={forwardedRef}
		>
			{children}
		</Select>
	)
);

export { Select, ManagedSelect };
