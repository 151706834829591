import {
	PhoneNumber,
	PhoneNumberFormat,
	PhoneNumberType,
	PhoneNumberUtil,
} from 'google-libphonenumber';
import { memoize } from 'lodash';

const LOCALE_STORAGE_KEY = 'app-locale';

export const saveLocale = (locale: string) =>
	localStorage.setItem(LOCALE_STORAGE_KEY, JSON.stringify(locale));

const getLocale = () => {
	const token = localStorage.getItem(LOCALE_STORAGE_KEY);
	return token ? JSON.parse(token) : 'de_DE';
};

const phoneUtil = PhoneNumberUtil.getInstance();
const MIN_PHONENUMBER_LENGTH = 5;
const MIN_PHONENUMBER_E164_LENGTH = 10;
const allowedServiceNumbers: { [countryCode: string]: string[] } = {
	'49': ['1801', '1802', '1803', '1804', '1805', '1806', '18088', '700', '800'],
	'44': ['870', '845'],
};
const EWRCountries = [
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'HU',
	'IS',
	'IE',
	'IT',
	'LV',
	'LI',
	'LT',
	'LU',
	'MT',
	'NL',
	'NO',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'ES',
	'SE',
	'GB',
];

function getLanguageFromLocale(locale: string) {
	return locale.split(/[_-]/)[1];
}

export function isDirectDialNumber(phoneNumber: string) {
	return phoneNumber.length <= MIN_PHONENUMBER_LENGTH;
}

function getE164NumberByLanguage(phoneNumber: string, language: 'DE' | 'GB') {
	if (isDirectDialNumber(phoneNumber)) {
		return phoneNumber;
	}

	const sanitizedNumber =
		phoneNumber.match(/^[1-9][0-9]{1,2}/) && phoneNumber.length > MIN_PHONENUMBER_E164_LENGTH
			? `+${phoneNumber}`
			: phoneNumber;
	try {
		const parsedPhoneNumber = phoneUtil.parse(sanitizedNumber, language);
		return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);
	} catch (err) {
		return phoneNumber;
	}
}

const getE164NumberByDomain = {
	'sipgate.de': (num: string) => getE164NumberByLanguage(num, 'DE'),
	'sipgate.co.uk': (num: string) => getE164NumberByLanguage(num, 'GB'),
};

/** @deprecated, please use normalizePhonenumber() */
export function getE164Number(phoneNumber: string, domain: 'sipgate.de' | 'sipgate.co.uk') {
	return getE164NumberByDomain[domain](phoneNumber);
}

function isAllowedServiceNumbers(parsedPhoneNumber: PhoneNumber) {
	try {
		const internationalPrefix = parsedPhoneNumber.getCountryCode();
		if (!internationalPrefix) {
			return false;
		}

		const e164PhoneNumber = phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);

		return (
			!!allowedServiceNumbers[internationalPrefix] &&
			!!allowedServiceNumbers[internationalPrefix].find(allowedNationalPrefix =>
				e164PhoneNumber.startsWith(`+${internationalPrefix}${allowedNationalPrefix}`)
			)
		);
	} catch (err) {
		return false;
	}
}

export function isValidPhoneNumberType(parsedPhoneNumber: PhoneNumber) {
	try {
		const phoneNumberType = phoneUtil.getNumberType(parsedPhoneNumber);
		return (
			(phoneNumberType === PhoneNumberType.FIXED_LINE_OR_MOBILE ||
				phoneNumberType === PhoneNumberType.FIXED_LINE ||
				phoneNumberType === PhoneNumberType.MOBILE ||
				phoneNumberType === PhoneNumberType.VOIP ||
				phoneNumberType === PhoneNumberType.UAN ||
				phoneNumberType === PhoneNumberType.TOLL_FREE ||
				phoneNumberType === PhoneNumberType.VOICEMAIL ||
				isAllowedServiceNumbers(parsedPhoneNumber)) &&
			phoneNumberType !== PhoneNumberType.UNKNOWN
		);
	} catch (err) {
		return false;
	}
}

function isValidPhonenumberByLanguage(phoneNumber: string, language: 'DE' | 'GB') {
	try {
		if (typeof phoneNumber === 'undefined' || !phoneNumber.length) {
			return false;
		}

		if (/[^+0-9/(),-.\s]/.test(phoneNumber)) {
			return false;
		}

		if (phoneNumber.length <= MIN_PHONENUMBER_LENGTH) {
			return false;
		}

		const parsedPhoneNumber = phoneUtil.parse(phoneNumber, language);
		return isValidPhoneNumberType(parsedPhoneNumber);
	} catch (err) {
		return false;
	}
}

const isValidPhonenumberByDomain = {
	'sipgate.de': (num: string) => isValidPhonenumberByLanguage(num, 'DE'),
	'sipgate.co.uk': (num: string) => isValidPhonenumberByLanguage(num, 'GB'),
};

/** @deprecated, please use normalizePhonenumber() */
export function isValidPhonenumber(phoneNumber: string, domain: 'sipgate.de' | 'sipgate.co.uk') {
	return isValidPhonenumberByDomain[domain](phoneNumber);
}

function getLocalizedNumber(phoneNumber: string, language: 'DE' | 'GB') {
	try {
		if (!phoneNumber) return null;

		if (isDirectDialNumber(phoneNumber)) {
			return phoneNumber;
		}

		const sanitizedPhoneNumber = /^[1-9].*/.test(phoneNumber) ? `+${phoneNumber}` : phoneNumber;

		if (!isValidPhonenumberByLanguage(sanitizedPhoneNumber, language)) {
			return phoneNumber;
		}

		const parsedPhoneNumber = phoneUtil.parse(sanitizedPhoneNumber, language);
		const regionCode = phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);

		if (!regionCode) {
			return sanitizedPhoneNumber;
		}

		const phoneNumberFormat =
			language.toLowerCase() === regionCode.toLowerCase()
				? PhoneNumberFormat.NATIONAL
				: PhoneNumberFormat.INTERNATIONAL;

		return phoneUtil.format(parsedPhoneNumber, phoneNumberFormat).replace(/\s+/, '-');
	} catch (err) {
		return phoneNumber;
	}
}

// Lodashs memoize only memoizes based on first parameter, so lets
// do memoization based on domain by hand.
const localizedNumbersByDomain = {
	'sipgate.de': memoize(num => getLocalizedNumber(num, 'DE') || ''),
	'sipgate.co.uk': memoize(num => getLocalizedNumber(num, 'GB') || ''),
};

export function localizeNumber(phoneNumber: string, domain: 'sipgate.de' | 'sipgate.co.uk') {
	return localizedNumbersByDomain[domain](phoneNumber);
}

export function formatPhonenumberForScreenreader(number: string) {
	return number
		.replace(/[^\d+]+/g, ',')
		.replace(/(\d)/g, d => ` ${d}`)
		.trimStart();
}

export function getCountryCode(phoneNumber: string, language: 'DE' | 'GB') {
	try {
		const parsedPhoneNumber = phoneUtil.parse(phoneNumber, language);
		return phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);
	} catch (err) {
		return '??';
	}
}

function isMobileNumberByLanguage(phoneNumber: string, language: 'DE' | 'GB') {
	try {
		if (!phoneNumber || !isValidPhonenumberByLanguage(phoneNumber, language)) {
			return false;
		}

		const sanitizedPhoneNumber = /^[1-9].*/.test(phoneNumber) ? `+${phoneNumber}` : phoneNumber;
		const parsedPhoneNumber = phoneUtil.parse(sanitizedPhoneNumber, language);
		return phoneUtil.getNumberType(parsedPhoneNumber) === PhoneNumberType.MOBILE;
	} catch (err) {
		return phoneNumber;
	}
}

// Lodashs memoize only memoizes based on first parameter, so lets
// do memoization based on domain by hand.
const isMobileNumberByDomain = {
	'sipgate.de': memoize(num => isMobileNumberByLanguage(num, 'DE') || ''),
	'sipgate.co.uk': memoize(num => isMobileNumberByLanguage(num, 'GB') || ''),
};

export function isMobileNumber(phoneNumber: string, domain: 'sipgate.de' | 'sipgate.co.uk') {
	return isMobileNumberByDomain[domain](phoneNumber);
}

export function isAnonymous(phoneNumber: string) {
	return phoneNumber === 'anonymous' || phoneNumber === 'unknown';
}

/** @deprecated, please use normalizePhonenumber() */
export const sanitizeNumber = (phoneNumber: string) => {
	if (phoneNumber) {
		return phoneNumber.replace(/[^0-9+*#]/g, '');
	}

	return phoneNumber;
};
export function getCurrentCountryCode() {
	return phoneUtil.getCountryCodeForRegion(getLanguageFromLocale(getLocale()));
}

export function formatNumberForAPI(phonenumber: string, domain: 'sipgate.de' | 'sipgate.co.uk') {
	let normalized = phonenumber.trim();

	normalized = normalized.replace(/^\+/, '00');
	normalized = normalized.replace(/[^0-9]/g, '');

	if (isValidPhonenumber(normalized, domain)) {
		return getE164Number(normalized, domain);
	}

	if (normalized.length <= 4 && normalized[0] !== '0') {
		return normalized;
	}

	if (normalized.startsWith('0') && !normalized.startsWith('00')) {
		const countryCode = getCurrentCountryCode();

		return normalized.replace(/^0/, `+${countryCode}`);
	}

	if (/^0*$/.test(normalized)) {
		return `+${getCurrentCountryCode()}`;
	}

	return normalized.replace(/^0*/, '+');
}

function isEWRNumberByLanguage(number: string, language: 'DE' | 'GB') {
	const countryCode = getCountryCode(number, language);

	if (countryCode) {
		return EWRCountries.includes(countryCode);
	}

	return false;
}

// Lodashs memoize only memoizes based on first parameter, so lets
// do memoization based on domain by hand.
const isEWRNumberByDomain = {
	'sipgate.de': memoize(num => isEWRNumberByLanguage(num, 'DE') || false),
	'sipgate.co.uk': memoize(num => isEWRNumberByLanguage(num, 'GB') || false),
};

export function isEWRNumber(phoneNumber: string, domain: 'sipgate.de' | 'sipgate.co.uk') {
	return isEWRNumberByDomain[domain](phoneNumber);
}

export const isPotentialPhonenumber = (searchString: string) =>
	searchString.trim().length > 0 && !/[^(){}[\]*+\-_\d\s\\/]/.test(searchString);

export * from './normalize';
export * from './match';
