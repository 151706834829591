import classnames from 'classnames';
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';

import { Dropdown } from '../..';
import {
	ActionElement,
	AllActionElementDefinition,
	ButtonDefinition,
	ContextDefinition,
	LinkDefinition,
} from '../../components/ActionElement';
import { Button } from '../../components/button/Button';
import { HeadingBoundary } from '../../components/heading/HeadingBoundary';
import { Search } from '../../components/search/Search';
import { usePandaContext } from '../../contexts/pandaContext';
import { ViewHeader } from '../../viewComponents/ViewHeader';
import { ListNavAsList } from './ListNavAsList';
import { ListNavAsTable } from './ListNavAsTable';
import { ColumnMeta } from './types';

export type Props<T, Columns extends Record<string, ColumnMeta<T>>> = {
	/** Überschrift der View. Erscheint oben in einer Zeile mit den `actionElements`. */
	heading: string;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Hinzufügen oder Buchen.
	 *
	 * **LinkDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'link';
	 *  to: string;
	 *  label: string;
	 *  loud?: boolean;
	 *  direction?: 'internal' | 'external';
	 * }
	 * ```
	 * **ButtonDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'button';
	 *  onClick: () => void;
	 *  label: string;
	 *  disabled?: boolean;
	 *  loud?: boolean;
	 * }
	 * ```
	 */
	actionElements: (LinkDefinition | ButtonDefinition | ContextDefinition)[];
	/**
	 * Angabe der `columns` mit einem frei wählbaren `key`.
	 *
	 * * `sort` muss eine Sortierfunktion sein, welche an [Array.sort](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
	 *   übergeben werden kann und aufsteigend sortiert.
	 * * `isState` entscheidet, ob die Spalte `States` enthält, welche in der mobilen Ansicht vorne angezeigt werden sollen
	 *
	 * ```
	 * {
	 *  [key]: {
	 *   text: string;
	 *   sort?: (a: T, b: T) => number;
	 *   clip?: 'meta' | 'truncate';
	 *   size?: 'xsmall' | 'small' | 'medium' | 'large';
	 *   hidden?: boolean;
	 *   isState?: boolean;
	 *  }
	 * }
	 * ```
	 */
	columns: Columns;
	/**
	 * Übergib die Komponente die angezeigt werden soll. Falls du keine besonderen Wünsche hast, dann nutz unseren [EmptyState](../?path=/docs/components-emptystate-overview--docs)
	 * Der Safari kann nicht mit SVGs umgehen an denen nicht height="100%" steht, denkt also daran das an eure SVGs dran zu machen.
	 */
	emptyState: React.ReactNode;
	/**
	 * Array der Datensätze, die später in der ListNav dargestellt werden sollen.
	 * Beachte, dass die erste Spalte kein Array sein darf, sondern ein `string` sein muss, da daraus der Link für die Zeile gebaut wird.
	 * In allen anderen Spalten können auch Arrays verwendet werden. Je nach verfügbarem Platz werden mehr oder weniger angezeigt.
	 */
	items: T[];
	/**
	 * Anzahl der maximal anlegbaren Datensätze
	 */
	itemLimit?: number;
	/** Eine `.map`-Funktion zur Darstellung eines `items`. Benutze die
	 * `<ListNavColumn>`- und `<ListNavItem>`-Komponenten, um deine Daten für die Liste aufzuarbeiten.
	 */
	children: (item: T) => JSX.Element;
	pageSizes?: number[];
	enablePagination?: boolean;
	/**
	 * Übergib die Komponente die zwischen Headline und Suchfeld angezeigt werden soll.
	 */
	infoBox?: React.ReactNode;
} & (PropsWithSearch<T> | PropsWithoutSearch);

type PropsWithSearch<T> = {
	/**
	 * Eine Funktion, die die übergebenen Elemente anhand einer Nutzer:innen-Eingabe filtern kann.
	 * Wenn diese Funktion übergeben wird, zeigt die ListNavView eine Suche an.
	 */
	search: (term: string) => T[];
	/**
	 * Dieser Platzhalter ist sichtbar, wenn ein leeres Suchfeld angezeigt wird.
	 */
	searchPlaceholder: string;
};

type PropsWithoutSearch = {
	/**
	 * Eine Funktion, die die übergebenen Elemente anhand einer Nutzer:innen-Eingabe filtern kann.
	 * Wenn diese Funktion übergeben wird, zeigt die ListNavView eine Suche an.
	 */
	search?: undefined;
	/**
	 * Dieser Platzhalter ist sichtbar, wenn ein leeres Suchfeld angezeigt wird.
	 */
	searchPlaceholder?: undefined;
};

const defaultPageSize = 25;
const styles = {
	search: classnames('sm:max-w-[35rem]', 'mt-16', 'sm:mt-24'),
	emptyStateContent: classnames(
		'w-full',
		'h-full',
		'flex',
		'justify-center',
		'items-center',
		'mt-16',
		'sm:mt-40',
		'xl:mt-64'
	),
	pagination: classnames(
		'flex',
		'justify-center',
		'mt-16',
		'sm:mt-24',
		'items-center',
		'flex-wrap'
	),
	paginationInformation: classnames('text-lg', 'font-normal', 'mx-16'),
	paginationSettings: classnames(
		'text-lg',
		'gap-y-24',
		'font-normal',
		'lg:mx-32',
		'flex-auto',
		'md:flex-none',
		'items-center',
		'mx-0',
		'justify-around',
		'flex',
		'flex-wrap'
	),
	paginationSettingsLabel: classnames('mr-8', 'ml-32'),
	mobileContent: classnames('sm:hidden', 'mt-16'),
	desktopContent: classnames('hidden', 'sm:block'),
	paginationBackAndForward: classnames(
		'flex',
		'flex-initial',
		'flex-nowrap',
		'items-center',
		'justify-center'
	),
	paginationEntriesDropdown: classnames(
		'flex',
		'flex-initial',
		'flex-nowrap',
		'items-center',
		'justify-center'
	),
};

const ListNavView = <T, Columns extends Record<string, ColumnMeta<T>>>({
	heading,
	infoBox,
	actionElements,
	columns,
	emptyState,
	items,
	itemLimit,
	search,
	searchPlaceholder,
	children,
	enablePagination,
	pageSizes = [defaultPageSize, defaultPageSize * 2],
}: Props<T, Columns>): JSX.Element => {
	const [pageSize, setPageSize] = React.useState(pageSizes[0]);
	const [sortedField, setSortedField] = React.useState<keyof Columns>(Object.keys(columns)[0]);
	const [sortDirection, setSortDirection] = React.useState<'ascending' | 'descending'>('ascending');
	const [searchTerm, setSearchTerm] = React.useState('');
	const [offset, setOffset] = React.useState(0);

	const paginationPageSizeId = React.useId();
	const { languageKeys } = usePandaContext();

	useEffect(() => {
		setOffset(0);
	}, [searchTerm, setOffset]);

	const sortedActionElements = (elements: AllActionElementDefinition[]) => {
		const contextItem = elements.find(actionElement => actionElement.type === 'contextMenu');

		if (contextItem) {
			elements.push(elements.splice(elements.indexOf(contextItem), 1)[0]);
		}

		return elements;
	};

	const sortedAndFilteredItems = (search ? [...search(searchTerm)] : [...items]).sort((a, b) => {
		const sortItems = columns[sortedField as keyof Columns].sort;

		if (!sortItems) {
			return 0;
		}

		return sortItems(a, b) * (sortDirection === 'ascending' ? 1 : -1);
	});

	const sortedAndFilteredAndPagedItems = sortedAndFilteredItems.splice(
		enablePagination ? offset : 0,
		enablePagination ? pageSize : items.length
	);

	const showEmptyState = items.length === 0;

	const renderPagination = () => {
		if (!enablePagination) {
			return null;
		}

		if (items.length <= pageSize) {
			return null;
		}

		const decreasePage = () => {
			if (pageSize > offset) setOffset(0);
			else setOffset(offset - pageSize);
		};

		const increasePage = () => {
			setOffset(offset + pageSize);
		};

		return (
			<div className={styles.pagination}>
				<span className={styles.paginationSettings}>
					<div className={styles.paginationBackAndForward}>
						<Button
							icon="back"
							onClick={decreasePage}
							disabled={offset === 0}
							iconOnly
							size="large"
							variant="loud"
						/>
						<span className={styles.paginationInformation}>
							<b>
								{offset}-{offset + pageSize > items.length ? items.length : offset + pageSize}
							</b>{' '}
							{languageKeys.PANDA_PAGINATION_OF_DIVIDER} {items.length}
						</span>
						<Button
							icon="next"
							onClick={increasePage}
							disabled={offset + pageSize >= items.length}
							iconOnly
							size="large"
							variant="loud"
						/>
					</div>
					<div className={styles.paginationEntriesDropdown}>
						<label htmlFor={paginationPageSizeId} className={styles.paginationSettingsLabel}>
							{languageKeys.PANDA_PAGINATION_ENTRIES_PER_PAGE}
						</label>
						<Dropdown
							id={paginationPageSizeId}
							onChange={setPageSize}
							value={pageSize}
							options={pageSizes}
							mapOptionToKey={opt => opt.toString()}
							mapOptionToLabel={opt => opt.toString()}
							size="large"
							title=""
						/>
					</div>
				</span>
			</div>
		);
	};

	const renderHeader = () => {
		const renderSearch = () => {
			if (!search || !searchPlaceholder || showEmptyState) {
				return null;
			}

			return (
				<div className={styles.search}>
					<Search
						value={searchTerm}
						onChange={setSearchTerm}
						placeholder={searchPlaceholder}
						resultCount={sortedAndFilteredItems.length}
						landmark
					/>
				</div>
			);
		};

		return (
			<>
				<ViewHeader
					heading={heading}
					actionElements={sortedActionElements(actionElements).map((element, i) => (
						<ActionElement
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							element={{
								...element,
								loud: element.loud ? element.loud : i === actionElements.length - 1,
							}}
						/>
					))}
					itemCount={items.length}
					itemLimit={itemLimit}
				/>
				{infoBox}
				{renderSearch()}
			</>
		);
	};

	const renderMobile = () => (
		<div className={styles.mobileContent}>
			<ListNavAsList
				items={sortedAndFilteredAndPagedItems}
				columns={columns}
				searchTerm={searchTerm}
			>
				{children}
			</ListNavAsList>
		</div>
	);

	const renderDesktop = () => (
		<div className={styles.desktopContent}>
			<ListNavAsTable
				sortedField={sortedField}
				setSortedField={setSortedField}
				sortDirection={sortDirection}
				setSortDirection={setSortDirection}
				items={sortedAndFilteredAndPagedItems}
				columns={columns}
				searchTerm={searchTerm}
			>
				{children}
			</ListNavAsTable>
		</div>
	);

	if (showEmptyState) {
		return (
			<>
				{renderHeader()}
				<HeadingBoundary>
					<div className={styles.emptyStateContent}>{emptyState}</div>
				</HeadingBoundary>
			</>
		);
	}

	return (
		<>
			{renderHeader()}
			{renderMobile()}
			{renderDesktop()}
			{renderPagination()}
		</>
	);
};

export { ListNavView };
