/* eslint-disable react/no-unused-prop-types */
import React from 'react';

type Props = {
	value: string;
	disabled?: boolean;
	children: string | React.ReactNode;
};

/**
 * This components exist so the usage of <Select/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Option = (props: Props) => null;
