import { GeneralErrorBase } from './ApiError';

export const NO_CITIES_FOUND = 2002;
export const NO_STREETS_FOUND = 2003;
export const NO_ADDRESS_FOUND = 2004;
export const INVALID_ZIP_CODE = 2005;

export const GENERAL_ERROR = 2500;

const errorCodes = [
	GENERAL_ERROR,
	NO_CITIES_FOUND,
	NO_STREETS_FOUND,
	NO_ADDRESS_FOUND,
	INVALID_ZIP_CODE,
] as const;
type ErrorCodes = (typeof errorCodes)[number];
export type AddressApiError = GeneralErrorBase<ErrorCodes>;

export const AddressApiErrors = {
	GeneralError: (cause?: unknown): AddressApiError => ({
		apiErrorCode: GENERAL_ERROR,
		message: 'Generic address error',
		cause,
	}),
	NoStreetsFound: (message: string, cause?: unknown): AddressApiError => ({
		apiErrorCode: NO_STREETS_FOUND,
		message,
		cause,
	}),
	NoCitiesFound: (message: string, cause?: unknown): AddressApiError => ({
		apiErrorCode: NO_CITIES_FOUND,
		message,
		cause,
	}),
	NoAddressFound: (message: string, cause?: unknown): AddressApiError => ({
		apiErrorCode: NO_ADDRESS_FOUND,
		message,
		cause,
	}),
	InvalidZipCode: (message: string, cause?: unknown): AddressApiError => ({
		apiErrorCode: INVALID_ZIP_CODE,
		message,
		cause,
	}),
};
