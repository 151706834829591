import { SipgateProduct, SipgateSignupStatus } from '../redux/slices/signup';

interface BaseSignupData {
	salutation: string;
	firstname: string;
	lastname: string;
	phoneNumber: string;
	company: string;

	domain: Domain;
	country: Omit<string, 'DE'> | 'DE';
	zip: string;
	city: string;
	email: string;
	password: string;
	testaccount: boolean;
	tacIds: string[];
	product: SipgateProduct;
	identityProvider: 'SIPGATE' | 'GOOGLE' | 'MICROSOFT';
}

export interface GermanSignupData extends BaseSignupData {
	street: string;
	number: string;
	country: 'DE';
}

// any country except germany
export interface InternationalSignupData extends BaseSignupData {
	internationalAddress1: string;
	internationalAddress2: string;
	country: Omit<string, 'DE'>;
}

export type SignupData = GermanSignupData | InternationalSignupData;

export type PartialSignupData =
	| Omit<GermanSignupData, 'domain' | 'product' | 'identityProvider'>
	| Omit<InternationalSignupData, 'domain' | 'product' | 'identityProvider'>;

export type PartialSignupDataWithEmail = Pick<SignupData, 'email' | 'domain' | 'product'>;

type DeepPartial<T> = T extends object
	? {
			[P in keyof T]?: DeepPartial<T[P]>;
		}
	: T;

export type SavePartialSignupRequest = DeepPartial<
	Omit<
		SignupApiRequest,
		| 'tacIds'
		| 'email'
		| 'password'
		| 'partnerId'
		| 'testAccount'
		| 'command'
		| 'marketingId'
		| 'domain'
	>
>;

export interface SignupCreatedResponse {
	identifier: string;
	activationCode?: string;
	activationUrl?: string;
}

export interface SignupApiRequest {
	tacIds: string[];
	subscribedToNewsletter: boolean;
	marketingId: number;
	command: string;
	testAccount: boolean;
	lastname: string;
	firstname: string;
	password: string;
	partnerId: string;
	domain: string;
	company: string;
	email: string;
	gender: string;
	phoneNumber: string;
	address: Address;
	ls2hs: string;
	utmParams: string;
}

interface Address {
	type: string;
	street?: string;
	houseNumber?: string;
	city: string;
	zipCode: string;
	address1?: string;
	address2?: string;
	country: string;
}

export type SupportedLocales = 'de_DE' | 'en_GB';
export type Domain = 'sipgate.de' | 'sipgate.co.uk';
export type IdentityProvider = 'SIPGATE' | 'GOOGLE' | 'MICROSOFT';

export const isInternationalSignupData = (
	signupData: SignupData
): signupData is InternationalSignupData => {
	return signupData.country !== 'DE';
};

export const isGermanSignupData = (signupData: SignupData): signupData is GermanSignupData =>
	signupData.country === 'DE' && signupData.domain === 'sipgate.de';

export interface SignupResponse {
	identifier: string;
	email: string;
	firstname: string;
	lastname: string;
	company: string;
	phoneNumber: string;
	gender: string;
	status: SipgateSignupStatus;
	subscribedToNewsletter: boolean;
	zip: string;
	city: string;
	product: SipgateProduct;
	country: string;
	street: string;
	houseNumber: string;
	address1: string;
	address2: string;
	identityProvider: IdentityProvider;
	avatarUrl: string;
}
