import React from 'react';
import classnames from 'classnames';
import redIconSmall from './icons/icon-state-red500-overlay-24.svg';
import orangeIconSmall from './icons/icon-state-orange500-overlay-24.svg';
import greenIconSmall from './icons/icon-state-green500-overlay-24.svg';
import redIconMedium from './icons/icon-state-red500-overlay-32.svg';
import orangeIconMedium from './icons/icon-state-orange500-overlay-32.svg';
import greenIconMedium from './icons/icon-state-green500-overlay-32.svg';

type Color = 'green' | 'orange' | 'red';
type Size = 'small' | 'medium';

type Props = {
	/**
	 * Die Icons die du hier übergibst sollten entweder `1,5rem` oder `2rem` abhängig
	 * von der `size` haben, damit wir den Status richtig positionieren können.
	 */
	icon: React.ReactNode;

	/**
	 * Bestimmt die Farbe des angezeigten Status.
	 */
	color: Color;

	/**
	 * Wenn dein Icon `1,5rem` groß ist, dann setze `small` und wenn `2rem` dann `medium`.
	 */
	size: Size;

	/**
	 * Ein String der den aktuellen Status des Objekts beschreibt wie online oder offline.
	 */
	state: string;

	/**
	 * Ein String der die Bedeutung des Icons für Screenreader Nutzer:innen beschreibt.
	 */
	alt: string;

	/**
	 * Soll dieses icon inline mit text gerendert werden?
	 */
	inline?: boolean;
};

const styles = {
	wrapper: (inline: boolean, size: Size) =>
		classnames('select-none', inline && 'inline', !inline && (size === 'medium' ? 'h-32' : 'h-24')),
	state: (size: Size) =>
		classnames(
			'-mb-2',
			size === 'medium' ? 'h-32' : 'h-24',
			size === 'medium' ? '-ml-32' : '-ml-24'
		),
};

const renderDot = (type: Color, size: Size, state: string) => {
	switch (type) {
		case 'green':
			return (
				<img
					src={size === 'small' ? greenIconSmall : greenIconMedium}
					alt=""
					className={styles.state(size)}
					title={state}
				/>
			);

		case 'orange':
			return (
				<img
					src={size === 'small' ? orangeIconSmall : orangeIconMedium}
					alt=""
					className={styles.state(size)}
					title={state}
				/>
			);

		case 'red':
			return (
				<img
					src={size === 'small' ? redIconSmall : redIconMedium}
					alt=""
					className={styles.state(size)}
					title={state}
				/>
			);
	}
};

export const StateIcon = ({
	icon,
	size,
	state,
	color: type,
	alt,
	inline = false,
}: Props): JSX.Element => {
	return (
		<div role="img" aria-label={`${state} ${alt}`} className={styles.wrapper(inline, size)}>
			{icon}

			{renderDot(type, size, state)}
		</div>
	);
};
