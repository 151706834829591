import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { translationSlice } from './slices/translations';
import { addressesSlice } from './slices/addresses';
import { signupSlice } from './slices/signup';
import { nqnupsiSlice } from './slices/nqnupsi';
import { tacsSlice } from './slices/tacs';

export const rootReducer = combineReducers({
	translations: translationSlice.reducer,
	addresses: addressesSlice.reducer,
	signup: signupSlice.reducer,
	tacs: tacsSlice.reducer,
	nqnupsi: nqnupsiSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
	return configureStore({
		reducer: rootReducer,
		preloadedState,
	});
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export default setupStore;
