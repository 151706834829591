import {
	normalizeDirectDial,
	normalizeE164,
	normalizePhonenumber,
} from '@web-apps/phonenumbers-utils';
import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

export interface ValidatedNumber {
	type: 'E164' | 'DIRECTDIAL';
	number: string;
}

/**
 * Ensures a string is a valid quickdial number.
 */
export const validateDirectDials =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	input => {
		const forbiddenCodes: string[] = [
			'110',
			'111',
			'112',
			'115',
			'119',
			'211',
			'311',
			'411',
			'511',
			'611',
			'711',
			'811',
			'911',
			'999',
		];

		const result = normalizeDirectDial(input);

		if (result.valid) {
			if (forbiddenCodes.includes(result.value)) {
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_QUICKDIAL_FORBIDDEN'),
				};
			}

			return {
				valid: true,
				value: result.value,
			};
		}

		switch (result.error) {
			case 'INVALID_CHARACTERS':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_QUICKDIAL_INVALID_CHARACTERS'),
				};
			case 'LEADING_ZERO':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_QUICKDIAL_LEADING_ZERO'),
				};
			case 'TOO_LONG':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_QUICKDIAL_TOO_LONG'),
				};
			case 'TOO_SHORT':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_QUICKDIAL_TOO_SHORT'),
				};

			default:
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_DEFAULT_ERROR_MESSAGE'),
				};
		}
	};

/**
 * Ensures a string is a valid phonenumber.
 * If allowNonRoutable is false, it checks the number the same
 * way as our API. If you want to pass the number to the API this is
 * _almost_ always what you want.
 *
 * It converts the string to an object of type `{ type: 'E164' | 'QUICKDIAL'; number: string }`,
 * so you can check what tpe of number was entered.
 *
 * ```
 * validatePhonenumber(translate, {
 *     allowQuickdial: true,
 *     allowNonRoutable: false,
 *     locale: userinfo.locale
 * })
 * ```
 */
export const validatePhonenumber =
	(
		translate: Translate | string,
		options: {
			allowDirectDial: boolean;
			allowNonRoutable: boolean;
			domain: 'sipgate.de' | 'sipgate.co.uk';
		}
	): ManagedFieldValidator<string, ValidatedNumber> =>
	input => {
		const result = options.allowDirectDial
			? normalizePhonenumber(input, options.domain)
			: normalizeE164(input, options.domain);

		if (result.valid) {
			if (options.allowNonRoutable || result.routable) {
				return {
					valid: true,
					value: {
						type: result.type,
						number: result.value,
					},
				};
			}

			return {
				valid: false,
				error: renderError(translate, 'FORM_VALIDATION_NON_ROUTABLE_NUMBER'),
			};
		}

		switch (result.error) {
			case 'GENERIC':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_INVALID_NUMBER'),
				};
			case 'INVALID_CHARACTERS':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_INVALID_NUMBER'),
				};
			case 'LEADING_ZERO':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_QUICKDIAL_LEADING_ZERO'),
				};
			case 'MISSING_LOCALPREFIX':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_NUMBER_MISSING_LOCALPREFIX'),
				};
			case 'TOO_LONG':
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_QUICKDIAL_TOO_LONG'),
				};
			case 'TOO_SHORT':
				return {
					valid: false,
					error: renderError(
						translate,
						options.allowDirectDial
							? 'FORM_VALIDATION_NUMBER_TOO_SHORT_QUICKDIAL'
							: 'FORM_VALIDATION_NUMBER_TOO_SHORT'
					),
				};
			default:
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_DEFAULT_ERROR_MESSAGE'),
				};
		}
	};
