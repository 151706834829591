import React from 'react';
import classnames from 'classnames';
import { TableBodyContext } from './contexts/tableBodyContext';
import { useTableContext } from './contexts/tableContext';
import { TableRow } from './TableRow';

type Props = {
	children: React.ReactComponentElement<typeof TableRow>[];
};

const styles = {
	tbody: classnames('hyphens'),
};

export const TableBody = ({ children }: Props): JSX.Element => {
	const { activeRow } = useTableContext();

	return (
		<tbody className={styles.tbody}>
			{React.Children.map(children, (child, index) => {
				return (
					<TableBodyContext.Provider
						value={{
							rowIndex: index,
							isActiveRow: activeRow === index,
						}}
					>
						{child}
					</TableBodyContext.Provider>
				);
			})}
		</tbody>
	);
};
