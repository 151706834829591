import React from 'react';
import classnames from 'classnames';
import classes from './Tabs.module.css';

type Props = {
	label: string;
	sublabel: string;
	isActive: boolean;
	isSelected: boolean;
	handleOnClick?: VoidFunction;
	disabled: boolean;
};

const styles = {
	listItem: classnames('flex', 'items-center', 'self-stretch'),
	outerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'group',
			'cursor-pointer',
			'focus:outline-none',
			'focus-visible:outline-none',
			'justify-center',
			'p-8',
			'whitespace-nowrap',
			'duration-150',
			'ease-in-out',
			'transition',
			'bg-neo-color-global-background-static-transparent',
			!isDisabled &&
				isSelected && ['bg-neo-color-global-background-static-transparent', classes.borderSelected],
			!isDisabled &&
				!isSelected && [
					classes.border,
					classes.borderHover,
					'bg-neo-color-global-background-static-transparent',
				],
			isDisabled && [
				'bg-neo-color-global-background-static-transparent',
				'disabled:cursor-not-allowed',
			],
			isDisabled && isSelected && [classes.borderDisabledSelected],
			isDisabled && !isSelected && [classes.border]
		),
	innerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'font-brand',
			'group-focus-visible:ring-focus-inset',
			'px-16',
			'py-8',
			'rounded',
			'block',
			'duration-150',
			'ease-in-out',
			'transition',
			'min-w-[9rem]',
			'text-start',
			!isDisabled &&
				isSelected && [
					'bg-neo-color-global-background-primary-soft-default',
					'group-focus-visible:ring-neo-color-global-border-static-focus',
					'group-active:bg-neo-color-global-background-primary-soft-active',
				],
			!isDisabled &&
				!isSelected && [
					'group-hover:bg-neo-color-global-background-primary-soft-hover',
					'group-focus-visible:ring-neo-color-global-border-static-focus',
					'group-active:g-neo-color-global-background-primary-soft-active',
				],
			isDisabled && isSelected && ['bg-neo-color-global-background-neutral-soft-disabled']
		),
	label: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'text-sm/16',
			'font-bold',
			!isDisabled &&
				!isSelected && [
					'text-neo-color-global-content-neutral-intense',
					'group-hover:text-neo-color-global-content-primary-intense',
					'group-active:text-neo-color-global-content-primary-intense',
				],
			!isDisabled &&
				isSelected && [
					'text-neo-color-global-content-primary-intense',
					'group-active:text-neo-color-global-content-primary-intense',
				],
			isDisabled && !isSelected && ['text-neo-color-global-content-neutral-disabled'],
			isDisabled && isSelected && ['text-neo-color-global-content-primary-disabled']
		),

	sublabel: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'text-xs',
			isDisabled && ['text-neo-color-global-content-neutral-disabled'],
			!isDisabled &&
				!isSelected && [
					'text-neo-color-global-content-neutral-moderate',
					'group-hover:text-neo-color-global-content-neutral-moderate',
					'group-active:text-neo-color-global-content-neutral-moderate',
				],
			!isDisabled && isSelected && ['text-neo-color-global-content-info-moderate', 'font-bold']
		),
};

const MainTabItem = ({
	label,
	sublabel,
	isSelected,
	isActive,
	disabled,
	handleOnClick,
}: Props): JSX.Element => (
	<li className={styles.listItem} role="presentation">
		<button
			role="tab"
			aria-selected={isSelected}
			type="button"
			className={styles.outerButton(isSelected, disabled)}
			disabled={disabled}
			onClick={!disabled ? handleOnClick : undefined}
			aria-label={`${label} (${sublabel})`}
		>
			<div className={styles.innerButton(isSelected, disabled)}>
				<div className={styles.label(isSelected, disabled)}>{label}</div>
				<div className={styles.sublabel(isActive, disabled)}>{sublabel}</div>
			</div>
		</button>
	</li>
);

export { MainTabItem };
