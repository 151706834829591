import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import { useCallback, useEffect, useRef } from 'react';

const ResizeObserver =
	typeof window !== 'undefined' && window.ResizeObserver ? window.ResizeObserver : Polyfill;

export function useResizeObserver(callback: (entry: ResizeObserverEntry) => void) {
	const observer = useRef<ResizeObserver | null>(null);
	const element = useRef<HTMLElement | null>(null);
	const debounceTimer = useRef<number | null>(null);

	const debouncedCallback = useCallback(
		(entries: ResizeObserverEntry[]) => {
			if (debounceTimer.current) {
				window.clearTimeout(debounceTimer.current);
			}
			debounceTimer.current = window.setTimeout(() => {
				callback(entries[0]);
			}, 100);
		},
		[callback]
	);

	useEffect(() => {
		if (observer.current) {
			observer.current.disconnect();
		}

		observer.current = new ResizeObserver(entries => {
			debouncedCallback(entries);
		});

		if (element.current) {
			observer.current.observe(element.current);
		}

		return () => {
			if (observer.current) {
				observer.current.disconnect();
			}
			if (debounceTimer.current) {
				window.clearTimeout(debounceTimer.current);
			}
		};
	}, [debouncedCallback]);

	return useCallback((e: HTMLElement | null) => {
		element.current = e;

		if (!observer.current) {
			return;
		}

		observer.current.disconnect();

		if (element.current) {
			observer.current.observe(element.current);
		}
	}, []);
}
