import React from 'react';
import classnames from 'classnames';
import { ManagedCheckbox } from '@panda/ui';
import { ManagedForm } from '@web-apps/forms';
import { useFormikContext } from 'formik';
import { isNqDomain } from '../../nqNupsi/NQNupsi';
import classes from './NQOnlyGroup.scss';

const NqOnlyGroup = () => {
	const formik = useFormikContext<{
		testaccount: boolean;
	}>();

	if (!isNqDomain()) {
		return null;
	}

	return (
		<div className={classes.group}>
			<div className={classnames(isNqDomain() ? classes.row : classes.hidden, classes.width4)}>
				<ManagedCheckbox managedField={ManagedForm.buildField(formik, 'testaccount')}>
					Als Testaccount anlegen
				</ManagedCheckbox>
			</div>
		</div>
	);
};

export default NqOnlyGroup;
