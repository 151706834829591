import React from 'react';
import classnames from 'classnames';
import { usePandaContext } from '../../contexts/pandaContext';

type Props = {
	/**
	 * Übergib hier den `value`, der gerade in die Suche eingegeben wurde.
	 */
	searchTerm: string;

	/**
	 * Benutze dies falls du nur begrenzt Platz hast damit die Erläuterung
	 * für den Benutzer weggelassen wird.
	 */
	short?: boolean;
};

const styles = {
	heading: classnames(
		'font-brand',
		'font-bold',
		'text-lg/24',
		'text-neo-color-global-content-neutral-intense',
		'mt-8',
		'mb-4',
		'mx-0'
	),
	paragraph: classnames(
		'font-brand',
		'font-normal',
		'text-sm/18',
		'text-neo-color-global-content-neutral-intense',
		'mt-0',
		'mb-16'
	),
	subHeading: classnames(
		'font-brand',
		'font-bold',
		'text-sm/16',
		'text-neo-color-global-content-neutral-intense',
		'font-bold',
		'mt-16',
		'mb-0'
	),
	list: classnames(
		'font-brand',
		'font-normal',
		'text-sm/20',
		'text-neo-color-global-content-neutral-moderate',
		'm-12',
		'ml-24',
		'p-0',
		'flex',
		'flex-col',
		'gap-8'
	),
};

const SearchNoResult = ({ searchTerm, short }: Props): JSX.Element => {
	const { languageKeys } = usePandaContext();

	return (
		<>
			<h2 className={styles.heading}>{languageKeys.PANDA_SEARCH_NO_RESULTS_HEADING}</h2>
			<p className={styles.paragraph}>
				{languageKeys.PANDA_SEARCH_NO_RESULTS_PARAGRAPH(searchTerm)}
			</p>

			{short ? null : (
				<>
					<h3 className={styles.subHeading}>{languageKeys.PANDA_SEARCH_NO_RESULTS_SUBHEADING}</h3>
					<ul className={styles.list}>
						<li>{languageKeys.PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1}</li>
						<li>{languageKeys.PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2}</li>
						<li>{languageKeys.PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3}</li>
					</ul>
				</>
			)}
		</>
	);
};

export { SearchNoResult };
