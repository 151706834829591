import { ManagedFieldValidator } from '@web-apps/forms';

export const validateNoNumber =
	(error: string): ManagedFieldValidator<string, string> =>
	input => {
		const isValid = !input.match('\\d');
		if (isValid) {
			return { valid: true, value: input };
		}
		return { valid: false, error };
	};
