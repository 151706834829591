import { ErrorBase } from '../../types';
import { SupportedLocales } from '../../../types';

export type TranslationErrors = ErrorBase<'COULD_NOT_FETCH'> & {
	items?: { [key: string]: string };
	locale?: SupportedLocales;
};

export const CouldNotFetchError = (
	message: string,
	items: { [key: string]: string },
	locale: SupportedLocales
): TranslationErrors => ({
	name: 'COULD_NOT_FETCH',
	message,
	items,
	locale,
});

export interface TranslationState {
	items: {
		[key: string]: string;
	};
	fetching: boolean;
	fetched: boolean;

	error?: TranslationErrors;

	language: SupportedLocales;
}

export type RootTranslationState = {
	translations: TranslationState;
};
