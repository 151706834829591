import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignupState, SipgateDomain, SipgateProduct } from './types';
import {
	activateSignup,
	createPartialSignup,
	createSignup,
	getSignup,
	resendActivation,
} from './actions';
import { getIdentifier, getProduct, getTacIds, isUKHostname } from '../../../utils';

export const initialState: SignupState = {
	identifier: getIdentifier(window.location),
	country: !isUKHostname(window.location.hostname) ? 'DE' : 'GB',
	domain: !isUKHostname(window.location.hostname) ? 'sipgate.de' : 'sipgate.co.uk',
	product: getProduct(window.location),
	tacIds: getTacIds(
		getProduct(window.location),
		!isUKHostname(window.location.hostname) ? 'sipgate.de' : 'sipgate.co.uk'
	),
	status: undefined,
	creatingPending: false,
	createdPending: false,
	creatingPartial: false,
	createdPartial: false,
	activating: false,
	activated: false,
	resending: false,
	resented: false,
	fetching: false,
	fetched: false,
};

export const signupSlice = createSlice({
	name: 'signup',
	initialState,
	reducers: {
		clearAddressData: (state, action: PayloadAction<string>) => ({
			...state,
			zip: '',
			city: '',
			street: '',
			number: '',
			country: action.payload,
		}),
		setEmail: (state, action: PayloadAction<string>) => ({
			...state,
			email: action.payload,
		}),
		setCountry: (state, action: PayloadAction<string>) => ({
			...state,
			country: action.payload,
		}),
		setDomain: (state, action: PayloadAction<SipgateDomain>) => ({
			...state,
			domain: action.payload,
			tacIds: getTacIds(state.product, action.payload),
		}),
		setProduct: (state, action: PayloadAction<SipgateProduct>) => ({
			...state,
			product: action.payload,
			tacIds: getTacIds(action.payload, state.domain),
		}),
	},
	extraReducers: builder => {
		// ActivateSignup
		builder.addCase(activateSignup.pending, state => ({
			...state,
			activating: true,
			activated: false,
		}));
		builder.addCase(activateSignup.rejected, (state, action) => ({
			...state,
			activated: false,
			activating: false,
			error: action.payload,
		}));
		builder.addCase(activateSignup.fulfilled, (state, action) => ({
			...state,
			identifier: action.payload.identifier,
			activationCode: action.payload.activationCode,
			activated: true,
			activating: false,
			error: undefined,
		}));
		// ResendActivation
		builder.addCase(resendActivation.pending, state => ({
			...state,
			resending: true,
			resented: false,
		}));
		builder.addCase(resendActivation.rejected, (state, action) => ({
			...state,
			resending: false,
			resented: false,
			resendError: action.payload,
		}));
		builder.addCase(resendActivation.fulfilled, state => ({
			...state,
			resending: false,
			resented: true,
		}));
		// create partial signup
		builder.addCase(createPartialSignup.pending, state => ({
			...state,
			creatingPartial: true,
			createdPartial: false,
			identifier: undefined,
			activationCode: undefined,
			error: undefined,
		}));
		builder.addCase(createPartialSignup.fulfilled, (state, action) => ({
			...state,
			createdPartial: true,
			creatingPartial: false,
			identifier: action.payload.identifier,
			activationCode: undefined,
			error: undefined,
		}));
		builder.addCase(createPartialSignup.rejected, (state, action) => ({
			...state,
			createdPartial: false,
			creatingPartial: false,
			identifier: undefined,
			activationCode: undefined,
			error: action.payload,
		}));
		// getting signup data
		builder.addCase(getSignup.pending, state => ({
			...state,
			fetching: true,
			fetched: false,
		}));
		builder.addCase(getSignup.fulfilled, (state, action) => {
			return {
				...initialState,
				fetched: true,
				fetching: false,
				email: action.payload.email,
				identifier: action.payload.identifier,
				firstname: action.payload.firstname,
				lastname: action.payload.lastname,
				company: action.payload.company,
				phoneNumber: action.payload.phoneNumber,
				gender: action.payload.gender,
				status: action.payload.status,
				subscribedToNewsletter: action.payload.subscribedToNewsletter,
				country: action.payload.country,
				zip: action.payload.zip,
				city: action.payload.city,
				product: action.payload.product,
				street: action.payload.street,
				number: action.payload.houseNumber,
				address1: action.payload.address1,
				address2: action.payload.address2,
				identityProvider: action.payload.identityProvider,
				avatarUrl: action.payload.avatarUrl,
			};
		});
		builder.addCase(getSignup.rejected, (state, action) => ({
			...initialState,
			fetched: false,
			fetching: false,
			identifier: undefined,
			email: undefined,
			activationCode: undefined,
			error: action.payload,
		}));
		// createSignup
		builder.addCase(createSignup.pending, state => ({
			...state,
			creatingPending: true,
			createdPending: false,
		}));
		builder.addCase(createSignup.rejected, (state, action) => ({
			...state,
			creatingPending: false,
			createdPending: false,
			error: action.payload,
		}));
		builder.addCase(createSignup.fulfilled, (state, action) => ({
			...state,
			creatingPending: false,
			createdPending: true,
			identifier: action.payload.identifier,
			activationCode: action.payload.activationCode,
			activationUrl: action.payload.activationUrl,
		}));
	},
});
