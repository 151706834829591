import React, { AriaAttributes } from 'react';
import { PasswordInput } from './PasswordInput';
import { RawInput } from './RawInput';

type InputMode = 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';

export type InputProps = {
	/**
	 * Setzt das HTML-Attribut `name`
	 */
	name?: string;
	/**
	 * Setzt den aktuellen Eingabewert
	 */
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	error?: string;
} & (GenericInputProps | NumberInputProps | DateInputProps | PasswordInputProps) &
	AriaAttributes;

export type ManagedInputProps = {
	/**
	 * Die Prop `managedField` kann genutzt werden, um die renderProps der
	 * [`ManagedForm`-Komponente](https://github.com/sipgate/web-apps/blob/main/shared/forms/Readme.md)
	 * entgegenzunehmen.
	 *
	 */
	managedField: {
		name: string;
		value: string;
		onChange: React.ChangeEventHandler<HTMLInputElement>;
		onBlur: React.FocusEventHandler<HTMLInputElement>;
	} & ({ valid: true; error: null } | { valid: false; error: string });
} & (GenericInputProps | NumberInputProps | DateInputProps | PasswordInputProps) &
	AriaAttributes;

type BaseProps = {
	/**
	 * Das `label` befindet sich über dem eigentlichen Eingabefeld und
	 * beschreibt es mit 1-2 Wörtern.
	 */
	label?: string;
	/**
	 * Die `description` kann benutzt werden, um in einer zweiten Zeile
	 * unter dem `label` noch eine kurze Beschreibung hinzuzufügen.
	 */
	description?: string;
	autoFocus?: boolean;
	/**
	 * `disabled` verhindert die Eingabe in das Feld und entfernt es
	 * aus dem Accessibility-Tree (s. auch `readOnly`).
	 */
	disabled?: boolean;
	/**
	 * Über `readOnly` ist zwar der Inhalt des Feldes sichtbar, kann
	 * aber nicht bearbeitet werden. Im Gegensatz zu `disabled` wird
	 * er aber von Screenreadern vorgelesen.
	 */
	readOnly?: boolean;
	onFocus?: React.FocusEventHandler<HTMLInputElement>;
};

export type GenericInputProps = BaseProps & {
	type?: 'email' | 'tel' | 'text' | 'url';

	/**
	 * Der `placeholder` ist sichtbar, wenn das Eingabefeld leer ist.
	 */
	placeholder: string;

	/**
	 * Der `inputMode` beinflusst welche Tastatur z.Bsp. von Mobilgeräten angezeigt wird.
	 * Siehe auch [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode).
	 */
	inputMode?: InputMode;

	/**
	 * Ein Wert welcher direkt an das HTML `input` Element weitergereicht wird um
	 * Browsern und Passwortmanagern Hinweise über erwünschte Autoverfollgständigung
	 * zu geben.
	 */
	autocomplete?: string;

	optional?: boolean;

	min?: undefined;
	max?: undefined;
	minLength?: undefined;
};

export type NumberInputProps = BaseProps & {
	type: 'number';

	/**
	 * Die Nummer muss größer oder gleich dem eingetragenen Wert sein.
	 */
	min?: number;

	/**
	 * Die Nummer muss kleiner oder gleich dem eingetragenen Wert sein.
	 */
	max?: number;

	optional?: boolean;
	placeholder?: string;
	minLength?: undefined;
	inputMode?: undefined;
	autocomplete?: undefined;
};

export type DateInputProps = BaseProps & {
	type: 'date';

	/**
	 * Das früheste Datum, welches die Nutzer:in auswählen darf.
	 * Ihr müsst das Datum trotzdem händisch validieren, da die Nutzer:in nicht davon abgehalten wird,
	 * manuell ein früheres Datum einzugeben.
	 */
	min?: Date;

	/**
	 * Das späteste Datum, welches die Nutzer:in auswählen darf.
	 * Ihr müsst das Datum trotzdem händisch validieren, da die Nutzer:in nicht davon abgehalten wird,
	 * manuell ein älteres Datum einzugeben.
	 */
	max?: Date;

	optional?: boolean;

	placeholder?: undefined;
	minLength?: undefined;
	inputMode?: undefined;
	autocomplete?: undefined;
};

export type PasswordInputProps = BaseProps & {
	type: 'password';

	/**
	 * Der `placeholder` ist sichtbar, wenn das Eingabefeld leer ist.
	 */
	placeholder: string;

	/**
	 * Ausschließlich ein Hinweis an Passwortmanager, wie lang ein
	 * autogeneriertes Passwort mindestens sein soll.
	 *
	 * Wenn ihr absolut sicher stellen wollt, dass kein kürzeres Passwort eingegeben wird,
	 * müsst ihr dies in eurem Code selbst validieren.
	 */
	minLength?: number;

	/**
	 * Ein Wert welcher direkt an das HTML `input` Element weitergereicht wird um
	 * Browsern und Passwortmanagern Hinweise über erwünschte Autoverfollgständigung
	 * zu geben.
	 */
	autocomplete?: string;

	min?: undefined;
	max?: undefined;
	optional?: undefined;
	inputMode?: InputMode;
};

const Input = ({
	name,
	error,
	value,
	onChange,
	onBlur,
	onFocus,
	autoFocus = false,
	description,
	label,
	placeholder,
	minLength,
	disabled,
	readOnly = false,
	optional,
	type,
	min,
	max,
	inputMode,
	autocomplete,
	...ariaAttributes
}: InputProps) => {
	if (type === 'password') {
		return (
			<PasswordInput
				label={label}
				placeholder={placeholder}
				name={name}
				error={error}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				/* eslint-disable-next-line jsx-a11y/no-autofocus */
				autoFocus={autoFocus}
				description={description}
				minLength={minLength}
				disabled={disabled}
				readOnly={readOnly}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...ariaAttributes}
			/>
		);
	}

	if (type === 'number') {
		return (
			<RawInput
				type="number"
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				error={error}
				description={description}
				name={name}
				disabled={disabled}
				readOnly={readOnly}
				onFocus={onFocus}
				/* eslint-disable-next-line jsx-a11y/no-autofocus */
				autoFocus={autoFocus}
				label={label}
				optional={optional}
				min={min}
				max={max}
				placeholder={placeholder}
				maxLength={undefined}
				inputMode={undefined}
				autocomplete={autocomplete}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...ariaAttributes}
			/>
		);
	}

	if (type === 'date') {
		return (
			<RawInput
				type="date"
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				error={error}
				description={description}
				name={name}
				disabled={disabled}
				readOnly={readOnly}
				onFocus={onFocus}
				/* eslint-disable-next-line jsx-a11y/no-autofocus */
				autoFocus={autoFocus}
				label={label}
				optional={optional}
				min={min}
				max={max}
				placeholder={undefined}
				maxLength={undefined}
				inputMode={undefined}
				autocomplete={autocomplete}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...ariaAttributes}
			/>
		);
	}

	return (
		<RawInput
			type={type || 'text'}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			error={error}
			description={description}
			name={name}
			placeholder={placeholder}
			disabled={disabled}
			readOnly={readOnly}
			onFocus={onFocus}
			/* eslint-disable-next-line jsx-a11y/no-autofocus */
			autoFocus={autoFocus}
			label={label}
			optional={optional}
			min={undefined}
			max={undefined}
			maxLength={255}
			inputMode={inputMode}
			autocomplete={autocomplete}
			/* eslint-disable-next-line react/jsx-props-no-spreading */
			{...ariaAttributes}
		/>
	);
};

const ManagedInput = ({
	managedField: field,
	label,
	placeholder,
	onFocus,
	autoFocus = false,
	description,
	minLength,
	disabled,
	readOnly = false,
	max,
	min,
	optional,
	type,
	inputMode,
	autocomplete,
	...ariaAttributes
}: ManagedInputProps) => {
	if (type === 'password') {
		return (
			<PasswordInput
				name={field.name}
				value={field.value}
				onChange={field.onChange}
				onBlur={field.onBlur}
				error={field.error === null ? undefined : field.error}
				label={label}
				placeholder={placeholder}
				onFocus={onFocus}
				/* eslint-disable-next-line jsx-a11y/no-autofocus */
				autoFocus={autoFocus}
				description={description}
				minLength={minLength}
				disabled={disabled}
				readOnly={readOnly}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...ariaAttributes}
			/>
		);
	}

	if (type === 'number') {
		return (
			<RawInput
				type="number"
				name={field.name}
				value={field.value}
				onChange={field.onChange}
				onBlur={field.onBlur}
				error={field.error === null ? undefined : field.error}
				description={description}
				disabled={disabled}
				readOnly={readOnly}
				onFocus={onFocus}
				/* eslint-disable-next-line jsx-a11y/no-autofocus */
				autoFocus={autoFocus}
				label={label}
				optional={optional}
				min={min}
				max={max}
				placeholder={placeholder}
				maxLength={undefined}
				inputMode={undefined}
				autocomplete={autocomplete}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...ariaAttributes}
			/>
		);
	}

	if (type === 'date') {
		return (
			<RawInput
				type="date"
				name={field.name}
				value={field.value}
				onChange={field.onChange}
				onBlur={field.onBlur}
				error={field.error === null ? undefined : field.error}
				description={description}
				disabled={disabled}
				readOnly={readOnly}
				onFocus={onFocus}
				/* eslint-disable-next-line jsx-a11y/no-autofocus */
				autoFocus={autoFocus}
				label={label}
				optional={optional}
				min={min}
				max={max}
				placeholder={undefined}
				maxLength={undefined}
				inputMode={undefined}
				autocomplete={autocomplete}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...ariaAttributes}
			/>
		);
	}

	return (
		<RawInput
			type={type || 'text'}
			name={field.name}
			value={field.value}
			onChange={field.onChange}
			onBlur={field.onBlur}
			error={field.error === null ? undefined : field.error}
			description={description}
			placeholder={placeholder}
			disabled={disabled}
			readOnly={readOnly}
			onFocus={onFocus}
			/* eslint-disable-next-line jsx-a11y/no-autofocus */
			autoFocus={autoFocus}
			label={label}
			optional={optional}
			min={undefined}
			max={undefined}
			maxLength={255}
			inputMode={inputMode}
			autocomplete={autocomplete}
			/* eslint-disable-next-line react/jsx-props-no-spreading */
			{...ariaAttributes}
		/>
	);
};

export { Input, ManagedInput };
