export interface ErrorBase<T extends number> extends Error {
	apiErrorCode: T;
	name: string;
	message: string;
	cause: unknown;
}

export interface ApiException {
	apiErrorCode: number;
	message: string;
	constraint: string;
}

export const hasApiErrorCode = (error: unknown): error is { apiErrorCode: number } => {
	return typeof (error as { apiErrorCode?: number })?.apiErrorCode === 'number';
};
export const isApiException = (error: unknown): error is ApiException => hasApiErrorCode(error);
