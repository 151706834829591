import React, { useEffect } from 'react';
import { ManagedInput, Option, Select } from '@panda/ui';
import classnames from 'classnames';
import { ManagedForm } from '@web-apps/forms';
import { useFormikContext } from 'formik';
import classes from './Address.scss';

import useTranslations from '../../../hooks/translations';
import useAddressData from '../../../hooks/AddressData';

const UKAddress = () => {
	const { translate } = useTranslations();
	const { addresses, hasAddressError, setZip, zip } = useAddressData();

	const formik = useFormikContext<{
		address1: string;
		address2: string;
		zip: string;
		city: string;
		country: string;
	}>();

	const [selectedAddress, setSelectedAddress] = React.useState('');

	useEffect(() => {
		setZip(formik.values.zip, formik.values.country);
	}, [zip, setZip, formik.values.zip, formik.values.country]);

	useEffect(() => {
		if (hasAddressError && !formik.getFieldMeta('postcode').error) {
			formik.setFieldError('postcode', translate('SIGNUP_ERROR_INVALID_ZIP'));
		} else if (!hasAddressError && formik.getFieldMeta('postcode').error) {
			formik.setFieldError('postcode', undefined);
		}
	}, [translate, hasAddressError, formik]);

	useEffect(() => {
		if (selectedAddress) {
			const index = Number(selectedAddress);
			const selectedAddressObj = addresses[index];

			if (!selectedAddressObj) {
				return;
			}

			if (formik.values.address1 !== selectedAddressObj.address) {
				formik.setFieldValue('address1', selectedAddressObj.address);
			}

			if (formik.values.address2 !== selectedAddressObj.additional) {
				formik.setFieldValue('address2', selectedAddressObj.additional);
			}

			if (formik.values.city !== selectedAddressObj.city) {
				formik.setFieldValue('city', selectedAddressObj.city);
			}
		}
	}, [selectedAddress, addresses, formik]);

	useEffect(() => {
		if (formik.touched.zip && formik.values.zip !== formik.values.zip.trim()) {
			formik.setFieldValue('zip', formik.values.zip.trim());
		}
	}, [formik]);

	return (
		<>
			<div className={classnames(classes.row)}>
				<div className={classnames(classes.width3)}>
					<ManagedInput
						managedField={ManagedForm.buildField(formik, 'zip')}
						label={translate('SIGNUP_ADDRESS_ZIP_LABEL')}
						placeholder=""
					/>
				</div>
				<div className={classnames(classes.width9)}>
					<Select
						title={translate('SIGNUP_ADDRESS_LABEL')}
						placeholder={translate('SIGNUP_ADDRESS_PLACEHOLDER')}
						disabled={addresses.length === 0}
						onChange={(value: string) => {
							setSelectedAddress(value);
						}}
						value={selectedAddress}
					>
						{addresses.map((item, index) => (
							<Option value={`${index}`} key={index}>
								{item.address}, {item.additional}, {item.city}, {item.zip}
							</Option>
						))}
					</Select>
				</div>
			</div>
			<div className={classnames(classes.row, classes.width12)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'address1')}
					label={translate('SIGNUP_ADDRESS_INTERNATIONAL_1_LABEL')}
					placeholder=""
					disabled={selectedAddress === ''}
				/>
			</div>
			<div className={classnames(classes.row, classes.width12)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'address2')}
					label={translate('SIGNUP_ADDRESS_INTERNATIONAL_2_LABEL')}
					placeholder=""
					disabled={selectedAddress === ''}
				/>
			</div>
			<div className={classnames(classes.row)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'city')}
					label={translate('SIGNUP_ADDRESS_CITY_LABEL')}
					placeholder=""
					disabled={selectedAddress === ''}
				/>
			</div>
		</>
	);
};

export default UKAddress;
