import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tac, TacsState } from './types';

export const initialState: TacsState = {
	items: [],
};

export const tacsSlice = createSlice({
	name: 'tacs',
	initialState,
	reducers: {
		setTacs: (state, action: PayloadAction<Tac[]>) => ({
			...state,
			items: action.payload.sort((a, b) => a.type.localeCompare(b.type)),
		}),
	},
});
