import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useParams } from 'react-router-dom';
import Layout from '../signup/Layout';
import classes from './Tac.scss';
import { TacIDResponse } from '../../api/types/TacIDResponse';
import api from '../../api';
import { useLoadingAnimation } from '../../hooks/LoadingAnimation';
import { renderPandaLink } from '../../utils/markdown';

const replaceNodes = (docElement: HTMLElement) => {
	for (const element of docElement.childNodes) {
		if (element.nodeName === 'A') {
			const htmlNode = element as HTMLElement;
			const pandaLink = renderPandaLink({
				href: htmlNode.getAttribute('href') ?? '#',
				children: htmlNode.innerHTML,
				variant: 'quiet',
			});
			if (pandaLink) {
				const newElement = document.createElement('null');
				const pandaLinkAsHtml = renderToStaticMarkup(pandaLink);
				newElement.innerHTML = pandaLinkAsHtml;
				element.replaceWith(newElement.firstChild as Node);
			}
		}
		if (element.hasChildNodes()) {
			replaceNodes(element as HTMLElement);
		}
	}
};

const Tac = () => {
	const { tacIdentifier } = useParams<{ tacIdentifier: string }>();
	const [signedTac, setSignedTac] = useState<TacIDResponse | null>(null);
	const loadingAnimation = useLoadingAnimation();

	useEffect(() => {
		if (signedTac !== null) return;
		api.getTacBySignedTacId(tacIdentifier).then(result => {
			if (result.isOk()) {
				setSignedTac(result.value);
				loadingAnimation.hide();
			}
		});
	}, [loadingAnimation, tacIdentifier, signedTac]);

	if (!signedTac) return <div>404</div>;

	const domParser = new DOMParser();
	const docElement = domParser.parseFromString(signedTac.htmlContent, 'text/html').documentElement;
	replaceNodes(docElement);

	return (
		<Layout>
			<div className={classes.success}>
				<div className={classes.content}>
					<span
						className={classes.message}
						// eslint-disable-next-line react/forbid-dom-props, react/no-danger
						dangerouslySetInnerHTML={{ __html: docElement.innerHTML }}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default Tac;
