import { ErrorBase, hasApiErrorCode } from './types';

export const ACTIVATION_NOT_FOUND = 1404;
export const ACTIVATION_ALREADY_FINISHED = 1001;
export const ACTIVATION_GENERAL_ERROR = 1500;

const apiErrorCodes = [
	ACTIVATION_NOT_FOUND,
	ACTIVATION_ALREADY_FINISHED,
	ACTIVATION_GENERAL_ERROR,
] as const;
type ApiErrorCode = (typeof apiErrorCodes)[number];

export type ActivationApiError = ErrorBase<ApiErrorCode>;

export const ActivationApiErrors = {
	GeneralError: (cause?: unknown): ActivationApiError => ({
		apiErrorCode: ACTIVATION_GENERAL_ERROR,
		name: 'ACTIVATION_GENERAL_ERROR',
		message: 'Generic activation error',
		cause,
	}),
	NotFound: (message: string, cause?: unknown): ActivationApiError => ({
		apiErrorCode: ACTIVATION_NOT_FOUND,
		name: 'ACTIVATION_NOT_FOUND_ERROR',
		message,
		cause,
	}),
	AlreadyActivated: (message: string, cause?: unknown): ActivationApiError => ({
		apiErrorCode: ACTIVATION_ALREADY_FINISHED,
		name: 'ACTIVATION_ALREADY_FINISHED',
		message,
		cause,
	}),
};

export const isActivationError = (error: unknown): error is ActivationApiError => {
	return hasApiErrorCode(error) && apiErrorCodes.includes(error.apiErrorCode as ApiErrorCode);
};
