import React, { useContext } from 'react';

export const DisabledContext = React.createContext(false);

export const isDisabled = (prop: boolean | undefined, value: boolean): boolean => {
	if (prop !== undefined) {
		return prop;
	}

	return value;
};

export const useDisabled = (prop?: boolean) => {
	return isDisabled(prop, useContext(DisabledContext));
};
