import React from 'react';
import classnames from 'classnames';
import classes from './Tabs.module.css';

type Props = {
	label: string;
	isActive?: boolean;
	handleOnClick?: VoidFunction;
	disabled: boolean;
};

const styles = {
	listItem: classnames('flex', 'items-center', 'self-stretch'),
	outerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'group',
			'cursor-pointer',
			'focus:outline-none',
			'focus-visible:outline-none',
			'justify-center',
			'p-8',
			'whitespace-nowrap',
			'duration-150',
			'ease-in-out',
			'transition',
			!isDisabled &&
				isSelected && ['bg-neo-color-global-background-static-transparent', classes.borderSelected],
			!isDisabled &&
				!isSelected && [
					classes.border,
					classes.borderHover,
					'bg-neo-color-global-background-static-transparent',
				],
			isDisabled && [
				'bg-neo-color-global-background-static-transparent',
				'disabled:cursor-not-allowed',
			],
			isDisabled && isSelected && [classes.borderDisabledSelected],
			isDisabled && !isSelected && [classes.border]
		),
	innerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'font-brand',
			'group-focus-visible:ring-focus-inset',
			'px-16',
			'py-8',
			'rounded',
			'text-sm/16',
			'block',
			'duration-150',
			'ease-in-out',
			'transition',
			!isDisabled &&
				isSelected && [
					'bg-neo-color-global-background-primary-soft-default',
					'text-neo-color-global-content-primary-intense',
					'group-active:bg-neo-color-global-background-primary-soft-active',
				],
			!isDisabled &&
				!isSelected && [
					'text-neo-color-global-content-neutral-intense',
					'group-hover:bg-neo-color-global-background-primary-soft-hover',
					'group-hover:text-neo-color-global-content-primary-intense',
					'group-active:bg-neo-color-global-background-primary-soft-active',
					'group-active:text-neo-color-global-content-primary-intense',
				],
			isDisabled &&
				isSelected && [
					'text-neo-color-global-content-primary-disabled',
					'bg-neo-color-global-background-primary-soft-disabled',
				],
			isDisabled && !isSelected && ['text-neo-color-global-content-primary-disabled']
		),
};

const TabItem = ({ label, isActive = false, disabled, handleOnClick }: Props): JSX.Element => (
	<li className={styles.listItem} role="presentation">
		<button
			role="tab"
			aria-selected={isActive}
			type="button"
			className={styles.outerButton(isActive, disabled)}
			disabled={disabled}
			onClick={!disabled ? handleOnClick : undefined}
		>
			<span className={styles.innerButton(isActive, disabled)}>{label}</span>
		</button>
	</li>
);

export { TabItem };
