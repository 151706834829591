import React, { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import { DownloadLink, ExternalLink, InternalLink } from '@panda/ui';
import classes from './Markdown.scss';
import { Translate } from './types';

interface ComponentProps {
	source?: string;
	variant?: 'quiet' | 'normal';
}

interface ComponentTranslateProps {
	translate: Translate;
	translationKey: string;
	placeholder: string[];
	variant?: 'quiet' | 'normal';
}

const isDownloadLink = (href: string): boolean => {
	const downloadLinkRegex = /^https?:\/\/.+\.pdf/;
	return downloadLinkRegex.test(href);
};

const isInternalLink = (href: string): boolean => {
	const internalRelativLinkRegex = /^\/.*/;
	const internalLinkRegex =
		/^https?:\/\/(signup|app)\.((dev|local)+\.)?(sipgate\.com|sipgatebasic(\.de|\.co\.uk)+|simquadrat\.de).*/;
	return internalLinkRegex.test(href) || internalRelativLinkRegex.test(href);
};

const isExternalSipgateLink = (href: string): boolean => {
	const externalSipgateLinkRegex =
		/^https?:\/\/.*(sipgate|simquadrat|sipgateteam|sipgatebasic|sipgatetrunking)\.(de|com|co\.uk|uk)/;
	return externalSipgateLinkRegex.test(href);
};

export const renderPandaLink = ({
	href,
	children,
	variant,
}: {
	href?: string;
	children: ReactNode;
	variant?: 'quiet' | 'normal';
}) => {
	if (!href) {
		return null;
	}

	if (isDownloadLink(href)) {
		return (
			<DownloadLink variant={variant || 'quiet'} target="blank" url={href}>
				{children as string}
			</DownloadLink>
		);
	}

	if (isInternalLink(href)) {
		return (
			<InternalLink variant={variant} to={href}>
				{children as string}
			</InternalLink>
		);
	}

	if (isExternalSipgateLink(href)) {
		return (
			<ExternalLink variant={variant} to={href} target="blank">
				{children as string}
			</ExternalLink>
		);
	}

	return (
		<ExternalLink variant={variant || 'quiet'} secure target="blank" to={href}>
			{children as string}
		</ExternalLink>
	);
};

const escapeMarkdown = (input: string) => {
	return input.replace(/[\\`*{}[\]()#+\-.!_>]/g, '\\$&');
};

export const Markdown = (props: ComponentProps) => (
	<ReactMarkdown
		unwrapDisallowed
		includeElementIndex
		components={{
			a: (nodeProps: { href?: string; children: ReactNode }) =>
				renderPandaLink({ ...nodeProps, variant: props.variant }),
			p: (nodeProps: { children: ReactNode; index?: number }) =>
				nodeProps.index === 0 ? (
					nodeProps.children
				) : (
					<div className={classes.pTag}>{nodeProps.children}</div>
				),
		}}
	>
		{props.source || ''}
	</ReactMarkdown>
);

export const MarkdownTranslate = (props: ComponentTranslateProps) => {
	const escapedPlaceholders = props.placeholder.map(p => escapeMarkdown(p));
	return (
		<Markdown
			source={props.translate(props.translationKey, ...escapedPlaceholders)}
			variant={props.variant}
		/>
	);
};
