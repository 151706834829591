import { RootState } from '../../setupStore';
import { RootSignupState } from './types';

export const getSignupState = (state: RootState) => state.signup;
export const getEmail = (state: RootState) => state.signup.email;
export const getIdentifier = (state: RootSignupState) => state.signup.identifier;
export const getSignupError = (state: RootState) => state.signup.error;

export const getCountry = (state: RootState) => state.signup.country;

export const getDomain = (state: RootSignupState) => state.signup.domain;

export const getProduct = (state: RootSignupState) => state.signup.product;

export const getTacIds = (state: RootSignupState) => state.signup.tacIds;

export const isResending = (state: RootSignupState) => state.signup.resending;
export const wasResented = (state: RootState) => state.signup.resented;

export const hasResendError = (state: RootState) => !!state.signup.resendError;
export const getResendError = (state: RootState) => state.signup.resendError;
export const isActivating = (state: RootSignupState) => state.signup.activating;

export const isFetched = (state: RootSignupState) => state.signup.fetched;
export const isFetching = (state: RootSignupState) => state.signup.fetching;

export const hasSignupError = (state: RootSignupState) => !!state.signup.error;

export const getSignupData = (state: RootSignupState) => state.signup;

export const isPartialCreated = (state: RootSignupState) => state.signup.createdPartial;
export const isPartialCreating = (state: RootSignupState) => state.signup.creatingPartial;

export const isPendingCreated = (state: RootSignupState) => state.signup.createdPending;
export const isPendingCreating = (state: RootSignupState) => state.signup.creatingPending;

export const getActivationCode = (state: RootSignupState) => state.signup.activationCode;

export const getIdentityProvider = (state: RootSignupState) => state.signup.identityProvider;

export const getLandingPageUrl = (state: RootSignupState) => {
	if (state.signup.domain === 'sipgate.co.uk') {
		switch (state.signup.product) {
			case 'trunking':
				return 'https://www.sipgatetrunking.co.uk';
			default:
				return 'https://www.sipgate.co.uk';
		}
	}

	switch (state.signup.product) {
		case 'trunking':
			return 'https://www.sipgatetrunking.de';
		case 'affiliate':
			return 'https://www.sipgate.de/partnerprogramm';
		default:
			return 'https://www.sipgate.de';
	}
};

export const getActivationUrl = (state: RootSignupState) => state.signup.activationUrl;
