import React, { ComponentProps } from 'react';
import classnames from 'classnames';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { TabItem } from './TabItem';
import { Tab } from './Tab';
import classes from './Tabs.module.css';

type Props = {
	/**
	 * Die onChange Funktion wird bei Click auf einen der Tabs ausgeführt und übergibt den Index des geklickten Tabs in die Funktion.
	 */
	onChange: (index: number) => void;
	children:
		| React.ReactElement<ComponentProps<typeof Tab>>[]
		| React.ReactElement<ComponentProps<typeof Tab>>;
	/**
	 * Das Label gibt der Navigation einen eindeutigen Namen, um diese von anderen Navigationen zu unterscheiden.
	 * [Siehe auch Landmarks auf MDN](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/landmark_role)
	 */
	landmarkLabel?: string;
};

const styles = {
	nav: classnames('overflow-x-auto'),
	list: classnames(
		classes.border,
		'flex',
		'flex-row',
		'items-stretch',
		'justify-start',
		'm-0',
		'min-w-min-content',
		'p-0',
		'select-none'
	),
};

const Tabs = ({ children, onChange, landmarkLabel }: Props): JSX.Element => {
	const disabledContextValue = React.useContext(DisabledContext);

	const activeTabs = React.Children.toArray(children).filter(
		child => React.isValidElement(child) && child.props.isActive
	);
	if (activeTabs.length > 1) {
		throw new Error('There can only be one active <Tab> in <Tabs/>');
	}

	return (
		<nav className={styles.nav} aria-label={landmarkLabel}>
			<ul role="tablist" className={styles.list}>
				{children &&
					React.Children.map(
						children,
						(item: React.ReactElement<ComponentProps<typeof Tab>>, index) => (
							<TabItem
								label={item.props.label}
								isActive={item.props.isActive}
								disabled={isDisabled(item.props.disabled, disabledContextValue)}
								handleOnClick={() => onChange(index)}
							/>
						)
					)}
			</ul>
		</nav>
	);
};

export { Tabs };
