import React from 'react';
import classnames from 'classnames';
import { DisabledContext, isDisabled as isContextDisabled } from '../../contexts/disabledContext';

import classes from './Toggle.module.css';

type Size = 'small' | 'large' | 'xlarge';

type GeneralProps = {
	checked: boolean;
	disabled?: boolean;
	pending?: boolean;
	onChange: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
	size?: Size;
};

type WithChild = {
	/**
	 * Wenn sich die Screenreader-Beschreibung vom Labeltext unterscheiden
	 * soll, kann hierfür `ariaLabel` benutzt werden.
	 */
	ariaLabel?: never;
	/**
	 * Über `ariaLabelledBy` kann die HTML-`id` eines Elementes angegeben werden,
	 * das die Funktion des Toggles beschreibt.
	 */
	ariaLabelledBy?: never;
	/**
	 * Über die `children` wird der sichtbare Label-Text des Toggles definiert.
	 */
	children: string;
};
type WithAriaLabelledBy = {
	ariaLabel?: never;
	ariaLabelledBy: string;
	children?: string;
};
type WithAriaLabel = {
	ariaLabel: string;
	ariaLabelledBy?: never;
	children?: string;
};

type Props = GeneralProps & (WithChild | WithAriaLabelledBy | WithAriaLabel);

const styles = {
	touchTarget: (size: Size) =>
		classnames(
			'p-0',
			'flex',
			'justify-center',
			'items-center',
			'bg-neo-color-global-background-static-transparent',
			'focus:outline-none',
			'focus-visible:outline-none',
			'group',
			'cursor-default',
			'pointer-fine:rounded-full',
			size === 'small' && ['w-24', 'pointer-fine:h-12', 'pointer-fine:my-6', 'pointer-coarse:h-24'],
			size === 'large' && [
				'w-40',
				'pointer-fine:h-20',
				'pointer-fine:my-10',
				'pointer-coarse:h-40',
			],
			size === 'xlarge' && [
				'w-48',
				'pointer-fine:h-24',
				'pointer-fine:my-12',
				'pointer-coarse:h-48',
			]
		),
	background: (pending: boolean, size: Size, isDisabled: boolean) =>
		classnames(
			'relative',
			'rounded-full',
			'group-focus:outline-none',
			'group-focus-visible:outline-none',
			'overflow-hidden',
			'z-0',
			pending && 'cursor-wait',
			size === 'small' && ['w-24', 'h-12'],
			size === 'large' && ['w-40', 'h-20'],
			size === 'xlarge' && ['w-48', 'h-24'],
			isDisabled
				? ['bg-neo-color-global-background-neutral-intense-disabled', 'cursor-not-allowed']
				: ['bg-neo-color-global-background-neutral-intense-default', 'cursor-pointer']
		),
	track: (size: Size, checked: boolean, isDisabled: boolean, pending: boolean) =>
		classnames(
			'absolute',
			'flex',
			'justify-end',
			'left-0',
			'rounded-full',
			'w-full',
			'ease-out',
			'duration-150',
			'transition-colors',
			!pending && 'transition-transform',
			size === 'small' && 'h-12',
			size === 'large' && 'h-20',
			size === 'xlarge' && 'h-24',
			checked && ['translate-x-0'],
			checked &&
				pending && [
					'bg-neo-color-global-background-primary-intense-default',
					classes.animateCheckedPending,
				],
			checked && isDisabled && ['bg-neo-color-global-background-primary-intense-disabled'],
			checked &&
				!isDisabled && [
					'bg-neo-color-global-background-primary-intense-default',
					classes.translateActiveChecked,
				],
			!checked && !pending && ['bg-transparent', classes.translateUnchecked],
			!checked &&
				pending && [
					'bg-neo-color-global-background-primary-intense-default',
					classes.animateUncheckedPending,
				],
			!checked &&
				!isDisabled && [
					'group-active:bg-neo-color-global-background-primary-intense-default',
					classes.translateActiveUnchecked,
				]
		),
	handle: (size: Size, checked: boolean, isDisabled: boolean) =>
		classnames(
			'rounded-full',
			size === 'small' && ['w-8', 'h-8', 'm-2'],
			size === 'large' && ['w-14', 'h-14', classes.ring30Margin],
			size === 'xlarge' && ['w-16', 'h-16', 'm-4'],
			!isDisabled && 'bg-neo-color-global-component-toggle-handle-background-default',
			isDisabled && 'bg-neo-color-global-component-toggle-handle-background-disabled'
		),
	focusVisibleRing: (size: Size, checked: boolean) =>
		classnames(
			'absolute',
			'rounded-full',
			'h-full',
			'w-full',
			'outline-none',
			'pointer-events-none',
			'group-focus-visible:ring-focus-inset',
			'z-20',
			size === 'small' && 'group-focus-visible:ring-2',
			size === 'large' && 'group-focus-visible:ring',
			size === 'xlarge' && 'group-focus-visible:ring-4',
			checked
				? 'group-focus-visible:ring-neo-color-global-border-static-focus'
				: 'group-focus-visible:ring-neo-color-global-border-static-focus'
		),
	toggleContainer: classnames('flex', 'flex-row', 'items-center', 'space-x-8'),
	labelText: (size: Size, isDisabled: boolean) =>
		classnames(
			'font-brand',
			'font-normal',
			'select-none',
			size === 'small' && classnames('text-xs/16'),
			size === 'large' && classnames('text-base/20'),
			size === 'xlarge' && classnames('text-base/24'),
			isDisabled && 'text-neo-color-global-content-primary-disabled',
			!isDisabled && 'text-neo-color-global-content-neutral-intense'
		),
};

export const Toggle = ({
	size = 'large',
	ariaLabel,
	ariaLabelledBy,
	checked,
	disabled,
	pending = false,
	onChange,
	children,
}: Props): JSX.Element => {
	const disabledContextValue = React.useContext(DisabledContext);
	const isDisabled = isContextDisabled(disabled, disabledContextValue);

	// Used for improved readability only
	const TouchTarget = 'button';
	const Background = 'div';
	const Track = 'div';
	const Handle = 'span';
	const FocusVisibleRing = 'div';

	const ariaLabelOrId = (labelText?: string) =>
		ariaLabelledBy
			? { 'aria-labelledby': ariaLabelledBy }
			: { 'aria-label': ariaLabel || labelText };

	const renderLabel = (labelText?: string) =>
		labelText ? <span className={styles.labelText(size, isDisabled)}>{labelText}</span> : null;

	const appendLabel = (toggle: JSX.Element) =>
		children ? (
			<div className={styles.toggleContainer}>
				{toggle}
				{renderLabel(children)}
			</div>
		) : (
			toggle
		);

	return appendLabel(
		<TouchTarget
			className={styles.touchTarget(size)}
			{...ariaLabelOrId(children)} // eslint-disable-line
			type="button"
			onClick={e => {
				if (!pending) {
					onChange(e);
				}
			}}
			role="switch"
			aria-checked={checked}
			disabled={isDisabled}
		>
			<Background role="presentation" className={styles.background(pending, size, isDisabled)}>
				<Track role="presentation" className={styles.track(size, checked, isDisabled, pending)}>
					<Handle role="presentation" className={styles.handle(size, checked, isDisabled)} />
				</Track>
				<FocusVisibleRing aria-hidden className={styles.focusVisibleRing(size, checked)} />
			</Background>
		</TouchTarget>
	);
};
