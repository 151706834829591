import { ApiErrors } from './errors/ApiError';

const NQ_ONLY = 'nq_only';
const WAS_NQ_HEADER = 'X-Sipgate-Was-NQ-Request';

class HttpClient {
	private static internalServerErrorMiddleware(response: Response): Promise<Response> {
		if (
			response.status === 500 &&
			!response.headers.get('Content-Type')?.includes('application/json')
		) {
			return Promise.reject(ApiErrors.GeneralApiError('Internal Server Error'));
		}

		return Promise.resolve(response);
	}

	private static checkForNqOnly(response: Response) {
		const headers = response.headers;
		if (sessionStorage.getItem(NQ_ONLY)) {
			sessionStorage.removeItem(NQ_ONLY);
		}

		if (headers.has(WAS_NQ_HEADER.toLowerCase())) {
			sessionStorage.setItem(NQ_ONLY, 'true');
		}

		return Promise.resolve(response);
	}

	public static fetch(input: RequestInfo | URL, init?: RequestInit) {
		return fetch(input, init).then(this.internalServerErrorMiddleware).then(this.checkForNqOnly);
	}
}

export default HttpClient;
