import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ManagedForm } from '@web-apps/forms';
import { ManagedInput } from '@panda/ui';
import { useAppSelector } from '../../hooks/redux';
import useTranslations from '../../hooks/translations';
import { getSignupError } from '../../redux/slices/signup/selectors';

const EmailField = () => {
	const signupError = useAppSelector(getSignupError);
	const { translate } = useTranslations();

	const formik = useFormikContext<{ email: string }>();

	useEffect(() => {
		if (signupError && signupError.name === 'EMAIL_ALREADY_EXISTS') {
			formik.setErrors({
				email: translate('SIGNUP_ERROR_EMAIL_ALREADY_EXISTS'),
			});
		}
	}, [signupError, formik, translate]);

	return (
		<ManagedInput
			managedField={ManagedForm.buildField(formik, 'email')}
			label={translate('SIGNUP_LOGIN_EMAIL_LABEL')}
			readOnly
			placeholder=""
		/>
	);
};

export default EmailField;
