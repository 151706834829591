import { ManagedCheckbox } from '@panda/ui';
import { ManagedForm } from '@web-apps/forms';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';
import useTranslations from '../../../hooks/translations';
import classes from './EmergencyCallsGroup.scss';

export const EmergencyCallsGroup = () => {
	const { translate } = useTranslations();
	const formik = useFormikContext<{ emergencyCalls: boolean }>();

	return (
		<div className={classes.group}>
			<div className={classnames(classes.row, classes.width12)}>
				<ManagedCheckbox managedField={ManagedForm.buildField(formik, 'emergencyCalls')}>
					{translate('SIGNUP_UK_EMERGENCY_CALLS')}
				</ManagedCheckbox>
			</div>
		</div>
	);
};
