/* eslint-disable @web-apps/no-custom-colors */

export const getInitials = (firstname: string, lastname: string) => {
	return (firstname.substring(0, 1) + lastname.substring(0, 1)).toLocaleUpperCase();
};

const hashCode = (s: string) => {
	let h = 0;
	for (let i = 0; i < s.length; i += 1) {
		// eslint-disable-next-line no-bitwise
		h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
	}

	return Math.abs(h);
};

export const getColorClasses = (firstname: string, lastname: string, email?: string) => {
	const colors = [
		{
			background: 'fill-neo-color-global-component-avatar-background-1-4',
			text: 'fill-neo-color-global-component-avatar-content-1-4',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-1-3',
			text: 'fill-neo-color-global-component-avatar-content-1-3',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-3-4',
			text: 'fill-neo-color-global-component-avatar-content-3-4',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-3-3',
			text: 'fill-neo-color-global-component-avatar-content-3-3',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-2-2',
			text: 'fill-neo-color-global-component-avatar-content-2-2',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-2-4',
			text: 'fill-neo-color-global-component-avatar-content-2-4',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-2-3',
			text: 'fill-neo-color-global-component-avatar-content-2-3',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-3-2',
			text: 'fill-neo-color-global-component-avatar-content-3-2',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-1-2',
			text: 'fill-neo-color-global-component-avatar-content-1-2',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-2-1',
			text: 'fill-neo-color-global-component-avatar-content-2-1',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-3-1',
			text: 'fill-neo-color-global-component-avatar-content-3-1',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-7-4',
			text: 'fill-neo-color-global-component-avatar-content-7-4',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-1-1',
			text: 'fill-neo-color-global-component-avatar-content-1-1',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-7-3',
			text: 'fill-neo-color-global-component-avatar-content-7-3',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-5-4',
			text: 'fill-neo-color-global-component-avatar-content-5-4',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-7-2',
			text: 'fill-neo-color-global-component-avatar-content-7-2',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-6-4',
			text: 'fill-neo-color-global-component-avatar-content-6-4',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-5-3',
			text: 'fill-neo-color-global-component-avatar-content-5-3',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-7-1',
			text: 'fill-neo-color-global-component-avatar-content-7-1',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-5-2',
			text: 'fill-neo-color-global-component-avatar-content-5-2',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-6-3',
			text: 'fill-neo-color-global-component-avatar-content-6-3',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-5-1',
			text: 'fill-neo-color-global-component-avatar-content-5-1',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-4-4',
			text: 'fill-neo-color-global-component-avatar-content-4-4',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-4-3',
			text: 'fill-neo-color-global-component-avatar-content-4-3',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-6-2',
			text: 'fill-neo-color-global-component-avatar-content-6-2',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-6-1',
			text: 'fill-neo-color-global-component-avatar-content-6-1',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-4-2',
			text: 'fill-neo-color-global-component-avatar-content-4-2',
		},
		{
			background: 'fill-neo-color-global-component-avatar-background-4-1',
			text: 'fill-neo-color-global-component-avatar-content-4-1',
		},
	];

	const hash = hashCode(`${firstname}${lastname}${email || ''}`);
	const index = Math.min(Math.max(hash % colors.length, 0), colors.length - 1);

	return colors[index];
};
