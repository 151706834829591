interface LoadingAnimationHook {
	hide(): void;
}
export const useLoadingAnimation = (): LoadingAnimationHook => {
	return {
		hide: () => {
			const animation = document.getElementById('loading-animation');

			if (animation) {
				setTimeout(() => {
					animation.remove();
				}, 600);

				animation.classList.add('exit');
			}
		},
	};
};
