import { ForwardedRef, MutableRefObject, RefCallback, RefObject } from 'react';

export function mergeRefs<T>(...refs: (RefObject<T> | ForwardedRef<T>)[]): RefCallback<T> {
	return value => {
		for (const ref of refs) {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref !== null) {
				(ref as MutableRefObject<T | null>).current = value;
			}
		}
	};
}
