import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';
import { usePandaContext } from '../../contexts/pandaContext';

export type Props = {
	items: {
		url: string;
		label: string;
	}[];
};

const styles = {
	nav: classnames('m-16', 'bg-neo-color-global-background-static-transparent'),
	list: classnames('list-none', 'p-0', 'm-0'),
	link: (isActive: boolean) =>
		classnames(
			'pl-16',
			'pr-8',
			'py-12',
			'flex',
			'justify-between',
			'items-center',
			'gap-4',
			'no-underline',
			'rounded',
			'duration-150',
			'ease-in-out',
			'transition-colors',
			'focus-visible:ring-focus-inset',
			'select-none',
			!isActive && [
				'text-neo-color-global-content-neutral-intense',
				'bg-neo-color-global-background-static-transparent',
				'hover:text-neo-color-global-content-primary-intense',
				'hover:bg-neo-color-global-background-primary-soft-hover',
				'active:text-neo-color-global-content-primary-intense',
				'active:bg-neo-color-global-background-primary-soft-active',
			],
			isActive && [
				// We need to specify all the states because the app-web has defaults for links
				'text-neo-color-global-content-primary-intense',
				'bg-neo-color-global-background-primary-soft-default',
				'hover:text-neo-color-global-content-primary-intense',
				'hover:bg-neo-color-global-background-primary-soft-default',
				'active:text-neo-color-global-content-primary-intense',
				'active:bg-neo-color-global-background-primary-soft-active',
			]
		),
};

export const SideBarNavigation = ({ items }: Props) => {
	const { languageKeys } = usePandaContext();

	return (
		<nav className={styles.nav} aria-label={languageKeys.PANDA_SIDEBAR_NAVIGATION_LANDMARK}>
			<ul className={styles.list}>
				{items.map(item => {
					return (
						<li key={item.url}>
							<NavLink to={item.url} className={isActive => styles.link(isActive)}>
								{item.label} <SetIcon icon="next" size="24" />
							</NavLink>
						</li>
					);
				})}
			</ul>
		</nav>
	);
};
