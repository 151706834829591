import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { Translations } from '../../../api/types/translations';
import { CouldNotFetchError, RootTranslationState, TranslationErrors } from './types';
import English from './fallback/translations.en.json';
import German from './fallback/translations.de.json';
import { SupportedLocales } from '../../../types';
import { getLanguage, isFetched, isFetching } from './selectors';

export const fetchTranslations = createAsyncThunk<
	Translations,
	SupportedLocales,
	{ rejectValue: TranslationErrors; state: RootTranslationState }
>(
	'translations/fetchTranslations',
	async (locale, { rejectWithValue }) => {
		try {
			const result = await api.getTranslations(locale);

			if (result.isOk()) {
				return result.value;
			}

			const error = result.error;
			const translations = locale === 'de_DE' ? German : English;
			return rejectWithValue(CouldNotFetchError(error.message, translations, locale));
		} catch (e: unknown) {
			const translations = locale === 'de_DE' ? German : English;
			return rejectWithValue(CouldNotFetchError('Unknown error occurred', translations, locale));
		}
	},
	{
		condition: (locale, { getState }) => {
			if (getLanguage(getState()) !== locale) {
				return true;
			}
			return !isFetching(getState()) && !isFetched(getState());
		},
	}
);
