import classnames from 'classnames';
import React from 'react';
import { Headline } from '../../../components/heading/Headline';
import { ContextMenu } from '../../../components/contextMenu/ContextMenu';
import { MenuItem } from '../../../components/contextMenu/MenuItem';
import { usePandaContext } from '../../../contexts/pandaContext';

interface Props {
	/**
	 * Der Name der Seite.
	 */
	heading: string;

	/**
	 * Falls der Name der Seite sipgate Produkt-Branding haben soll
	 * (wie zum Beispiel **sipgate**.io), kann hier der zweite Teil
	 * des Namens angegeben werden.
	 *
	 * Falls beide Teile von einem Leerzeichen getrennt sein sollen,
	 * muss das `brandSuffix` mit diesem Leerzeichen beginnen.
	 */
	brandSuffix?: string;

	/**
	 * Ein Array von `ContextMenu`-Items.
	 */
	contextMenu?: { label: string; onClick: () => void; critical?: boolean }[];
}

const styles = {
	container: classnames(
		'relative',
		'bg-neo-color-web-app-surface-sidebarhero',
		'border-neo-color-global-border-neutral-soft-default',
		'border-b',
		'py-[2.25rem]'
	),
	contextMenu: classnames('absolute', 'top-0', 'right-0', 'm-16'),
	heading: classnames(
		'm-0',
		'text-center',
		'text-neo-color-global-content-neutral-intense',
		'leading-32',
		'text-2xl',
		'font-bold',
		'select-none'
	),
	brandSuffix: classnames('text-[1.05em]', 'font-light', 'font-meta'),
	skipToContentLink: classnames(
		'sr-only',
		'focus:not-sr-only',
		'focus:absolute',
		// mimics screaming link button
		'focus:font-bold',
		'focus:no-underline',
		'focus:p-8',
		'focus:p-16',
		'focus:bg-neo-color-global-background-electric-intense-default',
		'focus:hover:bg-neo-color-global-background-electric-intense-hover',
		'focus:active:bg-neo-color-global-background-electric-intense-active',
		'focus:text-neo-color-global-content-electric-on-intense',
		'focus:hover:text-neo-color-global-content-electric-on-intense',
		'focus:active:text-neo-color-global-content-electric-on-intense',
		'focus:rounded',
		'focus:z-800',
		'focus-visible:ring-focus-inset'
	),
};

export const SideBarTextHeader = ({ heading, contextMenu, brandSuffix }: Props) => {
	const { languageKeys } = usePandaContext();

	return (
		<div className={styles.container}>
			<Headline className={styles.heading}>
				{heading}
				{brandSuffix ? <span className={styles.brandSuffix}>{brandSuffix}</span> : null}
			</Headline>

			<a href="#content" className={styles.skipToContentLink}>
				{languageKeys.PANDA_SIDEBAR_NAVIGATION_SKIP_TO_CONTENT_LINK}
			</a>

			<div className={styles.contextMenu}>
				{contextMenu && (
					<ContextMenu>
						{contextMenu.map(contextMenuItem => (
							<MenuItem
								key={contextMenuItem.label}
								onClick={contextMenuItem.onClick}
								critical={contextMenuItem.critical}
							>
								{contextMenuItem.label}
							</MenuItem>
						))}
					</ContextMenu>
				)}
			</div>
		</div>
	);
};
