import classnames from 'classnames';
import React from 'react';

type Props = Omit<React.LabelHTMLAttributes<HTMLLabelElement>, 'className'>;

const styles = {
	label: classnames(
		'block',
		'mb-4',
		'mx-2',
		'font-brand',
		'font-normal',
		'text-xs/14',
		'text-neo-color-global-content-neutral-moderate',
		'grow'
	),
};

const Label = ({ children, ...labelProps }: Props): JSX.Element => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading,jsx-a11y/label-has-associated-control
		<label {...labelProps} className={styles.label}>
			{children}
		</label>
	);
};

export { Label };
