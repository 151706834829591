import classNames from 'classnames';
import React, { AriaAttributes } from 'react';
import { LanguageKeys, usePandaContext } from '../../../contexts/pandaContext';

import { icons, SetIconDefinition } from './definitions';

type SetIcons = typeof icons;

interface BaseProps<Icon extends keyof SetIcons> {
	/** Welches Icon soll gerendert werden? */
	icon: Icon;
	/**
	 * Setzt `display: inline-block` und positioniert das icon auf
	 * der Baseline des ihn umgebenen Texts.
	 */
	inline?: 'inline' extends keyof SetIcons[Icon] ? boolean : boolean;
}

type InlineProps<Icon extends keyof SetIcons> =
	| {
			inline: true;
			size: 'text';
			touchSize?: never;
	  }
	| {
			inline?: boolean;
			size: keyof SetIcons[Icon]['box'];
			touchSize?: keyof SetIcons[Icon]['box'];
	  };

type BoxProps<Icon extends keyof SetIcons> = {
	/**
	 * Die Größe in Pixeln welche die Bounding-Box (inklusive Freiraum) des Icons einnehmen
	 * soll.
	 *
	 * Nicht alle Icons sind in allen Größen verfügbar.
	 *
	 * Für einige Icons gibt es auch die Möglichkeit `text` anzugeben, falls es
	 * `inline` gerendert werden soll. Dies erlaubt es das Icon mit dem umgebenen Text
	 * zu skalieren.
	 */
	size: keyof SetIcons[Icon]['box'];
	/**
	 * Die Größe in Pixeln welche die Bounding-Box (inklusive Freiraum) des Icons auf
	 * touch Geräten einnehmen soll.
	 *
	 * Nicht alle Icons sind in allen Größen verfügbar.
	 */
	touchSize?: keyof SetIcons[Icon]['box'];
};

type SizeProps<Icon extends keyof SetIcons> = 'inlineSvg' extends keyof SetIcons[Icon]
	? InlineProps<Icon>
	: BoxProps<Icon>;

type Props<Icon extends keyof SetIcons> = BaseProps<Icon> & SizeProps<Icon> & AriaAttributes;

const styles = (inline: boolean, size: string, touchSize?: string) =>
	classNames(
		'panda-icon',

		size === 'text' && ['h-text', 'w-text'],
		inline && 'inline-block',
		!inline && 'block',

		size === '12' && ['min-h-[.75rem]', 'min-w-[.75rem]'],
		size === '16' && ['min-h-[1rem]', 'min-w-[1rem]'],
		size === '24' && ['min-h-[1.5rem]', 'min-w-[1.5rem]'],
		size === '32' && ['min-h-[2rem]', 'min-w-[2rem]'],
		size === '64' && ['min-h-[4rem]', 'min-w-[4rem]'],

		touchSize === '12' && ['pointer-coarse:h-[.75rem]', 'pointer-coarse:w-[.75rem]'],
		touchSize === '16' && ['pointer-coarse:h-[1rem]', 'pointer-coarse:w-[1rem]'],
		touchSize === '24' && ['pointer-coarse:h-[1.5rem]', 'pointer-coarse:w-[1.5rem]'],
		touchSize === '32' && ['pointer-coarse:h-[2rem]', 'pointer-coarse:w-[2rem]'],
		touchSize === '64' && ['pointer-coarse:h-[4rem]', 'pointer-coarse:w-[4rem]'],

		// Offset icon spacing so baseline is aligned correctly
		inline && size !== 'text' && size === '12' && '-mb-1',
		inline && size !== 'text' && size === '16' && '-mb-1',
		inline && size !== 'text' && size === '24' && '-mb-[0.1875rem]',
		inline && size !== 'text' && size === '32' && '-mb-[0.1875rem]',
		inline && size !== 'text' && size === '64' && '-mb-[0.375rem]',

		inline && size !== 'text' && touchSize === '12' && 'pointer-coarse:-mb-1',
		inline && size !== 'text' && touchSize === '16' && 'pointer-coarse:-mb-1',
		inline && size !== 'text' && touchSize === '24' && 'pointer-coarse:-mb-[0.1875rem]',
		inline && size !== 'text' && touchSize === '32' && 'pointer-coarse:-mb-[0.1875rem]',
		inline && size !== 'text' && touchSize === '64' && 'pointer-coarse:-mb-[0.375rem]'
	);

export const SetIcon = <I extends keyof SetIcons>({
	icon,
	size,
	inline,
	touchSize,
	className,

	...aria
}: Props<I> & { className?: string }) => {
	const ico = icons[icon] as SetIconDefinition;

	const iconSvg = size === 'text' ? ico.inlineSvg : ico.box[size as string];
	const touchSvg = touchSize ? ico.box[touchSize as string] : undefined;

	if (iconSvg === undefined) {
		throw new Error('This should never happen');
	}

	return (
		<span
			className={classNames(
				className,
				`panda-icon-${iconSvg}`,
				touchSvg ? `pointer-coarse:panda-icon-${touchSvg}` : undefined,
				styles(!!inline, size as string, touchSize as string)
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...aria}
		/>
	);
};

export const ExportedSetIcon = <I extends keyof SetIcons>(props: Props<I>) =>
	SetIcon(props as Props<I> & { className?: string });

export const getIconLabel = (lks: LanguageKeys, icon: keyof SetIcons) => lks[icons[icon].label];

export const useIconLabel = (icon: keyof SetIcons) =>
	getIconLabel(usePandaContext().languageKeys, icon);

export const SetIconLabel = ({ icon }: { icon: keyof SetIcons }) => {
	const label = useIconLabel(icon);

	return <>{label}</>;
};
