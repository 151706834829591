import { useAppDispatch, useAppSelector } from './redux';
import {
	AddressError,
	addressesSlice,
	getAddresses,
	getCities,
	getCitiesLastError,
	getCity,
	getStreets,
	getStreetsLastError,
	getZip,
	hasAddressesLastError,
	hasCitiesLastError,
	hasStreetsFetched,
	hasStreetsLastError,
	isCitiesFetching,
	isFetched,
	isFetching,
	isStreetsFetching,
	setCity,
	setZip,
} from '../redux/slices/addresses';
import { getCountry } from '../redux/slices/signup/selectors';
import { UkAddressResponse } from '../api/types/UkAddressResponse';

interface AddressDataHook {
	city: string;
	zip: string;
	country: string;

	cities: string[];
	hasCitiesError: boolean;
	citiesError?: AddressError;
	isCitiesFetching: boolean;
	hasCitiesFetched: boolean;

	streets: string[];
	hasStreetsError: boolean;
	streetsError?: AddressError;
	isStreetsFetching: boolean;
	hasStreetsFetched: boolean;

	setCity: (city: string) => void;
	setZip: (zip: string, country: string) => void;
	clearAddressData: (country: string) => void;

	addresses: UkAddressResponse[];
	hasAddressError: boolean;

	fetched: boolean;
	fetching: boolean;
	hasError: boolean;
	error?: AddressError;
}

export const useAddressData = (): AddressDataHook => {
	const dispatch = useAppDispatch();
	const fetched = useAppSelector(isFetched);
	const fetching = useAppSelector(isFetching);
	const hasError = useAppSelector(hasAddressesLastError);

	const cities = useAppSelector(getCities);
	const hasCitiesError = useAppSelector(hasCitiesLastError);
	const citiesError = useAppSelector(getCitiesLastError);

	const streets = useAppSelector(getStreets);
	const hasStreetsError = useAppSelector(hasStreetsLastError);
	const streetsError = useAppSelector(getStreetsLastError);

	const clearAddressData = (c: string) => dispatch(addressesSlice.actions.clearAddressData(c));

	return {
		city: useAppSelector(getCity),
		zip: useAppSelector(getZip),
		country: useAppSelector(getCountry),

		cities,
		hasCitiesError,
		citiesError,
		isCitiesFetching: useAppSelector(isCitiesFetching),
		hasCitiesFetched: useAppSelector(hasStreetsFetched),

		streets,
		hasStreetsError,
		streetsError,
		isStreetsFetching: useAppSelector(isStreetsFetching),
		hasStreetsFetched: useAppSelector(hasStreetsFetched),

		addresses: useAppSelector(getAddresses),
		hasAddressError: useAppSelector(hasAddressesLastError),

		fetched,
		fetching,
		hasError,
		setCity: (city: string) => dispatch(setCity(city)),
		setZip: (zip: string, country: string) => dispatch(setZip({ zip, country })),
		clearAddressData,
	};
};

export default useAddressData;
