import { Translate, Translations } from '@web-apps/translations';
import { sprintf } from 'sprintf-js';
import { useAppDispatch, useAppSelector } from './redux';
import {
	getError,
	getLanguage,
	getTranslations,
	hasTranslationsError,
	isFetched,
	isFetching,
} from '../redux/slices/translations/selectors';
import { fetchTranslations, TranslationErrors } from '../redux/slices/translations';
import { SupportedLocales } from '../types';

const isProduction = () => (process.env.NODE_ENV ? process.env.NODE_ENV === 'production' : true);

export const translate =
	(translations: Translations): Translate =>
	(lk, ...replacements) => {
		const translated = translations[lk] || '';

		if (translated || isProduction()) {
			try {
				return replacements === null ? translated : sprintf(translated, ...replacements);
			} catch (e) {
				return '';
			}
		}

		return `### ${lk} ###`;
	};

interface TranslationsHook {
	language: SupportedLocales;
	fetched: boolean;
	fetching: boolean;
	fetchTranslations: () => void;
	translate: Translate;
	hasError: boolean;
	error?: TranslationErrors;
}

export const useTranslations = (): TranslationsHook => {
	const dispatch = useAppDispatch();
	const fetched = useAppSelector(isFetched);
	const fetching = useAppSelector(isFetching);
	const translations = useAppSelector(getTranslations);
	const language = useAppSelector(getLanguage);
	const hasError = useAppSelector(hasTranslationsError);
	const error = useAppSelector(getError);

	if (!fetched && !fetching) {
		dispatch(fetchTranslations(language));
	}

	if (fetched && !fetching) {
		document.documentElement.lang = language === 'de_DE' ? 'de' : 'en';
	}

	return {
		language,
		fetched,
		fetching,
		fetchTranslations: () => dispatch(fetchTranslations(language)),
		translate: translate(translations),
		hasError,
		error,
	};
};

export default useTranslations;
