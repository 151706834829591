import React, { useEffect, useState } from 'react';
import { pickByEnvironment } from '@web-apps/service-urls';
import ErrorPage from '../pages/error/ErrorPage';
import { useAppSelector } from '../hooks/redux';
import { usePusherContext } from '../components/PusherContext';
import { getSignupError } from '../redux/slices/signup/selectors';
import useTranslations from '../hooks/translations';
import { useLoadingAnimation } from '../hooks/LoadingAnimation';

function extractActivationCode(str: string) {
	const regex = /[a-f0-9]{10}/; // Match a 10-character hexadecimal string
	const match = str.match(regex); // Search for the regex in the input string

	if (match && match.length > 0) {
		return match[0]; // Return the first match found
	}
	return null; // No match found
}

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
	const signupError = useAppSelector(getSignupError);
	const translations = useTranslations();

	const identifier = useAppSelector(state => state.signup.identifier);
	const pusher = usePusherContext();

	const loadingAnimation = useLoadingAnimation();

	const [showError, setShowError] = useState(false);

	useEffect(() => {
		if (identifier) {
			pusher.client.then(client => {
				return client.listen<{ status: string }>(
					identifier,
					'signup_status',
					(event: { status: string }) => {
						if (event.status === 'failed') {
							setShowError(true);
						}
					}
				);
			});
		}
	}, [identifier, pusher]);

	if (signupError && signupError.name === 'ACTIVATION_NOT_FOUND_ERROR') {
		const activationCode = extractActivationCode(signupError.message);
		if (activationCode) {
			window.location.href =
				pickByEnvironment('activationUrl', {
					local: 'https://secure.local.sipgate.de:10443/signup/activate/id/',
					development: 'https://secure.dev.sipgate.de/signup/activate/id/',
					production: 'https://secure.live.sipgate.de/signup/activate/id/',
				}) + activationCode;
		} else {
			loadingAnimation.hide();
			return <ErrorPage />;
		}
	}

	if (
		showError ||
		translations.hasError ||
		(signupError && signupError.name === 'COULD_NOT_CREATE_PENDING_SUBSCRIBER')
	) {
		loadingAnimation.hide();
		return <ErrorPage />;
	}

	if (signupError && signupError.name === 'ALREADY_ACTIVATED_ERROR') {
		window.location.href = pickByEnvironment('login', {
			local: 'https://login.dev.sipgate.com',
			development: 'https://login.dev.sipgate.com',
			production: 'https://login.sipgate.com',
		});
	}

	return <>{children}</>;
};

export default ErrorBoundary;
