import React from 'react';
import { HeadingBoundary, Headline, ManagedInput, ManagedSelect, Option } from '@panda/ui';
import classnames from 'classnames';
import { ManagedForm } from '@web-apps/forms';
import { useFormikContext } from 'formik';
import classes from './PersonalData.scss';
import useTranslations from '../../../hooks/translations';

type FormValues = {
	salutation: string;
	firstname: string;
	lastname: string;
	phoneNumber: string;
};

const PersonalData = () => {
	const { translate } = useTranslations();
	const formik = useFormikContext<FormValues>();

	return (
		<div
			id="PersonalDataGroup"
			className={classes.group}
			role="region"
			aria-labelledby="PersonalDataGroupHeadline"
		>
			<HeadingBoundary>
				<Headline id="PersonalDataGroupHeadline">
					{translate('SIGNUP_PERSONAL_DATA_HEADLINE')}
				</Headline>
			</HeadingBoundary>
			<div className={classnames(classes.row, classes.width6, classes.mobilefw)}>
				<ManagedSelect
					managedField={ManagedForm.buildField(formik, 'salutation')}
					title={translate('SIGNUP_PERSONAL_DATA_SALUTATION_TITLE')}
					placeholder={translate('SIGNUP_PERSONAL_DATA_SALUTATION_PLACEHOLDER')}
				>
					<Option value="m">{translate('SIGNUP_PERSONAL_DATA_SALUTATION_OPTION_MR')}</Option>
					<Option value="f">{translate('SIGNUP_PERSONAL_DATA_SALUTATION_OPTION_MS')}</Option>
				</ManagedSelect>
			</div>
			<div className={classnames(classes.row)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'firstname')}
					label={translate('SIGNUP_PERSONAL_DATA_FIRSTNAME_LABEL')}
					placeholder=""
				/>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'lastname')}
					label={translate('SIGNUP_PERSONAL_DATA_LASTNAME_LABEL')}
					placeholder=""
				/>
			</div>

			<div className={classnames(classes.row, classes.width6, classes.mobilefw)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'phoneNumber')}
					label={translate('SIGNUP_PERSONAL_DATA_PHONENUMBER_LABEL')}
					placeholder=""
				/>
			</div>
		</div>
	);
};

export default PersonalData;
