import React from 'react';
import classnames from 'classnames';

import { Avatar } from '@panda/ui';
import { useAppSelector } from '../../../hooks/redux';
import classes from './SocialUser.scss';

const SocialUser = () => {
	const signupState = useAppSelector(state => state.signup);

	return (
		<div
			className={classnames(
				classes.wrapper,
				signupState.identityProvider && classes[`${signupState.identityProvider}`]
			)}
		>
			<div>
				<Avatar
					firstname={signupState.firstname || ''}
					lastname={signupState.lastname || ''}
					email={signupState.email || ''}
					size="large"
					imageUrl={signupState.avatarUrl || undefined}
				/>
			</div>
			<div>
				<div className={classes.name}>
					{signupState.firstname} {signupState.lastname}
				</div>
				<div className={classes.email}>{signupState.email}</div>
			</div>
		</div>
	);
};

export default SocialUser;
