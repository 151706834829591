import { Formik, FormikErrors, FormikHelpers, FormikState } from 'formik';
import React from 'react';
import {
	ManagedForm,
	ManagedFieldProps,
	FieldValues,
	SubmitResult,
	ChildProps,
	ManagedFormValidator,
	SubmitErrors,
	FieldValidators,
	ValidatorResults,
} from './ManagedForm';

export interface InitData<Source extends FieldValues, Validators extends FieldValidators<Source>> {
	initialValues: Source;
	fieldValidators: Validators;
	formValidator: ManagedFormValidator<ValidatorResults<Source, Validators>>;
	onSubmit: (
		data: ValidatorResults<Source, Validators>,
		form: SubmitErrors<keyof Source>
	) => SubmitResult<keyof Source> | Promise<SubmitResult<keyof Source>>;
}

type HTMLSubmitHandler<Source extends FieldValues, Validators extends FieldValidators<Source>> = (
	e: React.FormEvent<HTMLFormElement>,
	onSubmit: (
		data: ValidatorResults<Source, Validators>,
		form: SubmitErrors<keyof Source>
	) => SubmitResult<keyof Source> | Promise<SubmitResult<keyof Source>>
) => void;

// We need to use any, because usage of this context becomes incredibly unergonomic otherwise.
// This kinda sucks, but the loss of type safety is kinda inherent to generic contexts.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ManagedFormContext = React.createContext<FormState<any, any> | null>(null);
ManagedFormContext.displayName = 'ManagedFormContext';

export const ManagedFormProvider = ManagedFormContext.Provider;
export const ManagedFormConsumer = ManagedFormContext.Consumer;

export type FormState<Source extends FieldValues, Validators extends FieldValidators<Source>> =
	| {
			initialized: false;
			init: (data: InitData<Source, Validators>) => void;
	  }
	| {
			initialized: true;
			childProps: ChildProps<Source>;
			onSubmit: HTMLSubmitHandler<Source, Validators>;
	  };

export type InferState<Form> =
	Form extends React.ComponentType<Props<infer Source, infer Validators>>
		? FormState<Source, Validators>
		: never;

interface Props<Source extends FieldValues, Validators extends FieldValidators<Source>> {
	children: (data: FormState<Source, Validators>) => React.ReactNode;
}

interface State<Source extends FieldValues, Validators extends FieldValidators<Source>> {
	data?: {
		initialValues: Source;

		fieldValidators: Validators;
		formValidator: ManagedFormValidator<ValidatorResults<Source, Validators>>;
		validate: (values: Source) => FormikErrors<Source>;
	};
	showFormErrors: boolean;
	submitFormErrors: string[];
	validateFormErrors: string[];
}

export class ManagedFormState<
	Source extends FieldValues,
	Validators extends FieldValidators<Source>,
> extends React.Component<Props<Source, Validators>, State<Source, Validators>> {
	public state: State<Source, Validators> = {
		showFormErrors: false,
		submitFormErrors: [],
		validateFormErrors: [],
	};

	// Explicitly not in state, to avoid rerendering our children.
	//
	// Basically submitting the form saves the method to our class
	// before calling the `onSubmit` formik passed to us.
	//
	// This allows the `formikSubmit` method we previously passed to Formik, to access
	// the user-provided onSubmit without having to rerender (hooray for closure fuckery).
	//
	// All this, so passing a new closure on every render does not result in a rerendering loop.
	private onSubmit:
		| ((
				data: ValidatorResults<Source, Validators>,
				form: SubmitErrors<keyof Source>
		  ) => SubmitResult<keyof Source> | Promise<SubmitResult<keyof Source>>)
		| null = null;

	// A cache to keep our field objects from changing on each render even if their data did not change.
	private fieldCache: { [field in keyof Source]?: ManagedFieldProps<Source[field]> } = {};

	private init = (
		data: InitData<Source, Validators>,
		resetForm: (nextState?: Partial<FormikState<Source>>) => void
	) => {
		this.setState(state => {
			if (state.data) {
				return state;
			}

			resetForm({ values: data.initialValues });

			return {
				data: {
					initialValues: data.initialValues,
					fieldValidators: data.fieldValidators,
					formValidator: data.formValidator,
					validate: (values: Source) => {
						const { fieldErrors, formErrors } = ManagedForm.collectData(
							data.fieldValidators,
							data.formValidator,
							values
						);

						if (formErrors) {
							this.setState(s => (s.showFormErrors ? { ...s, validateFormErrors: formErrors } : s));
						}

						return fieldErrors;
					},
				},
				showFormErrors: false,
				submitFormErrors: [],
				validateFormErrors: [],
			};
		});
	};

	private formikSubmit = async (values: Source, helpers: FormikHelpers<Source>) => {
		if (!this.state.data || !this.onSubmit) {
			return;
		}

		const submitData = ManagedForm.collectData(
			this.state.data.fieldValidators,
			this.state.data.formValidator,
			values
		);

		// We always want to reset field errors
		helpers.setErrors(submitData.fieldErrors);

		if (submitData.state !== 'fields-invalid') {
			// But we want to keep full-form errors until we reevaluated them.
			// (We want to avoid hiding an error if we havent even checked if its still applying.)
			this.setState({ showFormErrors: true, validateFormErrors: submitData.formErrors });
		}

		if (submitData.state === 'valid') {
			this.setState({ submitFormErrors: [], validateFormErrors: [] });

			const errors = await this.onSubmit(submitData.values, new SubmitErrors());
			if (!errors) {
				return;
			}

			for (const [field, message] of Object.entries(errors.fieldErrors)) {
				if (message) {
					helpers.setFieldError(field, message);
				}
			}

			this.setState({ submitFormErrors: Array.from(errors.formErrors) });
		}
	};

	public render() {
		if (!this.state.data) {
			return (
				<Formik initialValues={{}} onSubmit={() => {}}>
					{({ resetForm }) =>
						this.props.children({
							initialized: false,
							init: data => this.init(data, resetForm),
						})
					}
				</Formik>
			);
		}

		return (
			<Formik
				initialValues={this.state.data.initialValues}
				onSubmit={this.formikSubmit}
				validate={this.state.data.validate}
			>
				{formik => {
					const fieldValues = {} as { [field in keyof Source]: Source[field] };

					const fields = {} as { [field in keyof Source]: ManagedFieldProps<Source[field]> };
					for (const fieldName in formik.initialValues) {
						const field = ManagedForm.buildField(formik, fieldName, this.fieldCache);

						fields[fieldName] = field;
						this.fieldCache[fieldName] = field;
						fieldValues[fieldName] = formik.values[fieldName] ?? formik.initialValues[fieldName];
					}

					const form = {
						canBeSubmitted: !formik.isSubmitting,
						errors: [...this.state.submitFormErrors, ...this.state.validateFormErrors],
					};

					return this.props.children({
						initialized: true,
						childProps: { fields, form },
						onSubmit: (e, onSubmit) => {
							this.onSubmit = onSubmit;
							formik.handleSubmit(e);
						},
					});
				}}
			</Formik>
		);
	}
}
