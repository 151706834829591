import React, { useContext } from 'react';
import { PusherWrapper } from '../api/clients/Pusher';

export const PusherContext = React.createContext<{
	client: Promise<PusherWrapper>;
}>({
	// will never resolve.
	client: new Promise<PusherWrapper>(() => {}),
});

export const usePusherContext = () => useContext(PusherContext);
