import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { BaseLink } from './BaseLink';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';

type GeneralProps = {
	children: string | ReactNode;
	/**
	 * Setze diese Prop nur, wenn das automatische Resizing bei Touch Devices dein Interface zerstört.
	 * Kümmere dich dann darum, dass die Touchfläche groß genug ist.
	 */
	deprecated?: boolean;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs) */
	disabled?: boolean;
	/** Der Pfad zum Download-Ziel. */
	fileName?: string;
	/** target='blank' sollte nur in Ausnahmen verwendet werden. */
	target?: 'blank';
	url: string;
	/** Diese Funktion wird aufgerufen, wenn die Datei gedownloaded wird und soll genutzt werden, um den Download an sich zu tracken. Dazu nehmen wir in der Regel mixpanel. Sie darf auf keinen Fall dazu genutzt werden, eine Seitennavigation vorzunehmen. Dazu dient das "url"-prop. */
	tracking?: () => void;
};

type AsButtonProps = {
	button: true;
	/** Die Größen "small" und "xlarge" können nur in der variant="loud" gesetzt werden. */
	size?: 'small' | 'medium' | 'large' | 'xlarge';
	width?: 'content' | 'max' | 'max-on-touch-device';
	variant?: 'normal' | 'loud';
	iconOnly?: boolean;
};

type LinkProps = {
	button?: false;
	size?: never;
	width?: never;
	variant?: 'silent' | 'quiet' | 'normal';
	iconOnly?: never;
};

type Props = GeneralProps & (AsButtonProps | LinkProps);

const styles = {
	icon: (
		size: 'small' | 'medium' | 'large' | 'xlarge',
		disabled: boolean,
		button: boolean,
		iconOnly: boolean
	) =>
		classnames(
			'self-center',
			disabled && 'bg-neo-color-global-background-primary-intense-disabled',
			button &&
				!iconOnly && [
					size === 'small' && 'ml-6',
					size === 'medium' && 'ml-8',
					size === 'large' && 'ml-10',
					size === 'xlarge' && 'ml-12',
				],
			!button && ['ml-quarter-text']
		),
	text: classnames('whitespace-normal'),
};

const DownloadLink = ({
	button = false,
	deprecated = false,
	children,
	disabled,
	fileName,
	size = 'medium',
	width = 'content',
	url,
	variant = 'normal',
	iconOnly = false,
	target,
	tracking,
}: Props): JSX.Element => {
	const disabledContextValue = React.useContext(DisabledContext);

	const getIconSize = () => {
		switch (size) {
			case 'small':
				return '12';
			case 'medium':
				return '16';
			case 'large':
			case 'xlarge':
				return '24';
		}
	};

	return (
		<BaseLink
			button={button}
			deprecated={deprecated}
			disabled={disabled}
			size={size}
			width={width}
			type="download"
			fileName={fileName}
			variant={variant}
			iconOnly={iconOnly}
			url={url}
			target={target}
			tracking={tracking}
		>
			{!iconOnly && <span className={styles.text}>{children}</span>}
			{!iconOnly && '\uFEFF'}
			{button ? (
				<SetIcon
					icon="download"
					size={getIconSize()}
					touchSize={!deprecated ? '24' : undefined}
					className={styles.icon(
						size,
						isDisabled(disabled, disabledContextValue),
						button,
						iconOnly
					)}
				/>
			) : (
				<SetIcon
					icon="download"
					inline
					size="text"
					className={styles.icon(
						size,
						isDisabled(disabled, disabledContextValue),
						button,
						iconOnly
					)}
				/>
			)}
		</BaseLink>
	);
};

export { DownloadLink };
