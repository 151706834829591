import { SipgateDomain } from '../redux/slices/signup';
import { useAppSelector } from './redux';
import { getDomain } from '../redux/slices/signup/selectors';

interface DomainHook {
	domain: SipgateDomain;
}
export const useSipgateDomain = (): DomainHook => {
	const domain = useAppSelector(getDomain);

	return {
		domain,
	};
};

export default useSipgateDomain;
