import serviceUrls, { pickByEnvironment } from '@web-apps/service-urls';
import Pusher from 'pusher-js/with-encryption';
import { PusherEvent } from '../types/pusher';

const PRODUCTION_KEY = '394b2e684a08ec4930fb';
const DEVELOPMENT_KEY = '4717477cc60f567aea03';

/*
 * The reason for this class is, that we want to manage
 * subscriptions and listeners in a single location to avoid one instance of a component
 * interrupting another one
 */
export class PusherWrapper {
	private pusher: Pusher;

	public constructor() {
		this.pusher = new Pusher(PusherWrapper.getPusherKey(), {
			cluster: 'eu',
			authEndpoint: `${serviceUrls.restApi}/v3/signup/subscribe`,
		});
	}

	private static getPusherKey() {
		return pickByEnvironment('pusher', {
			local: DEVELOPMENT_KEY,
			development: DEVELOPMENT_KEY,
			production: PRODUCTION_KEY,
		});
	}

	public static async build() {
		return new PusherWrapper();
	}

	public listen<T>(channel: string, event: PusherEvent, handler: (data: T) => void) {
		const internalHandler = (data: T) => handler(data);
		const channelName = `private-encrypted-signup-${channel}`;

		let channelInstance = this.pusher.channel(channelName);
		if (!channelInstance) {
			channelInstance = this.pusher.subscribe(channelName);
		}

		channelInstance.bind(event, internalHandler);

		return () => this.pusher.channel(channelName).unbind(event, internalHandler);
	}
}

export const pusher = PusherWrapper.build();
