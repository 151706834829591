import { AddressApiErrors } from './AddressApiError';

export type UaasApiError =
	| {
			error: 'NO_CITIES_FOUND';
	  }
	| {
			error: 'NO_STREETS_FOUND';
	  }
	| {
			error: 'INVALID_ZIP_CODE';
	  };

export const isUaasApiError = (err: unknown): err is UaasApiError => {
	if (typeof err !== 'object' || err === null) {
		return false;
	}

	if (!('error' in err)) {
		return false;
	}

	return (
		err.error === 'NO_CITIES_FOUND' ||
		err.error === 'NO_STREETS_FOUND' ||
		err.error === 'INVALID_ZIP_CODE'
	);
};

export const uaasErrorToAddressError = (err: UaasApiError) => {
	switch (err.error) {
		case 'INVALID_ZIP_CODE':
			return AddressApiErrors.InvalidZipCode('');
		case 'NO_CITIES_FOUND':
			return AddressApiErrors.NoCitiesFound('');
		case 'NO_STREETS_FOUND':
			return AddressApiErrors.NoStreetsFound('');
		default:
			return AddressApiErrors.GeneralError();
	}
};
