import React from 'react';
import { HeadingContext } from '../../contexts/HeadingContext';

type Props = {
	/**
	 * Alles was einer Überschrift untergeordnet ist.
	 */
	children: React.ReactNode;
	levelOverride?: 1 | 2 | 3 | 4 | 5 | 6;
};
export const HeadingBoundary = ({ levelOverride, children }: Props): JSX.Element => (
	<HeadingContext.Consumer>
		{curLevel => {
			return (
				<HeadingContext.Provider value={{ level: levelOverride || curLevel.level + 1 }}>
					{children}
				</HeadingContext.Provider>
			);
		}}
	</HeadingContext.Consumer>
);
