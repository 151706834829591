import React from 'react';
import classnames from 'classnames';
import classes from './Spinner.scss';

interface Props {
	className?: string;
}

export default class Spinner extends React.Component<Props> {
	public render() {
		return (
			<svg
				// Hack to ensure the spinner is not shown in a broken state before the css is loaded
				style={{ display: 'none' }}
				className={classnames(classes.spinner, this.props.className)}
				viewBox="0 0 66 66"
				data-testid="spinner"
			>
				<circle className={classes.path} cx="33" cy="33" r="30" />
			</svg>
		);
	}
}
