export const GENERAL_API_ERROR = 1001;

const errorCodes = [GENERAL_API_ERROR] as const;
type ErrorCodes = (typeof errorCodes)[number];

export interface GeneralErrorBase<T extends number> {
	apiErrorCode: T;
	message: string;
	cause: unknown;
}

export type ApiError = GeneralErrorBase<ErrorCodes>;

export const ApiErrors = {
	GeneralApiError: (message: string, cause?: unknown): ApiError => ({
		apiErrorCode: GENERAL_API_ERROR,
		message,
		cause,
	}),
};
