import { createSlice } from '@reduxjs/toolkit';
import { AddressError, AddressesState } from './types';
import { fetchCities, fetchStreets, fetchUKAddresses, setZip } from './actions';

export const initialState: AddressesState = {
	zip: '',
	city: '',
	street: '',
	country: 'DE',
	cities: {
		items: [],
		fetching: false,
		fetched: false,
		lastError: undefined,
	},
	streets: {
		items: [],
		fetching: false,
		fetched: false,
		lastError: undefined,
	},
	addresses: {
		items: [],
		fetching: false,
		fetched: false,
		lastError: undefined,
	},
	fetched: false,
	fetching: false,
};

export const addressesSlice = createSlice({
	name: 'addresses',
	initialState,
	reducers: {
		clearAddressData: (state, action) => ({ ...initialState, country: action.payload }),
		setZip: (state, action) => {
			if (action.payload.length === 0) {
				return {
					...state,
					zip: initialState.zip,
					city: initialState.zip,
					street: initialState.street,
					cities: initialState.cities,
					streets: initialState.streets,
				};
			}

			return {
				...state,
				zip: action.payload,
				city: initialState.city,
				street: initialState.street,
				cities: initialState.cities,
				streets: initialState.streets,
			};
		},
		setCity: (state, action) => {
			if (action.payload.length === 0) {
				return {
					...state,
					city: initialState.zip,
					street: initialState.street,
					streets: initialState.streets,
				};
			}

			return {
				...state,
				city: action.payload,
				street: initialState.street,
				streets: initialState.streets,
			};
		},
		setStreet: (state, action) => {
			if (action.payload.length === 0) {
				return {
					...state,
					city: initialState.zip,
					street: initialState.street,
					cities: initialState.cities,
					streets: initialState.streets,
				};
			}

			return {
				...state,
				street: action.payload,
			};
		},
		setCountry: (state, action) => ({
			...state,
			country: action.payload,
		}),
	},
	extraReducers: builder => {
		builder.addCase(setZip.fulfilled, (state, action) => ({
			...state,
			zip: action.payload,
		}));
		// Cities
		builder.addCase(fetchCities.pending, state => ({
			...state,
			cities: { ...initialState.cities, fetching: true },
			streets: initialState.streets,
			fetching: true,
		}));
		builder.addCase(fetchCities.fulfilled, (state, action) => {
			const newState = {
				...state,
				zip: action.meta.arg,
				cities: {
					items: action.payload,
					fetching: false,
					fetched: true,
					lastError: undefined,
				},
				streets: initialState.streets,
				fetching: false,
				fetched: true,
			};

			if (action.payload.length === 1) {
				newState.city = action.payload[0];
			}
			return newState;
		});
		builder.addCase(fetchCities.rejected, (state, action) => ({
			...state,
			cities: {
				...initialState.cities,
				lastError: action.payload as AddressError,
				fetched: true,
			},
			streets: initialState.streets,
			fetching: false,
			fetched: true,
		}));

		// Streets
		builder.addCase(fetchStreets.pending, state => ({
			...state,
			streets: { ...initialState.streets, fetching: true },
			fetching: true,
			fetched: false,
		}));
		builder.addCase(fetchStreets.fulfilled, (state, action) => ({
			...state,
			streets: {
				items: action.payload,
				fetching: false,
				fetched: true,
				lastError: undefined,
			},
			fetching: false,
			fetched: true,
		}));
		builder.addCase(fetchStreets.rejected, (state, action) => ({
			...state,
			streets: {
				...initialState.streets,
				lastError: action.payload as AddressError,
				fetched: true,
			},
			fetching: false,
			fetched: true,
		}));

		// UK Addresses
		builder.addCase(fetchUKAddresses.pending, state => ({
			...state,
			addresses: { ...initialState.addresses, fetching: true },
			fetching: true,
			fetched: false,
		}));
		builder.addCase(fetchUKAddresses.fulfilled, (state, action) => ({
			...state,
			addresses: {
				items: action.payload,
				fetching: false,
				fetched: true,
				lastError: undefined,
			},
			fetching: false,
			fetched: true,
		}));
		builder.addCase(fetchUKAddresses.rejected, (state, action) => ({
			...state,
			addresses: {
				...initialState.addresses,
				lastError: action.payload as AddressError,
				fetched: true,
			},
			fetching: false,
			fetched: true,
		}));
	},
});
