/* eslint-disable max-classes-per-file */
import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { PandaContext } from '../../../contexts/pandaContext';

import classes from './Dropdown.module.css';
import {
	DropdownContent,
	DropdownSize,
	DropdownPosition,
	HorizontalPosition,
	VerticalPosition,
} from './DropdownContent';

export type { DropdownSize, DropdownPosition, HorizontalPosition, VerticalPosition };

interface Props {
	position: DropdownPosition;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

/**
 * Simple Dropdown, basically a rectangle opening on top of everything.
 * You need to provide an explicit position.
 */
class InnerDropdown extends React.PureComponent<Props & { portalRoot: HTMLElement }> {
	private ref = React.createRef<DropdownContent>();

	private portalElement = document.createElement('div');

	public componentDidMount() {
		if (this.portalElement) {
			this.props.portalRoot.appendChild(this.portalElement);
		}
	}

	public componentWillUnmount() {
		if (this.portalElement) {
			this.props.portalRoot.removeChild(this.portalElement);
		}
	}

	public calculateSize(): DropdownSize | null {
		if (!this.ref.current) {
			return null;
		}

		return this.ref.current.calculateSize();
	}

	public render() {
		if (!this.portalElement) {
			return null;
		}

		return ReactDOM.createPortal(
			<CSSTransition
				in={this.props.open}
				timeout={150}
				classNames={{
					enter: classes.enter,
					enterActive: classes.enterActive,
					exit: classes.exit,
					exitActive: classes.exitActive,
				}}
			>
				<DropdownContent
					onClose={this.props.onClose}
					open={this.props.open}
					position={this.props.position}
					ref={this.ref}
				>
					{this.props.children}
				</DropdownContent>
			</CSSTransition>,
			this.portalElement
		);
	}
}

export class Dropdown extends React.PureComponent<Props> {
	private ref = React.createRef<InnerDropdown>();

	public calculateSize(): DropdownSize | null {
		if (!this.ref.current) {
			return null;
		}

		return this.ref.current.calculateSize();
	}

	public render() {
		return (
			<PandaContext.Consumer>
				{({ portalRoot }) => (
					<InnerDropdown
						ref={this.ref}
						onClose={this.props.onClose}
						open={this.props.open}
						position={this.props.position}
						portalRoot={portalRoot}
					>
						{this.props.children}
					</InnerDropdown>
				)}
			</PandaContext.Consumer>
		);
	}
}
