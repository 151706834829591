import { SipgateProduct } from '../redux/slices/signup';

export const pushEvent = (
	status: 'success' | 'failure',
	formId: string,
	product: SipgateProduct,
	errors?: Record<string, string>
) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'formSubmit',
			sipgateForm: {
				product,
				status,
				id: formId,
				errors,
			},
		});
	}
};
