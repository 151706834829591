import classNames from 'classnames';
import React from 'react';

interface ExternalProps {
	withoutMargin?: boolean;
}

type Props = Omit<React.HTMLAttributes<HTMLParagraphElement>, 'className' | 'role'> & ExternalProps;

const styles = {
	error: (withoutMargin: boolean) =>
		classNames(
			withoutMargin && ['m-0'],
			!withoutMargin && ['mt-4', 'mb-0', 'mx-2'],
			'text-xs/14',
			'text-neo-color-global-content-critical-moderate',
			'font-brand',
			'font-light',
			'cursor-default'
		),
};

const ErrorDescription = ({
	children,
	withoutMargin = false,
	...paragraphProps
}: Props): JSX.Element => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<p {...paragraphProps} className={styles.error(withoutMargin)} role="alert">
			{children}
		</p>
	);
};

export { ErrorDescription };
