import { ErrorBase } from '../../types';
import { UkAddressResponse } from '../../../api/types/UkAddressResponse';

const errors = [
	'GET_CITYS_ERROR',
	'GET_STREETS_ERROR',
	'GET_UK_ADDRESS_ERROR',
	'INVALID_ZIP_CODE_ERROR',
] as const;
type Errors = (typeof errors)[number];
export type AddressError = ErrorBase<Errors>;

export const AddressErrors = {
	GetCitiesError: (message: string): AddressError => ({
		name: 'GET_CITYS_ERROR',
		message,
	}),
	GetStreetsError: (message: string): AddressError => ({
		name: 'GET_STREETS_ERROR',
		message,
	}),
	GetUKAddressesError: (message: string): AddressError => ({
		name: 'GET_UK_ADDRESS_ERROR',
		message,
	}),
	InvalidZipCodeError: (message: string): AddressError => ({
		name: 'INVALID_ZIP_CODE_ERROR',
		message,
	}),
};

export interface AddressesState {
	zip: string;
	city: string;
	street: string;

	country: 'DE' | 'GB';
	cities: {
		items: string[];
		fetching: boolean;
		fetched: boolean;
		lastError?: AddressError;
	};
	streets: {
		items: string[];
		fetching: boolean;
		fetched: boolean;
		lastError?: AddressError;
	};
	addresses: {
		items: UkAddressResponse[];
		fetching: boolean;
		fetched: boolean;
		lastError?: AddressError;
	};
	fetching: boolean;
	fetched: boolean;
}
export type RootAddressState = {
	addresses: AddressesState;
};
