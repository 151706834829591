import classNames from 'classnames';
import React, { AriaAttributes } from 'react';

type Props = {
	icon: PandaIcons;
	className?: string;
} & AriaAttributes;

export type PandaIcons =
	| 'check-16'
	| 'dash-16'
	| 'exclamation_mark_circle-16'
	| 'sort_arrow-16'
	| 'sortable_arrows-16'
	| 'triangle_down-16';

export const PandaIcon = ({ icon, className, ...aria }: Props) => {
	return (
		<div
			className={classNames(className, `icon-${icon}`, 'panda-icon')}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...aria}
		/>
	);
};
