import React from 'react';
import { ManagedInput } from '@panda/ui';
import classnames from 'classnames';
import { ManagedForm } from '@web-apps/forms';
import { useFormikContext } from 'formik';
import classes from './Address.scss';
import useTranslations from '../../../hooks/translations';

const InternationalAddress = () => {
	const { translate } = useTranslations();

	const formik = useFormikContext<{
		company: string;
		address1: string;
		zip: string;
		city: string;
		address2: string;
	}>();

	return (
		<>
			<div className={classnames(classes.row, classes.width12)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'address1')}
					label={translate('SIGNUP_ADDRESS_INTERNATIONAL_1_LABEL')}
					placeholder=""
				/>
			</div>
			<div className={classnames(classes.row, classes.width12)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'address2')}
					label={translate('SIGNUP_ADDRESS_INTERNATIONAL_2_LABEL')}
					placeholder=""
				/>
			</div>
			<div className={classnames(classes.row)}>
				<div className={classnames(classes.width4)}>
					<ManagedInput
						managedField={ManagedForm.buildField(formik, 'zip')}
						label={translate('SIGNUP_ADDRESS_ZIP_LABEL')}
						placeholder=""
					/>
				</div>
				<div className={classnames(classes.width8)}>
					<ManagedInput
						managedField={ManagedForm.buildField(formik, 'city')}
						label={translate('SIGNUP_ADDRESS_CITY_LABEL')}
						placeholder=""
					/>
				</div>
			</div>
		</>
	);
};

export default InternationalAddress;
