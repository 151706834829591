import { useAppSelector } from './redux';
import { SipgateProduct } from '../redux/slices/signup';
import { getProduct } from '../redux/slices/signup/selectors';

interface SipgateProductHook {
	product: SipgateProduct;
}

export const useSipgateProduct = (): SipgateProductHook => {
	const product = useAppSelector(getProduct);

	return {
		product,
	};
};

export default useSipgateProduct;
