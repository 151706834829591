/* eslint-disable react/no-unused-prop-types */
import classNames from 'classnames';
import React, { useState } from 'react';
import { FocusTrap } from '@web-apps/focus-trap';

import { useAriaId } from '../../hooks/useAriaId';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';
import { VisualSearch } from '../../components/search/VisualSearch';
import { DropdownWithOpener } from './dropdowns/DropdownWithOpener';
import { SearchNoResult } from '../../components/search/SearchNoResult';

export interface Entity<T extends { id: string }> {
	/**
	 * Das ausgewählte Objekt.
	 */
	value: T;
	/**
	 * Der Name des Objekts welcher im Objektwechsler angezeigt wird.
	 */
	heading: string;
	/**
	 * Zusätzlich Information zum Objekt.
	 */
	subline?: string;
	/**
	 * Ist das Objekt aktuell ausgewählt?
	 */
	selected: boolean;
	/**
	 * Eine Grafik oder ein Avatar, die im Dropdown für den Objektwechsel angezeigt werden.
	 */
	graphic: React.ReactNode;
}

export interface EntitySwitcherProps<T extends { id: string }> {
	/** Callback welches beim Objektwechsel aufgerufen wird. */
	onChange: (entity: T) => void;

	/** Alle auswählbaren Objekte inklusive des aktuell ausgewählten. */
	entities: Entity<T>[];

	/**
	 * Ein Platzhalter welcher im Objektwechsler in der Suche
	 * angezeigt wird.
	 */
	searchPlaceholder: string;

	/**
	 * Ein Aria Label für die Suche im Objektwechsler.
	 */
	searchAriaLabel: string;
}

const styles = {
	switcher: classNames('relative'),
	dropdown: classNames(
		'overflow-hidden',
		'w-full',
		'bg-neo-color-global-surface-menu',
		'border',
		'border-neo-color-global-border-neutral-soft-default',
		'rounded-lg',
		'flex',
		'flex-col',
		'max-h-[22.25rem]',
		'shadow'
	),
	button: classNames(
		'flex',
		'items-center',
		'cursor-pointer',
		'w-full',
		'font-brand',
		'text-lg/24',
		'py-12',
		'pl-48',
		'pr-16',
		'font-bold',
		'ring-1',
		'ring-inset',
		'rounded-lg',
		'focus-visible:ring-focus-inset',
		'bg-neo-color-global-surface-menu',
		'ring-neo-color-global-border-neutral-moderate-default',
		'text-neo-color-global-content-neutral-intense',
		'hover:bg-neo-color-global-background-neutral-soft-hover',
		'hover:ring-neo-color-global-border-neutral-moderate-hover',
		'active:bg-neo-color-global-background-neutral-soft-active',
		'active:ring-neo-color-global-border-neutral-moderate-active',
		'duration-150',
		'ease-in-out',
		'transition'
	),
	headingContainer: classNames('grow', 'overflow-hidden', 'text-ellipsis'),
	caret: (open: boolean) =>
		classNames(
			'h-24',
			'w-24',
			'shrink-0',
			'ml-8',
			'transition',
			'duration-150',
			'ease-in-out',
			'flex',
			'items-center',
			'justify-center',
			'fill-current',
			open && ['scale-y-flip']
		),
	avatarContainer: classNames('w-40', 'h-40', 'flex', 'shrink-0', 'items-center', 'justify-center'),
	searchContainer: classNames('px-16', 'pt-16'),
	noResultsContainer: classNames('px-16'),
	list: classNames(
		'p-0',
		'm-0',
		'mt-8',
		'list-none',
		'overflow-auto',
		'shrink',
		'overscroll-contain'
	),
	listItem: (active: boolean) =>
		classNames(
			'flex',
			'items-center',
			'cursor-pointer',
			'gap-8',
			'px-16',
			'py-12',
			'font-brand',
			'text-base/20',
			'text-neo-color-global-content-neutral-intense',
			'hover:bg-neo-color-global-background-primary-soft-hover',
			'hover:text-neo-color-global-content-primary-intense',
			active && [
				'bg-neo-color-global-background-primary-soft-active',
				'text-neo-color-global-content-primary-intense',
			]
		),
	listEntryContent: classNames('grow', 'flex', 'flex-col', 'overflow-hidden'),
	heading: classNames('font-bold', 'truncate'),
	subline: classNames('font-light', 'truncate'),
};

export const EntitySwitcher = <T extends { id: string }>({
	entities,
	onChange,
	searchPlaceholder,
	searchAriaLabel,
}: EntitySwitcherProps<T>) => {
	const [open, setOpen] = useState(false);
	const [input, setInput] = useState('');

	const entity = entities.find(e => e.selected);

	const [activeElement, setActiveElement] = useState<string | null>(
		entity ? entity.value.id : null
	);

	const dropdownId = useAriaId('dropdown');
	const suggestionsId = useAriaId('suggestions');
	const switcherId = useAriaId('EntitySwitcher');

	const getListEntryId = (element: T) => `${switcherId}-${element.id}`;

	const filteredEntities = entities.filter(
		e =>
			e.heading.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
			e.subline?.toLocaleLowerCase().includes(input.toLocaleLowerCase())
	);

	const currentlyActiveElement =
		activeElement === null
			? null
			: filteredEntities.find(e => e.value.id === activeElement)?.value ??
				filteredEntities[0]?.value ??
				null;

	const scrollElementIntoView = (element: T) => {
		document.getElementById(getListEntryId(element))?.scrollIntoView({ block: 'nearest' });
	};

	const changeActiveElement = (element: T | null) => {
		setActiveElement(element ? element.id : null);
	};

	const closeDropdown = () => {
		setOpen(false);
	};

	const changeEntity = (value: T) => {
		onChange(value);

		/*
		 * We need to slow down closing the dropdown so the focus does not immediately
		 * shift to the button and triggers another event, opening the dropdown again.
		 */
		window.setTimeout(() => closeDropdown());
	};

	const selectNextEntry = () => {
		if (!currentlyActiveElement) {
			changeActiveElement(filteredEntities[0]?.value ?? null);
			return;
		}

		const currentlyActiveIndex = filteredEntities.findIndex(
			e => e.value.id === currentlyActiveElement.id
		);

		const nextEntity = filteredEntities[currentlyActiveIndex + 1];

		if (nextEntity) {
			changeActiveElement(nextEntity.value);
			scrollElementIntoView(nextEntity.value);
		}
	};

	const selectPreviousEntry = () => {
		if (!currentlyActiveElement) {
			changeActiveElement(filteredEntities[filteredEntities.length - 1]?.value ?? null);
			return;
		}

		const currentlyActiveIndex = filteredEntities.findIndex(
			e => e.value.id === currentlyActiveElement.id
		);

		const previousEntity = filteredEntities[currentlyActiveIndex - 1];

		if (previousEntity) {
			changeActiveElement(previousEntity.value);
			scrollElementIntoView(previousEntity.value);
		}
	};

	const selectFirstEntry = () => {
		const firstEntity = filteredEntities[0];

		if (firstEntity) {
			changeActiveElement(firstEntity.value);
			window.setTimeout(() => scrollElementIntoView(firstEntity.value));
		}
	};

	const selectLastEntry = () => {
		const lastEntity = filteredEntities[filteredEntities.length - 1];

		if (lastEntity) {
			changeActiveElement(lastEntity.value);
			window.setTimeout(() => scrollElementIntoView(lastEntity.value));
		}
	};

	const onInputKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.stopPropagation();
			if (currentlyActiveElement) {
				changeEntity(currentlyActiveElement);
			}
		}

		if (e.key === 'ArrowUp') {
			e.stopPropagation();
			selectPreviousEntry();
		}

		if (e.key === 'ArrowDown') {
			e.stopPropagation();
			selectNextEntry();
		}

		if (e.key === 'Tab') {
			e.stopPropagation();
			closeDropdown();
		}
	};

	return (
		<div className={styles.switcher}>
			<DropdownWithOpener
				onOpen={(where?: 'first' | 'last') => {
					setOpen(true);

					if (where === 'first') {
						selectFirstEntry();
					} else if (where === 'last') {
						selectLastEntry();
					}
				}}
				onClose={() => setOpen(false)}
				open={open}
				horizontalOpeningPosition="Center"
				opener={
					<button type="button" aria-controls={dropdownId} className={styles.button}>
						<span className={styles.headingContainer}>{entity ? entity.heading : ''}</span>
						<div className={styles.caret(open)}>
							<PandaIcon icon="triangle_down-16" />
						</div>
					</button>
				}
			>
				<FocusTrap allowOutsideClick active={open}>
					<div
						/*
						 * Because we used example 2 in
						 * https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html
						 * we assume the lint plugin is wrong
						 */
						role="combobox" // eslint-disable-line jsx-a11y/role-has-required-aria-props
						aria-expanded="true"
						aria-owns={suggestionsId}
						id={dropdownId}
						className={styles.dropdown}
					>
						<div className={styles.searchContainer}>
							<VisualSearch
								onChange={setInput}
								value={input}
								resultCount={filteredEntities.length}
								placeholder={searchPlaceholder}
								ariaLabel={searchAriaLabel}
								onKeyDown={onInputKeyDown}
								aria-activedescendant={
									currentlyActiveElement ? getListEntryId(currentlyActiveElement) : undefined
								}
								aria-autocomplete="list"
								aria-controls={suggestionsId}
								landmark={false}
								disableAutocomplete
							/>
						</div>

						{/* eslint-disable-next-line jsx-a11y/aria-activedescendant-has-tabindex */}
						<ul
							id={suggestionsId}
							className={styles.list}
							role="listbox"
							onMouseLeave={() => changeActiveElement(null)}
						>
							{filteredEntities.map(entry => (
								// eslint-disable-next-line jsx-a11y/click-events-have-key-events
								<li
									id={getListEntryId(entry.value)}
									key={getListEntryId(entry.value)}
									onClick={() => changeEntity(entry.value)}
									role="option"
									aria-selected={entry.value.id === entity?.value.id}
									className={styles.listItem(currentlyActiveElement?.id === entry.value.id)}
									onMouseOver={() => changeActiveElement(entry.value)}
									onFocus={() => changeActiveElement(entry.value)}
								>
									<div className={styles.avatarContainer}>{entry.graphic}</div>

									<div className={styles.listEntryContent}>
										<span className={styles.heading}>{entry.heading}</span>
										{entry.subline ? <span className={styles.subline}>{entry.subline}</span> : null}
									</div>
								</li>
							))}
						</ul>

						{filteredEntities.length === 0 ? (
							<div className={styles.noResultsContainer}>
								<SearchNoResult short searchTerm={input} />
							</div>
						) : null}
					</div>
				</FocusTrap>
			</DropdownWithOpener>
		</div>
	);
};

// Dummy Component for documentation only
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EntityDocs = <T extends { id: string }>(props: Entity<T>) => <></>;
