import React from 'react';
import classnames from 'classnames';
import { DetailViewHeader } from './DetailViewHeader';
import { HeadingBoundary } from '../../components/heading/HeadingBoundary';

export type Props = {
	/**
	 * Überschrift der `DetailView`
	 */
	heading: string;
	/**
	 * Ein Array von `ContextMenu`-Items
	 */
	contextMenu?: { label: string; onClick: () => void; critical?: boolean }[];
	/**
	 * Als Children erwartet die View eine oder mehrere [`Sections`](../?path=/docs/views-detailview-overview--docs).
	 * Ggf. kann man auch eine Komponente übergeben, die mehrere `Sections` wrapped.
	 * Das kann der Fall sein, wenn man als `ActionElement` ein [`Dropdown`](../?path=/docs/components-dropdown-overview--docs) benutzt.
	 */
	children: React.ReactNode;
	/**
	 * Übergib `StateIcon` Komponenten in den richtigen Größen.
	 * Siehe [`StateIcon` Dokumentation](../?path=/docs/components-stateicon-overview--docs)
	 */
	icons?: {
		small: React.ReactNode;
		medium: React.ReactNode;
	};
};

const styles = {
	sections: classnames('flex', 'flex-col', 'gap-32', 'pt-32'),
};

export const DetailView = ({ heading, contextMenu, children, icons }: Props) => {
	return (
		<div>
			<DetailViewHeader heading={heading} contextMenu={contextMenu} icons={icons} />
			<HeadingBoundary>
				<div className={styles.sections}>{children}</div>
			</HeadingBoundary>
		</div>
	);
};
