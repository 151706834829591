import { RootAddressState } from './types';

export const getZip = (state: RootAddressState) => state.addresses.zip;
export const getCity = (state: RootAddressState) => state.addresses.city;
export const getStreet = (state: RootAddressState) => state.addresses.street;
export const getCities = (state: RootAddressState) => state.addresses.cities.items;
export const getCitiesLastError = (state: RootAddressState) => state.addresses.cities.lastError;
export const hasCitiesLastError = (state: RootAddressState) => !!state.addresses.cities.lastError;

export const hasCitiesFetched = (state: RootAddressState) => state.addresses.cities.fetched;
export const isCitiesFetching = (state: RootAddressState) => state.addresses.cities.fetching;

export const getCountry = (state: RootAddressState) => state.addresses.country;

export const getStreets = (state: RootAddressState) => state.addresses.streets.items;
export const hasStreetsLastError = (state: RootAddressState) => !!state.addresses.streets.lastError;

export const hasStreetsFetched = (state: RootAddressState) => state.addresses.streets.fetched;
export const isStreetsFetching = (state: RootAddressState) => state.addresses.streets.fetching;

export const getStreetsLastError = (state: RootAddressState) => state.addresses.streets.lastError;

export const isFetching = (state: RootAddressState) => state.addresses.fetching;

export const isFetched = (state: RootAddressState) => state.addresses.fetched;

export const getAddresses = (state: RootAddressState) => state.addresses.addresses.items;
export const hasAddressesLastError = (state: RootAddressState) =>
	!!state.addresses.addresses.lastError;
