import { ErrorBase } from './types';
import { isApiException } from './ApiException';

export const GENERAL_SIGNUP_ERROR = 3500;
export const EMAIL_ALREADY_EXISTS_ERROR = 3001;
export const ALREADY_ACTIVATED_ERROR = 3002;

export const SIGNUP_NOT_FOUND_ERROR = 3404;

export const apiErrorCodes = [
	GENERAL_SIGNUP_ERROR,
	EMAIL_ALREADY_EXISTS_ERROR,
	ALREADY_ACTIVATED_ERROR,
	SIGNUP_NOT_FOUND_ERROR,
] as const;
type ApiErrorCodes = (typeof apiErrorCodes)[number];
export type SignupApiError = ErrorBase<ApiErrorCodes>;

export const isSignupApiError = (error: unknown): error is SignupApiError => {
	return isApiException(error) && apiErrorCodes.includes((error as SignupApiError).apiErrorCode);
};

export const SignupApiErrors = {
	GeneralError: (cause?: unknown): SignupApiError => ({
		apiErrorCode: GENERAL_SIGNUP_ERROR,
		name: 'GENERAL_SIGNUP_ERROR',
		message: 'General signup error',
		cause,
	}),
	EmailAlreadyExistsError: (message: string, cause?: unknown): SignupApiError => ({
		apiErrorCode: EMAIL_ALREADY_EXISTS_ERROR,
		name: 'EMAIL_ALREADY_EXISTS_ERROR',
		message,
		cause,
	}),
	NotFoundError: (message: string, cause?: unknown): SignupApiError => ({
		apiErrorCode: SIGNUP_NOT_FOUND_ERROR,
		name: 'SIGNUP_NOT_FOUND_ERROR',
		message,
		cause,
	}),
};
