import React, { useEffect } from 'react';

export const useOutsideAlerter = (ref: React.RefObject<HTMLDivElement>, f: () => void) => {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			if (event.target) {
				if (ref.current && !ref.current.contains(event.target as Node)) {
					f();
				}
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, f]);
};
