import { sprintf } from 'sprintf-js';
import { Translate, Translations } from './types';

const isProduction = () => (process.env.NODE_ENV ? process.env.NODE_ENV === 'production' : true);

export const translate =
	(translations: Translations): Translate =>
	(lk, ...replacements) => {
		const translated = translations[lk] || '';

		if (translated || isProduction()) {
			try {
				return replacements === null ? translated : sprintf(translated, ...replacements);
			} catch (e) {
				return '';
			}
		}

		return `### ${lk} ###`;
	};

export const useTranslations = (translations: Translations) => translate(translations);

export * from './types';
