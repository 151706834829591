import { createSlice } from '@reduxjs/toolkit';
import { fetchTranslations, TranslationState } from './index';
import { getLanguageByDomain } from '../../../utils';

export const initialState: TranslationState = {
	items: {},
	fetching: false,
	fetched: false,
	language: getLanguageByDomain(),
};

export const translationSlice = createSlice({
	name: 'translations',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchTranslations.pending, state => ({
			...state,
			fetching: true,
		}));
		builder.addCase(fetchTranslations.fulfilled, (state, action) => ({
			...state,
			items: action.payload,
			language: action.meta.arg,
			fetching: false,
			fetched: true,
		}));
		builder.addCase(fetchTranslations.rejected, (state, action) => ({
			...state,
			items: action.payload?.items || {},
			fetching: false,
			fetched: true,
			language: action.payload?.locale || state.language,
			error:
				action.payload !== undefined
					? {
							name: action.payload?.name,
							message: action.payload?.message,
						}
					: undefined,
		}));
	},
});
