import { HeadingBoundary, Headline } from '@panda/ui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { usePusherContext } from '../../components/PusherContext';
import Spinner from '../../components/spinner/Spinner';
import classes from './Status.scss';
import { useTranslations } from '../../hooks/translations';
import { useLoadingAnimation } from '../../hooks/LoadingAnimation';
import { MarkdownTranslate } from '../../utils/markdown';

export default function Status() {
	const { translate } = useTranslations();
	const { identifier } = useParams<{ identifier: string }>();

	const pusher = usePusherContext();
	const loadingAnimation = useLoadingAnimation();
	loadingAnimation.hide();

	useEffect(() => {
		if (identifier) {
			pusher.client.then(client => {
				return client.listen<{ url: string }>(
					identifier,
					'login_possible',
					(event: { url: string }) => {
						window.location.href = event.url;
					}
				);
			});
		}
	}, [identifier, pusher.client]);

	return (
		<div className={classes.activate}>
			<div className={classes.content}>
				<HeadingBoundary>
					<Headline>
						<MarkdownTranslate
							translate={translate}
							translationKey="SIGNUP_ACTIVATING_HEADLINE"
							placeholder={[]}
						/>
					</Headline>
				</HeadingBoundary>
				<span className={classes.message}>
					<MarkdownTranslate
						translate={translate}
						translationKey="SIGNUP_ACTIVATING_MESSAGE"
						placeholder={[]}
					/>
				</span>
				<div className={classes.spinner}>
					<Spinner />
				</div>
			</div>
		</div>
	);
}
