import React, { useEffect } from 'react';
import { ManagedInput, ManagedSelect, Option } from '@panda/ui';
import classnames from 'classnames';
import { ManagedForm } from '@web-apps/forms';
import { useFormikContext } from 'formik';
import classes from './Address.scss';

import useAddressData from '../../../hooks/AddressData';
import useTranslations from '../../../hooks/translations';

const GermanAddress = () => {
	const { translate } = useTranslations();
	const {
		zip,
		city,
		setZip,
		setCity,
		cities,
		hasCitiesError,
		citiesError,
		streets,
		hasStreetsError,
	} = useAddressData();

	const formik = useFormikContext<{
		company: string;
		street: string;
		zip: string;
		city: string;
		number: string;
	}>();

	useEffect(() => {
		setZip(formik.values.zip, 'DE');
	}, [formik, zip, setZip]);

	useEffect(() => {
		setCity(formik.values.city);
	}, [setCity, city, formik]);

	useEffect(() => {
		if (hasCitiesError && !formik.getFieldMeta('zip').error) {
			formik.setFieldError('zip', translate('SIGNUP_ERROR_INVALID_ZIP'));
		} else if (!hasCitiesError && formik.getFieldMeta('zip').error) {
			formik.setFieldError('zip', undefined);
		}
	}, [translate, formik, hasCitiesError]);

	useEffect(() => {
		if (cities.length === 1 && formik.values.city !== cities[0]) {
			formik.setFieldValue('city', cities[0]);
		}
	}, [formik, cities]);

	useEffect(() => {
		if (formik.touched.zip && formik.values.zip !== formik.values.zip.trim()) {
			formik.setFieldValue('zip', formik.values.zip.trim());
		}
	}, [formik]);

	return (
		<>
			<div className={classnames(classes.row)}>
				<div className={classnames(classes.width4)}>
					<ManagedInput
						managedField={ManagedForm.buildField(formik, 'zip')}
						label={translate('SIGNUP_ADDRESS_ZIP_LABEL')}
						placeholder=""
					/>
				</div>
				<div className={classnames(classes.width8)}>
					<ManagedSelect
						managedField={ManagedForm.buildField(formik, 'city')}
						title={translate('SIGNUP_ADDRESS_CITY_LABEL')}
						placeholder={translate('SIGNUP_ADDRESS_CITY_PLACEHOLDER')}
						disabled={cities.length === 0 || !!citiesError}
					>
						{cities.map(item => (
							<Option value={item} key={item}>
								{item}
							</Option>
						))}
					</ManagedSelect>
				</div>
			</div>
			<div className={classnames(classes.row)}>
				<div className={classnames(classes.width9)}>
					<ManagedSelect
						managedField={ManagedForm.buildField(formik, 'street')}
						title={translate('SIGNUP_ADDRESS_STREET_LABEL')}
						placeholder={translate('SIGNUP_ADDRESS_STREET_PLACEHOLDER')}
						disabled={streets.length === 0 || hasStreetsError}
					>
						{streets.map(item => (
							<Option value={item} key={item}>
								{item}
							</Option>
						))}
					</ManagedSelect>
				</div>
				<div className={classnames(classes.width3)}>
					<ManagedInput
						managedField={ManagedForm.buildField(formik, 'number')}
						label={translate('SIGNUP_ADDRESS_NUMBER_LABEL')}
						placeholder=""
						disabled={
							streets.length === 0 || hasStreetsError || cities.length === 0 || !!citiesError
						}
					/>
				</div>
			</div>
		</>
	);
};

export default GermanAddress;
