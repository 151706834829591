import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { usePandaContext } from '../../contexts/pandaContext';
import { ListNavViewItem } from './ListNavViewItem';
import { ListNavViewColumn } from './ListNavViewColumn';
import { ColumnMeta } from './types';
import { SearchNoResult } from '../../components/search/SearchNoResult';
import { useResize } from '../../hooks/useResize';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';

import classes from './ListNavAsTable.module.css';

type ColumnSize = 'xsmall' | 'small' | 'medium' | 'large';

export type TableProps<T, Columns extends Record<string, ColumnMeta<T>>> = {
	sortedField: keyof Columns;
	setSortedField: React.Dispatch<React.SetStateAction<keyof Columns>>;
	sortDirection: 'ascending' | 'descending';
	setSortDirection: React.Dispatch<React.SetStateAction<'ascending' | 'descending'>>;
	items: T[];
	children: (item: T) => JSX.Element;
	columns: Columns;
	searchTerm: string;
};

const sizeMap = {
	xsmall: { value: 112, style: classes.maxWidthXs },
	small: { value: 192, style: classes.maxWidthSm },
	medium: { value: 352, style: classes.maxWidthMd },
	large: { value: 432, style: classes.maxWidthLg },
};

const styles = {
	table: classnames(classes.borderSpacing, 'w-full', 'font-brand', 'text-lg/24'),
	columnHeader: classnames('h-32', 'px-16', 'py-0', 'first:pl-24', 'align-bottom', 'text-left'),
	columnSorter: (size: ColumnSize = 'medium') =>
		classnames(
			sizeMap[size].style,
			'appearance-none',
			'flex',
			'items-end',
			'group',
			'gap-x-4',
			'p-0',
			'm-0',
			'border-0',
			'bg-neo-color-global-background-static-transparent',
			'font-meta',
			'font-light',
			'text-base/16',
			'text-neo-color-global-content-neutral-moderate',
			'outline-none',
			'hover:text-neo-color-global-content-neutral-intense',
			'focus-visible:ring-focus',
			'focus-visible:relative',
			'focus-visible:z-10',
			'active:text-neo-color-global-content-neutral-moderate',
			'cursor-pointer',
			'duration-150',
			'ease-in-out',
			'transition-colors',
			'whitespace-nowrap',
			'rounded-sm',
			'pointer-coarse:py-16',
			'pointer-coarse:-mb-16'
		),
	columnLabel: (size: ColumnSize = 'medium') =>
		classnames(
			sizeMap[size].style,
			'group',
			'p-0',
			'm-0',
			'font-meta',
			'font-light',
			'text-base/16',
			'text-neo-color-global-content-neutral-moderate',
			'cursor-default',
			'align-bottom',
			'whitespace-nowrap'
		),
	currentSortOrderIcon: (showIcon: boolean, sortDirection: 'ascending' | 'descending') =>
		classnames(
			'h-16',
			'mr-8',
			'transform',
			'group-hover:bg-neo-color-global-content-neutral-intense',
			'group-active:bg-neo-color-global-content-neutral-moderate',
			'duration-150',
			'ease-in-out',
			'transition',
			showIcon ? 'bg-neo-color-global-content-neutral-intense' : 'rotate-0 hidden',
			sortDirection === 'descending' ? 'rotate-180' : 'group-hover:rotate-0'
		),
	possibleSortOrderIcon: (showSortableIcon: boolean) =>
		classnames(
			'transform',
			'group-hover:bg-neo-color-global-content-neutral-intense',
			'group-active:bg-neo-color-global-content-neutral-moderate',
			'duration-150',
			'ease-in-out',
			'transition',
			showSortableIcon ? 'bg-neo-color-global-content-neutral-moderate' : 'hidden'
		),
	row: classnames('relative', 'w-full', 'h-80', 'group', 'cursor-pointer'),
	truncate: (size: ColumnSize = 'medium') => classnames(sizeMap[size].style, 'truncate'),
	chevronCell: classnames('p-0', 'w-full', 'select-none'),
	chevronContainer: classnames('flex', 'justify-end'),
	chevronIcon: classnames(
		'bg-neo-color-global-content-neutral-intense',
		'group-hover:bg-neo-color-global-content-primary-intense',
		'group-active:bg-neo-color-global-content-primary-intense',
		'duration-150',
		'ease-in-out',
		'transition-colors',
		'pointer-events-none'
	),
	cell: classnames(
		'px-16',
		'bg-neo-color-web-app-component-card-background-default',
		'text-neo-color-global-content-neutral-intense',
		'text-left',
		'text-lg/24',
		'border-solid',
		'border-neo-color-global-border-neutral-soft-default',
		'border-b-2',
		'border-t-2',
		'first:border-l-2',
		'last:border-r-2',
		'first:pl-24',
		'last:pr-24',
		'first:rounded-l-lg',
		'last:rounded-r-lg',
		'last:w-24',
		'group-hover:bg-neo-color-global-background-primary-soft-hover',
		'group-hover:text-neo-color-global-content-primary-intense',
		'group-hover:border-neo-color-global-border-primary-moderate-hover',
		'group-hover:border-b-2',
		'group-hover:border-t-2',
		'group-hover:first:pl-24',
		'group-hover:last:pr-24',
		'group-hover:first:border-l-2',
		'group-hover:last:border-r-2',
		'group-active:text-neo-color-global-content-primary-intense',
		'group-active:border-neo-color-global-border-primary-moderate-active',
		'group-active:bg-neo-color-global-background-primary-soft-active',
		'group-active:border-b-2',
		'group-active:border-t-2',
		'group-active:first:border-l-2',
		'group-active:last:border-r-2',
		'group-active:first:pl-24',
		'group-active:last:pr-24',
		'group-focus-within:border-b-3',
		'group-focus-within:border-t-3',
		'group-focus-within:first:border-l-3',
		'group-focus-within:last:border-r-3',
		'group-focus-within:first:pl-[23px]',
		'group-focus-within:last:pr-[23px]',
		'group-focus-within:border-neo-color-global-border-static-focus',
		'transition',
		'duration-150',
		'ease-in-out'
	),
	contentCell: classnames('w-auto', 'whitespace-nowrap'),
	link: classnames(
		'no-underline',
		'focus:outline-none',
		'font-bold',
		'text-neo-color-global-content-neutral-intense',
		'group-hover:text-neo-color-global-content-primary-intense',
		'group-active:text-neo-color-global-content-primary-intense',
		'group-focus-visible:text-neo-color-global-content-neutral-intense',
		'flex',
		'items-center',
		'gap-16'
	),
	graphic: classnames('w-48', 'h-48', 'flex', 'items-center', 'justify-center'),
	metaContainer: (size: ColumnSize = 'medium') =>
		classnames(sizeMap[size].style, 'flex', 'items-center'),
	metaContent: classnames('truncate'),
	metaCount: classnames(
		'align-baseline',
		'font-meta',
		'font-light',
		'text-base/24',
		'text-neo-color-global-content-neutral-moderate',
		'whitespace-nowrap',
		'ml-8'
	),
	noResultsContainer: classnames('p-24', 'max-w-[35rem]'),
	dash: classnames('text-neo-color-global-content-neutral-moderate'),
};

function calculateColumnVisibility<Columns extends Record<string, ColumnMeta<never>>>(
	containerWidth: number,
	columns: Columns
) {
	let totalWidth = 0;
	const visibility = {} as Record<keyof Columns | symbol, boolean>;

	const entries = Object.entries(columns)
		.filter(([_, column]) => !column.hidden)
		.map(([name, column]) => ({
			name: name as keyof Columns,
			size: sizeMap[column.size || 'medium'].value,
		}));

	for (const { name, size } of entries) {
		totalWidth += size;

		visibility[name] = totalWidth <= containerWidth;
	}

	return visibility;
}

export const ListNavAsTable = <T, Columns extends Record<string, ColumnMeta<T>>>({
	items,
	children: mapItemsToChildren,
	sortedField,
	setSortedField,
	sortDirection,
	setSortDirection,
	columns,
	searchTerm,
}: TableProps<T, Columns>) => {
	const history = useHistory();
	const tableRef = React.useRef<HTMLTableElement>(null);
	const [columnsVisibility, setColumnsVisibility] = React.useState(
		calculateColumnVisibility(1920, columns)
	);
	const { languageKeys } = usePandaContext();

	useResize(
		useCallback(() => {
			if (!tableRef.current || !tableRef.current.parentElement) {
				return;
			}

			// 66 is the size of the chevron and 8 is the additional padding of the first column
			const containerWidth = tableRef.current.parentElement.offsetWidth - 66 - 8;

			setColumnsVisibility(calculateColumnVisibility(containerWidth, columns));
		}, [columns, setColumnsVisibility])
	);

	const handleSortButtonClick = (key: string) => () => {
		if (key === sortedField) {
			setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
			return;
		}

		setSortedField(key);
		setSortDirection('ascending');
	};

	const getFormattedContent = (columnKey: string, content: React.ReactNode) => {
		if (columns[columnKey].clip === 'meta' && Array.isArray(content) && content.length > 1) {
			return (
				<div className={styles.metaContainer(columns[columnKey].size)}>
					<span className={styles.metaContent}>
						{content.length === 1 ? (
							content[0]
						) : (
							<>
								{content[0]}, {content[1]}
							</>
						)}
					</span>
					<span
						title={`${content.length} ${languageKeys.PANDA_LISTNAV_TOTAL}`}
						className={styles.metaCount}
					>
						{`[${content.length}]`}
					</span>
				</div>
			);
		}

		if (content === null || content === '' || (Array.isArray(content) && content.length === 0)) {
			return (
				<div className={styles.truncate(columns[columnKey].size)}>
					<span className={styles.dash} aria-hidden>
						–
					</span>
				</div>
			);
		}

		return <div className={styles.truncate(columns[columnKey].size)}>{content}</div>;
	};

	const renderTableCell =
		(href: string, graphic: { large: React.ReactNode; xlarge: React.ReactNode } | undefined) =>
		(column: React.ReactComponentElement<typeof ListNavViewColumn>) => {
			const isFirstColumn = column.props.columnKey === Object.keys(columns)[0];

			if (isFirstColumn) {
				return (
					<td className={classnames(styles.cell, styles.contentCell)}>
						<Link className={styles.link} to={href}>
							{graphic ? <div className={styles.graphic}>{graphic.xlarge}</div> : null}
							<div className={styles.truncate(columns[column.props.columnKey].size)}>
								{column.props.children}
							</div>
						</Link>
					</td>
				);
			}

			if (columnsVisibility[column.props.columnKey]) {
				return (
					<td className={classnames(styles.cell, styles.contentCell)}>
						{getFormattedContent(column.props.columnKey, column.props.children)}
					</td>
				);
			}

			return null;
		};

	const renderTableRow = (item: React.ReactComponentElement<typeof ListNavViewItem>) => {
		return (
			<tr
				key={item.key}
				className={styles.row}
				onClickCapture={e => {
					e.preventDefault();
				}}
				onMouseUpCapture={e => {
					const leftClick = e.button === 0;
					const middleClick = e.button === 1;

					if (leftClick || middleClick) {
						const newTab = middleClick || e.ctrlKey || e.metaKey;

						if (newTab) {
							window.open(item.props.href, '_blank');
						} else {
							history.push(item.props.href);
						}

						e.stopPropagation();
					}
				}}
				onKeyDownCapture={e => {
					if (e.key === 'Enter') {
						const newTab = e.ctrlKey || e.metaKey;

						if (newTab) {
							window.open(item.props.href, '_blank');
						} else {
							history.push(item.props.href);
						}

						e.stopPropagation();
					}
				}}
			>
				{React.Children.map(
					item.props.children,
					renderTableCell(item.props.href, item.props.graphic)
				)}

				<td aria-hidden className={classnames(styles.cell, styles.chevronCell)}>
					<div className={styles.chevronContainer}>
						<SetIcon icon="next" size="24" className={styles.chevronIcon} />
					</div>
				</td>
			</tr>
		);
	};

	return (
		<>
			<table ref={tableRef} className={styles.table}>
				<thead>
					<tr>
						{Object.entries(columns).map(([key, columnDefinition]) => {
							const ariaSort = key === sortedField ? sortDirection : 'none';
							const showSortIcon = key === sortedField;
							const sortFunction = columnDefinition.sort;
							const showSortableIcon = key !== sortedField && !!sortFunction;

							if (columnsVisibility[key]) {
								return (
									<th
										scope="col"
										key={key}
										className={styles.columnHeader}
										role="columnheader"
										aria-label={columnDefinition.text}
										aria-sort={ariaSort}
									>
										{sortFunction ? (
											<button
												type="button"
												title={`${languageKeys.PANDA_LISTNAV_SORT_BY} ${columnDefinition.text}`}
												className={styles.columnSorter(columns[key].size)}
												onClick={handleSortButtonClick(key)}
												aria-label={`${languageKeys.PANDA_LISTNAV_SORT_BY} ${columnDefinition.text}`}
											>
												<div>{columnDefinition.text}</div>
												<PandaIcon
													icon="sort_arrow-16"
													className={styles.currentSortOrderIcon(showSortIcon, sortDirection)}
												/>
												<PandaIcon
													icon="sortable_arrows-16"
													className={styles.possibleSortOrderIcon(showSortableIcon)}
												/>
											</button>
										) : (
											<div className={styles.columnLabel(columns[key].size)}>
												{columnDefinition.text}
											</div>
										)}
									</th>
								);
							}
							return null;
						})}
						<th className={styles.chevronCell} scope="col" role="columnheader" aria-hidden />
					</tr>
				</thead>
				{items.length ? (
					<tbody>{items.map(item => renderTableRow(mapItemsToChildren(item)))}</tbody>
				) : null}
			</table>
			{!items.length ? (
				<div className={styles.noResultsContainer}>
					<SearchNoResult searchTerm={searchTerm} />
				</div>
			) : null}
		</>
	);
};
