import { SipgateDomain, SipgateProduct } from '../redux/slices/signup';

export const isUKHostname = (hostname: string) => hostname.includes('co.uk');

export const getLanguageByDomain = () =>
	isUKHostname(window.location.hostname) ? 'en_GB' : 'de_DE';

export const isUKPostcode = (postcode: string) =>
	/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
		postcode
	);

const isTrunking = (hostname: string) => hostname.includes('trunking');

export const getIdentifier = (location: Location) => {
	const result = location.pathname.match(
		'[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}'
	);
	if (result && result.length > 0) {
		return result[0];
	}
};
export const getProduct = (location: Location) => {
	if (isTrunking(location.hostname)) {
		return 'trunking';
	}

	if (location.pathname.includes('affiliate') || location.pathname.includes('partnerprogramm')) {
		return 'affiliate';
	}

	return 'team';
};

export const getTacIds = (product: SipgateProduct, domain: SipgateDomain) => {
	if (product === 'trunking' && domain === 'sipgate.de') {
		return [105, 108, 96];
	}

	if (product === 'trunking' && domain === 'sipgate.co.uk') {
		return [83, 98, 127];
	}

	if (product === 'team' && domain === 'sipgate.co.uk') {
		return [692, 98, 127];
	}

	if (product === 'affiliate' && domain === 'sipgate.de') {
		return [817, 782, 380];
	}

	if (product === 'affiliate' && domain === 'sipgate.co.uk') {
		return [692, 98, 127, 496];
	}

	// sipgate.de sipgate
	return [817, 782];
};
