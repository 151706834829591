import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { clearCookie, setCookie } from '../../utils/cookies';
import { useAppSelector } from '../../hooks/redux';
import { getProduct } from '../../redux/slices/signup/selectors';

const Partner = () => {
	const { partnerId } = useParams<{ partnerId: string }>();
	const product = useAppSelector(getProduct);
	useEffect(() => {
		if (partnerId) {
			clearCookie('partnerId');
			setCookie('partnerId', partnerId, 365);
		}
		if (product) {
			if (product === 'team') {
				if (document.location.href.includes('co.uk')) {
					document.location.href = `https://www.sipgate.co.uk/prices-tariffs${document.location.search}`;
				} else {
					document.location.href = `https://www.sipgate.de/preise${document.location.search}`;
				}
			} else if (product === 'trunking') {
				if (document.location.href.includes('co.uk')) {
					document.location.href = `https://www.sipgatetrunking.co.uk/${document.location.search}`;
				} else {
					document.location.href = `https://www.sipgatetrunking.de/tarife${document.location.search}`;
				}
			} else {
				document.location.href = `https://www.sipgate.de/${document.location.search}`;
			}
		}
	}, [partnerId, product]);

	return <></>;
};

export default Partner;
