import classnames from 'classnames';
import React from 'react';
import { ContextMenu } from '../../components/contextMenu/ContextMenu';
import { MenuItem } from '../../components/contextMenu/MenuItem';
import { Headline } from '../../components/heading/Headline';

type GeneralProps = {
	heading: string;
	icons?: { small: React.ReactNode; medium: React.ReactNode };
};

type Props = GeneralProps & {
	contextMenu?: { label: string; onClick: () => void; critical?: boolean }[];
};

const styles = {
	icon: classnames(
		'place-self-start',
		'sm:place-self-center',
		'pt-4',
		'sm:pt-0',
		'pr-4',
		'sm:pr-8'
	),
	iconSmall: classnames('inline', 'sm:hidden', 'mr-4', 'sm:mr-8'),
	iconMedium: classnames('inline', 'hidden', 'sm:inline', 'mr-4', 'sm:mr-8'),
	header: classnames(
		'cursor-default',
		'flex',
		'items-center',
		'border-b',
		'border-neo-color-global-border-neutral-soft-default',
		'gap-8',
		'pb-8',
		'md:pb-16',
		'lg:pb-24'
	),
	heading: classnames(
		'font-bold',
		'grow',
		'flex',
		'font-brand',
		'hyphens',
		'm-0',
		'text-neo-color-global-content-neutral-intense',
		'text-3xl/32',
		'sm:text-4xl/36',
		'md:text-5xl/40',
		'lg:text-6xl/48'
	),
	headingWrap: classnames('line-clamp-2', 'sm:line-clamp-1', 'grow'),
	container: classnames('flex', 'flex-nowrap', 'items-start', 'grow'),
};

export const DetailViewHeader = ({ heading, contextMenu, icons }: Props) => {
	const renderIcons = () => {
		if (!icons) {
			return null;
		}

		return (
			<>
				<span className={styles.iconSmall}>{icons.small}</span>
				<span className={styles.iconMedium}>{icons.medium}</span>
			</>
		);
	};

	return (
		<header className={styles.header}>
			<Headline className={styles.heading}>
				{renderIcons()}

				<span className={styles.headingWrap}>{heading}</span>
			</Headline>

			{contextMenu && (
				<ContextMenu showLabel>
					{contextMenu.map(contextMenuItem => (
						<MenuItem
							key={contextMenuItem.label}
							onClick={contextMenuItem.onClick}
							critical={contextMenuItem.critical}
						>
							{contextMenuItem.label}
						</MenuItem>
					))}
				</ContextMenu>
			)}
		</header>
	);
};
