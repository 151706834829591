import { createSlice } from '@reduxjs/toolkit';
import { NQNupsiState } from './types';

export const initialState: NQNupsiState = {
	showModal: false,
};

export const nqnupsiSlice = createSlice({
	name: 'nqnupsi',
	initialState,
	reducers: {
		setShowModal: (state, action) => ({
			...state,
			showModal: action.payload,
		}),
	},
});
