import React from 'react';
import { mergeRefs } from '../../../util/merge-refs';
import { DropdownPosition, HorizontalPosition, VerticalPosition } from './Dropdown';
import { HorizontalOpeningPosition, VerticalOpeningPosition } from './DropdownWithOpener';

interface Props {
	id?: string;
	verticalOpeningPosition: VerticalOpeningPosition;
	horizontalOpeningPosition: HorizontalOpeningPosition;
	onOpen: (focus: 'first' | 'last') => void;
	onClose: () => void;
	opened: boolean;
	children: React.ReactElement<{
		id: string;
		ref: React.Ref<HTMLElement>;
		onClick: React.MouseEventHandler;
		onKeyDown?: React.KeyboardEventHandler;
		'aria-haspopup'?: boolean;
		'aria-expanded'?: boolean;
	}>;
}

/**
 * A helper to wrap e.g. a button which should open a dropdown.
 * Use the public calculateDropdownPosition method via a ref to get the
 * dropdowns position based on the buttons position.
 */
export class DropdownOpener extends React.Component<Props> {
	private ref = React.createRef<HTMLElement>();

	private onClick = (e: React.MouseEvent) => {
		if (this.props.opened) {
			this.props.onClose();
		} else {
			this.props.onOpen('first');
		}

		e.preventDefault();
		e.stopPropagation();
	};

	private onKeyDown = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case 'ArrowDown':
				this.props.onOpen('first');
				break;
			case 'ArrowUp':
				this.props.onOpen('last');
				break;
			default:
				return;
		}

		e.preventDefault();
		e.stopPropagation();
	};

	public calculateDropdownPosition(): DropdownPosition | null {
		if (!this.ref.current) {
			return null;
		}

		if (typeof this.ref.current.getBoundingClientRect !== 'function') {
			return null;
		}

		const rect = this.ref.current.getBoundingClientRect();

		let verticalPosition: VerticalPosition;
		let horizontalPosition: HorizontalPosition;

		if (this.props.horizontalOpeningPosition === 'Left') {
			horizontalPosition = { right: rect.right };
		} else if (this.props.horizontalOpeningPosition === 'Right') {
			horizontalPosition = { left: rect.left };
		} else {
			horizontalPosition = { left: rect.left, right: rect.right };
		}

		if (this.props.verticalOpeningPosition === 'Top') {
			verticalPosition = { bottom: rect.top };
		} else {
			verticalPosition = { top: rect.bottom };
		}

		return {
			...verticalPosition,
			...horizontalPosition,
		};
	}

	public focus() {
		this.ref.current?.focus();
	}

	public render() {
		const child = this.props.children;

		return React.cloneElement(child, {
			id: this.props.id,
			ref: child.props.ref ? mergeRefs(this.ref, child.props.ref) : this.ref,
			'aria-haspopup': true,
			'aria-expanded': this.props.opened ? true : undefined,
			onClick: (e: React.MouseEvent) => {
				const returnValue = child.props.onClick ? child.props.onClick(e) : undefined;

				if (!e.isPropagationStopped() && !e.isDefaultPrevented()) {
					this.onClick(e);
				}

				return returnValue;
			},
			onKeyDown: (e: React.KeyboardEvent) => {
				const returnValue = child.props.onKeyDown ? child.props.onKeyDown(e) : undefined;

				if (!e.isPropagationStopped() && !e.isDefaultPrevented()) {
					this.onKeyDown(e);
				}

				return returnValue;
			},
		});
	}
}
