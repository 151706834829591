import React from 'react';
import classnames from 'classnames';
import { useDisabled } from '../../contexts/disabledContext';
import { usePandaContext } from '../../contexts/pandaContext';
import { useAriaId } from '../../hooks/useAriaId';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';

import classes from './Search.module.css';

type Props = {
	value: string;
	onChange: (value: string) => void;
	placeholder: string;
	disabled?: boolean;
	resultCount: number;
	onBlur?: (e: React.FocusEvent) => void;
	onKeyDown?: (e: React.KeyboardEvent) => void;
	landmark: boolean;
	ariaLabel?: string;
	role?: 'combobox';
	disableAutocomplete?: boolean;
};

const styles = {
	container: (condition: 'default' | 'disabled') =>
		classnames(
			'group',
			'opacity-100' /* required on iOS */,
			'w-full',
			'h-40',
			'relative',
			'ring-1',
			'ring-inset',
			'box-border',
			'rounded',
			'duration-150',
			'ease-in-out',
			'transition',

			condition === 'default' && [
				'bg-neo-color-web-app-component-search-background-default',
				'ring-neo-color-global-border-neutral-moderate-default',
				'focus-within:ring-neo-color-global-border-primary-intense-default',
				'focus-within:hover:ring-neo-color-global-border-primary-intense-default',
				'hover:ring-neo-color-global-border-neutral-moderate-hover',
				'active:ring-neo-color-global-border-primary-intense-active',
			],

			condition === 'disabled' && [
				'ring-neo-color-global-border-static-transparent',
				'bg-neo-color-global-background-neutral-intense-disabled',
			]
		),

	label: (disabled: boolean) =>
		classnames(
			'w-40',
			'h-40',
			'p-12',
			'block',
			'absolute',
			'left-0',
			disabled && 'cursor-not-allowed'
		),

	searchIcon: (condition: 'default' | 'disabled') =>
		classnames(
			'w-16',
			'h-16',
			'block',

			condition === 'default' && [
				'bg-neo-color-global-content-neutral-moderate',
				'group-hover:bg-neo-color-global-content-neutral-intense',
				'group-focus-within:bg-neo-color-global-content-primary-moderate',
				'group-focus-within:group-hover:bg-neo-color-global-content-primary-moderate',
			],

			condition === 'disabled' && ['bg-neo-color-global-content-neutral-disabled']
		),

	input: (condition: 'default' | 'disabled', empty: boolean) =>
		classnames(
			classes.resetNormalize,
			'placeholder:opacity-100', // Fixes firefox because we dont load preflight: https://github.com/tailwindlabs/tailwindcss/issues/3300
			'placeholder:italic',
			'placeholder:font-light',
			'placeholder:select-none',
			'appearance-none',
			'font-brand',
			'font-normal',
			'text-base/24',
			'focus:outline-none',
			'caret-neo-color-global-content-primary-moderate',
			'bg-transparent',
			'truncate',
			'py-8',
			'pl-40',
			'w-full',
			empty ? 'pr-12' : 'pr-40',
			condition === 'default' && [
				'text-neo-color-global-content-neutral-intense',
				'placeholder:neo-color-global-content-neutral-moderate',
				'hover:placeholder:text-neo-color-global-content-neutral-intense',
				'focus:placeholder:text-neo-color-global-content-neutral-intense',
			],
			condition === 'disabled' && [
				'text-neo-color-global-content-neutral-disabled',
				'placeholder:neo-color-global-content-neutral-disabled',
				'cursor-not-allowed',
			]
		),

	clearButton: classnames(
		'w-24',
		'h-24',
		'p-0',
		'block',
		'absolute',
		'right-8',
		'top-8',
		'bg-transparent',
		'cursor-pointer',
		'text-neo-color-global-content-neutral-intense',
		'disabled:cursor-not-allowed',
		'disabled:text-neo-color-global-content-neutral-disabled',
		'hover:text-neo-color-global-content-primary-moderate',
		'active:text-neo-color-global-content-primary-intense'
	),

	clearIcon: classnames('w-16', 'h-16', 'block', 'm-auto'),
};

export const VisualSearch = ({
	value,
	onChange,
	disabled,
	placeholder,
	landmark,
	resultCount,
	onKeyDown,
	onBlur,
	ariaLabel,
	disableAutocomplete,
	...ariaProps
}: Props): JSX.Element => {
	const id = useAriaId('panda-search-input');
	const inputRef = React.useRef<HTMLInputElement>(null);
	const isDisabled = useDisabled(disabled);
	const { languageKeys } = usePandaContext();

	const getCondition = () => {
		if (isDisabled) {
			return 'disabled';
		}

		return 'default';
	};

	const onClear = () => {
		onChange('');

		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	const empty = value.length === 0;

	return (
		<>
			<div role={landmark ? 'search' : undefined} className={styles.container(getCondition())}>
				<label
					className={styles.label(isDisabled)}
					htmlFor={id}
					aria-label={ariaLabel || languageKeys.PANDA_SEARCH_INPUT_LABEL}
				>
					<SetIcon icon="search" size="16" className={styles.searchIcon(getCondition())} />
				</label>

				<input
					id={id}
					ref={inputRef}
					type="search"
					placeholder={placeholder}
					value={value}
					onChange={e => onChange(e.target.value)}
					onKeyDown={onKeyDown}
					onBlur={onBlur}
					disabled={isDisabled}
					className={styles.input(getCondition(), empty)}
					autoComplete={disableAutocomplete ? 'off' : undefined}
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...ariaProps}
				/>

				{empty ? null : (
					<button
						onClick={onClear}
						disabled={isDisabled}
						aria-label={languageKeys.PANDA_SEARCH_INPUT_CLEAR}
						aria-hidden
						tabIndex={-1}
						className={styles.clearButton}
						type="button"
					>
						<SetIcon icon="close" size="16" className={styles.clearIcon} />
					</button>
				)}
			</div>
			<div role="status" aria-live="polite" className="sr-only">
				{resultCount}&nbsp;{languageKeys.PANDA_SEARCH_RESULTS}
			</div>
		</>
	);
};
