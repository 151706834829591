import classnames from 'classnames';
import serviceUrls from '@web-apps/service-urls';
import React from 'react';
import useTranslations from '../../../hooks/translations';
import { SipgateDomain, SipgateProduct } from '../../../redux/slices/signup';
import classes from './SocialButton.scss';
import useSipgateDomain from '../../../hooks/SipgateDomain';
import useSipgateProduct from '../../../hooks/SipgateProduct';

type Provider = 'google' | 'apple';

interface Props {
	provider: Provider;
	label: string;
}

const redirectUrl = (provider: Provider, domain: SipgateDomain, product: SipgateProduct) => {
	const redirectUri = encodeURIComponent(
		`${serviceUrls.appWeb}/implicit-auth-redirect?redirect=/&from_idp=true&idp=${provider}`
	);
	const keycloakUrl = serviceUrls.authentication.keycloak;
	return `${keycloakUrl}/realms/sipgate-apps/protocol/openid-connect/auth?response_type=token&client_id=sipgate-app-web&kc_idp_hint=${provider}&product=${product}&domain=${domain}&redirect_uri=${redirectUri}`;
};

const SocialButton = ({ provider, label }: Props) => {
	const { translate } = useTranslations();
	const { domain } = useSipgateDomain();
	const { product } = useSipgateProduct();

	return (
		<button
			type="button"
			className={classnames(classes.idp, classes[`${provider}`])}
			onClick={() => {
				window.parent.location.href = redirectUrl(provider, domain, product);
			}}
		>
			<span>{translate(label)}</span>
		</button>
	);
};

export default SocialButton;
