import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	PartialSignupData,
	PartialSignupDataWithEmail,
	SignupCreatedResponse,
	SignupData,
	SignupResponse,
} from '../../../types';
import api from '../../../api';
import {
	ActivationNotFoundError,
	AlreadyActivatedError,
	CouldNotActivatePendingSubscriber,
	CouldNotCreatePartialSignup,
	CouldNotCreatePendingSubscriber,
	CouldNotFindSignup,
	EmailAlreadyExists,
	GeneralSignupError,
	GenericResendActivationEmailError,
	RecipientNotFoundError,
	RootSignupState,
	SignupActivationEmailResendErrors,
	SignupErrors,
} from './types';
import {
	ACTIVATION_ALREADY_FINISHED,
	ACTIVATION_NOT_FOUND,
} from '../../../api/clients/errors/ActivationApiError';
import { signupSlice } from './reducer';
import {
	getDomain,
	getIdentifier,
	getIdentityProvider,
	getProduct,
	isActivating,
	isFetching,
	isPartialCreating,
	isPendingCreating,
	isResending,
} from './selectors';
import { RootState } from '../../setupStore';
import { SIGNUP_NOT_FOUND_ERROR } from '../../../api/clients/errors/SignupApiError';

export const createPartialSignup = createAsyncThunk<
	SignupCreatedResponse,
	{ email: string },
	{ rejectValue: SignupErrors; state: RootSignupState }
>(
	'signup/createPartialSignup',
	async (data, { rejectWithValue, dispatch, getState }) => {
		dispatch(signupSlice.actions.setEmail(data.email));
		const signupData = {
			email: data.email,
			domain: getDomain(getState()),
			product: getProduct(getState()),
			country: getDomain(getState()) === 'sipgate.de' ? 'DE' : 'GB',
			identityProvider: 'SIPGATE',
		} as PartialSignupDataWithEmail;

		const result = await api.createPartialSignup(signupData);

		if (result.isOk()) {
			return result.value;
		}

		const error = result.error;
		switch (error.name) {
			case 'EMAIL_ALREADY_EXISTS_ERROR':
				return rejectWithValue(EmailAlreadyExists(error.message));
			default:
				return rejectWithValue(CouldNotCreatePartialSignup(error.message));
		}
	},
	{
		condition(data, { getState }) {
			return !isPartialCreating(getState());
		},
	}
);

export const createSignup = createAsyncThunk<
	SignupCreatedResponse,
	PartialSignupData,
	{ rejectValue: SignupErrors; state: RootSignupState }
>(
	'signup/createSignup',
	async (data, { rejectWithValue, dispatch, getState }) => {
		dispatch(signupSlice.actions.setEmail(data.email));

		const signupData = {
			...data,
			domain: getDomain(getState()),
			product: getProduct(getState()),
			identityProvider: getIdentityProvider(getState()),
		} as SignupData;

		const identifier = getIdentifier(getState());

		const result = identifier
			? await api.createSignup(signupData, identifier)
			: await api.signup(signupData);

		if (result.isOk()) {
			return result.value;
		}

		const error = result.error;
		switch (error.name) {
			case 'EMAIL_ALREADY_EXISTS_ERROR':
				return rejectWithValue(EmailAlreadyExists(error.message));
			default:
				return rejectWithValue(CouldNotCreatePendingSubscriber(error.message));
		}
	},
	{
		condition(data, { getState }) {
			return !isPendingCreating(getState());
		},
	}
);

export const resendActivation = createAsyncThunk<
	void,
	string,
	{ rejectValue: SignupActivationEmailResendErrors; state: RootSignupState }
>(
	'signup/resendActivation',
	async (identifier, { rejectWithValue }) => {
		const result = await api.resendActivationEmail(identifier);

		if (result.isOk()) {
			return;
		}

		const error = result.error;
		switch (error.apiErrorCode) {
			case ACTIVATION_NOT_FOUND:
				return rejectWithValue(RecipientNotFoundError(error.message));
			default:
				return rejectWithValue(GenericResendActivationEmailError(error.message));
		}
	},
	{
		condition(identifier, { getState }) {
			return !isResending(getState());
		},
	}
);

export const activateSignup = createAsyncThunk<
	SignupCreatedResponse,
	string,
	{ rejectValue: SignupErrors; state: Pick<RootState, 'signup'> }
>(
	'signup/activateSignup',
	async (code, { rejectWithValue }) => {
		const result = await api.activate(code);

		if (result.isOk()) {
			return result.value;
		}

		const error = result.error;
		switch (error.apiErrorCode) {
			case ACTIVATION_ALREADY_FINISHED:
				return rejectWithValue(AlreadyActivatedError(error.message));
			case ACTIVATION_NOT_FOUND:
				return rejectWithValue(ActivationNotFoundError(error.message));
			default:
				return rejectWithValue(CouldNotActivatePendingSubscriber(error.message));
		}
	},
	{
		condition(code, { getState }) {
			return !isActivating(getState());
		},
	}
);

export const getSignup = createAsyncThunk<
	SignupResponse,
	string,
	{ rejectValue: SignupErrors; state: Pick<RootState, 'signup'> }
>(
	'signup/getSignup',
	async (identifier, { rejectWithValue }) => {
		const result = await api.getSignup(identifier);

		if (result.isOk()) {
			return result.value;
		}

		const error = result.error;
		switch (error.apiErrorCode) {
			case SIGNUP_NOT_FOUND_ERROR:
				return rejectWithValue(CouldNotFindSignup(error.message));
			default:
				return rejectWithValue(GeneralSignupError());
		}
	},
	{
		condition(identifier, { getState }) {
			return !isFetching(getState());
		},
	}
);
