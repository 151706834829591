import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { usePusherContext } from '../../components/PusherContext';
import useSignupData from '../../hooks/SignupData';

const Activate = () => {
	const history = useHistory();
	const { key } = useParams<{ key: string }>();

	const { signupData, activateSignup } = useSignupData();
	const { activated, identifier } = signupData;

	const pusher = usePusherContext();

	useEffect(() => {
		activateSignup(key);
	}, [key, history, activateSignup]);

	useEffect(() => {
		if (identifier) {
			pusher.client.then(client => {
				return client.listen<{ url: string }>(
					identifier,
					'login_possible',
					(event: { url: string }) => {
						window.location.href = event.url;
					}
				);
			});
		}
	}, [identifier, pusher.client]);

	useEffect(() => {
		if (activated && identifier) {
			history.push(`/status/${identifier}`);
		}
	}, [activated, history, identifier]);

	return <></>;
};

export default Activate;
