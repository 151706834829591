import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { InternalLink } from './links/InternalLink';
import { ExternalLink } from './links/ExternalLink';
import { Button, Icons } from './button/Button';
import { ContextMenu } from './contextMenu/ContextMenu';

type ActionElementDefinition = {
	label: string;
	loud?: boolean;
};
export type ButtonDefinition = ActionElementDefinition & {
	type: 'button';
	onClick: () => void;
	icon?: Icons;
	disabled?: boolean;
};

export type LinkDefinition = ActionElementDefinition & {
	type: 'link';
	to: string;
	direction?: 'internal' | 'external';
};

export type ContextDefinition = ActionElementDefinition & {
	type: 'contextMenu';
	items: ReactElement[];
};

export type AllActionElementDefinition = LinkDefinition | ButtonDefinition | ContextDefinition;

type Props = { element: AllActionElementDefinition };

const styles = {
	mobileElement: classnames('hidden', 'pointer-coarse:flex'),
	desktopElement: classnames('flex', 'pointer-coarse:hidden'),
};

const renderLink = (element: LinkDefinition) => {
	if (element.direction === 'external') {
		return (
			<>
				<div className={styles.desktopElement}>
					<ExternalLink
						target="blank"
						button
						to={element.to}
						size="medium"
						variant={element.loud ? 'loud' : 'normal'}
					>
						{element.label}
					</ExternalLink>
				</div>
				<div className={styles.mobileElement}>
					<ExternalLink
						target="blank"
						button
						to={element.to}
						size="xlarge"
						variant={element.loud ? 'loud' : 'normal'}
					>
						{element.label}
					</ExternalLink>
				</div>
			</>
		);
	}

	return (
		<>
			<div className={styles.desktopElement}>
				<InternalLink
					button
					to={element.to}
					size="medium"
					variant={element.loud ? 'loud' : 'normal'}
				>
					{element.label}
				</InternalLink>
			</div>
			<div className={styles.mobileElement}>
				<InternalLink
					button
					to={element.to}
					size="xlarge"
					variant={element.loud ? 'loud' : 'normal'}
				>
					{element.label}
				</InternalLink>
			</div>
		</>
	);
};

const renderButton = (element: ButtonDefinition) => {
	return (
		<>
			<div className={styles.desktopElement}>
				<Button
					icon={element.icon}
					onClick={element.onClick}
					size="medium"
					variant={element.loud ? 'loud' : 'normal'}
					disabled={element.disabled}
				>
					{element.label}
				</Button>
			</div>
			<div className={styles.mobileElement}>
				<Button
					icon={element.icon}
					onClick={element.onClick}
					size="xlarge"
					variant={element.loud ? 'loud' : 'normal'}
					disabled={element.disabled}
				>
					{element.label}
				</Button>
			</div>
		</>
	);
};

const renderContextMenu = (element: ContextDefinition) => {
	return (
		<>
			<ContextMenu showLabel>{element.items}</ContextMenu>
		</>
	);
};

export const ActionElement = ({ element }: Props): JSX.Element => {
	if (element.type === 'link') {
		return renderLink(element);
	}
	if (element.type === 'contextMenu') {
		return renderContextMenu(element);
	}
	return renderButton(element);
};
