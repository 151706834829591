import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { HeadingContext } from '../../contexts/HeadingContext';
import { usePandaContext } from '../../contexts/pandaContext';

const getHeadline = (level: number) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		default:
			return 'h6';
	}
};

type Props = {
	/**
	 * Macht die Überschrift nur für Screenreader sichtbar.
	 */
	screenreaderOnly?: boolean;
};

const getClassNames = (screenreaderOnly?: boolean, propClassnames?: string) => {
	if (screenreaderOnly) {
		return classnames(propClassnames, 'sr-only');
	}
	return propClassnames;
};

export const Headline = forwardRef(
	(
		{ screenreaderOnly, className, ...props }: React.HTMLProps<HTMLHeadingElement> & Props,
		ref: React.Ref<HTMLHeadingElement>
	) => {
		const { languageKeys } = usePandaContext();

		return (
			<HeadingContext.Consumer>
				{level => {
					const Heading = getHeadline(level.level);

					if (level.level === 1 && typeof props.children === 'string') {
						return (
							<>
								<Heading
									/* eslint-disable-next-line react/jsx-props-no-spreading */
									{...props}
									ref={ref}
									className={getClassNames(screenreaderOnly, className)}
								/>
								<Helmet titleTemplate={`%s${languageKeys.PANDA_TITLE_SUFFIX}`}>
									<title>{props.children}</title>
								</Helmet>
							</>
						);
					}
					return (
						<Heading
							/* eslint-disable-next-line react/jsx-props-no-spreading */
							{...props}
							ref={ref}
							className={getClassNames(screenreaderOnly, className)}
						/>
					);
				}}
			</HeadingContext.Consumer>
		);
	}
);
