import classNames from 'classnames';
import React, { forwardRef } from 'react';

import classes from './VisualInput.module.css';

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> & {
	condition: 'default' | 'error' | 'disabled';
	passwordInput?: boolean;
};

const styles = {
	input: (condition: 'default' | 'error' | 'disabled', passwordInput: boolean) =>
		classNames(
			classes.hideEye,
			'appearance-none',
			'opacity-100' /* required on iOS */,
			'w-full',
			'h-40',
			'p-8',
			'm-0',
			'font-brand',
			'font-normal',
			'text-base/24',
			'ring-1',
			'ring-inset',
			'box-border',
			'rounded-sm',
			'duration-150',
			'ease-in-out',
			'transition',
			'focus:outline-none',
			'placeholder:italic',
			'placeholder:font-light',
			'placeholder:select-none',
			'caret-neo-color-global-content-primary-intense',
			'truncate',
			'placeholder:opacity-100', // Fixes firefox because we dont load preflight: https://github.com/tailwindlabs/tailwindcss/issues/3300
			condition === 'default' && [
				'text-neo-color-global-content-neutral-intense',
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-neutral-moderate-default',
				'hover:ring-neo-color-global-border-neutral-moderate-hover',
				'active:ring-neo-color-global-border-primary-intense-active',
				'focus:ring-neo-color-global-border-primary-intense-active',
				'placeholder:text-neo-color-global-content-neutral-moderate',
				'hover:placeholder:text-neo-color-global-content-neutral-intense',
				'focus-visible:placeholder:text-neo-color-global-content-neutral-intense',
			],
			condition === 'error' && [
				'text-neo-color-global-content-neutral-intense',
				'bg-neo-color-global-component-input-background-default',
				'ring-neo-color-global-border-neutral-moderate-default',
				'hover:ring-neo-color-global-border-neutral-moderate-hover',
				'active:ring-neo-color-global-border-critical-intense-active',
				'focus:ring-neo-color-global-border-critical-intense-active',
				'placeholder:text-neo-color-global-content-neutral-moderate',
				'hover:placeholder:text-neo-color-global-content-neutral-moderate',
				'focus-visible:placeholder:text-neo-color-global-content-neutral-moderate',
				'caret-neo-color-global-content-critical-moderate',
			],
			!passwordInput && condition === 'error' && 'pr-40',
			passwordInput && 'font-mono placeholder:font-brand',
			passwordInput && condition === 'error' && 'pr-64',
			passwordInput && condition !== 'error' && 'pr-40',
			condition === 'disabled' && [
				'ring-neo-color-global-border-static-transparent',
				'bg-neo-color-global-background-neutral-intense-disabled',
				'text-neo-color-global-content-neutral-disabled',
				'placeholder:text-neo-color-global-content-neutral-disabled',
				'disabled:cursor-not-allowed',
			]
		),
};

const VisualInput = forwardRef<HTMLInputElement, Props>(
	({ condition, passwordInput = false, ...inputProps }, ref) => {
		return (
			<input
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...inputProps}
				className={styles.input(condition, passwordInput)}
				ref={ref}
			/>
		);
	}
);

export { VisualInput };
