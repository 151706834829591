import React from 'react';
import { InternalLink } from '@panda/ui';
import { useLocation } from 'react-router';
import { isNqDomain } from './NQNupsi';
import classes from './NQActivate.scss';

const NqActivate = () => {
	const location = useLocation<{ activationCode: string | undefined }>();

	if (isNqDomain() && location.state && location.state.activationCode) {
		return (
			<div className={classes.spacingTop}>
				<InternalLink button to={`/activate/${location.state.activationCode}`}>
					Account aktivieren
				</InternalLink>
			</div>
		);
	}

	return <></>;
};

export default NqActivate;
