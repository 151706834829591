/* eslint-disable react/no-unused-prop-types */
import React, { ComponentProps } from 'react';
import { Option } from './Option';

type Props = {
	label: string;
	children:
		| React.ReactElement<ComponentProps<typeof Option>>
		| React.ReactElement<ComponentProps<typeof Option>>[];
};

/**
 * This components exist so the usage of <Select/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OptGroup = (props: Props) => null;
