import { Tac, TacsState } from './types';
import { RootState } from '../../setupStore';

export const getTacState = (state: RootState) => state.tacs;

export const getTacs = (state: RootState) => state.tacs.items;

export const getTacByType = (state: TacsState, type: string) =>
	state.items.filter((tac: Tac) => {
		return tac.type === type;
	});

export const getAllTacs = (state: TacsState) => state.items;
