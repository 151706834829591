import classnames from 'classnames';
import React from 'react';
import { ContextMenu } from '../../../components/contextMenu/ContextMenu';
import { MenuItem } from '../../../components/contextMenu/MenuItem';
import { Headline } from '../../../components/heading/Headline';
import { State } from '../../../components/state/State';
import { EntitySwitcher, EntitySwitcherProps } from '../EntitySwitcher';

import classes from './SideBarEntityHeader.module.css';
import { usePandaContext } from '../../../contexts/pandaContext';

const styles = {
	topRow: (showLabel: boolean) =>
		classnames(
			'pb-[3.625rem]',
			'flex',
			'items-start',
			showLabel ? 'justify-between' : 'justify-end'
		),
	label: classnames(
		'bg-neo-color-global-background-neutral-soft-default',
		'text-neo-color-global-content-neutral-intense',
		'rounded',
		'px-12',
		'py-8',
		'text-sm/16',
		'cursor-default'
	),
	avatarWrapper: classnames(
		'mt-[-5.25rem]',
		'flex',
		'items-center',
		'justify-center',
		'bg-neo-color-web-app-component-sidebarhero-headergraphic-circle-background-default',
		'w-[6.5rem]',
		'h-[6.5rem]',
		'rounded-full',
		'mx-auto'
	),
	heading: classnames('sr-only'),
	container: classnames(
		classes.gradient,
		'px-16',
		'pt-16',
		'flex',
		'flex-col',
		'gap-16',
		'relative'
	),
	skipToContentLink: classnames(
		'sr-only',
		'focus:not-sr-only',
		'focus:absolute',
		// mimics screaming link button
		'focus:font-bold',
		'focus:no-underline',
		'focus:p-8',
		'focus:p-16',
		'focus:bg-neo-color-global-background-electric-intense-default',
		'focus:hover:bg-neo-color-global-background-electric-intense-hover',
		'focus:active:bg-neo-color-global-background-electric-intense-active',
		'focus:text-neo-color-global-content-electric-on-intense',
		'focus:hover:text-neo-color-global-content-electric-on-intense',
		'focus:active:text-neo-color-global-content-electric-on-intense',
		'focus:rounded',
		'focus:z-800',
		'focus-visible:ring-focus-inset'
	),
};

type StatusConfiguration = {
	text: string;
	color: 'red' | 'orange' | 'green';
};

export type Props<T extends { id: string }> = {
	/**
	 * Der Name welcher für die gesamte Seite per Screenreader kommuniziert wird.
	 * Dies sollte fast immer dem Heading des ausgewählten Objekts entsprechen.
	 */
	heading: string;

	/**
	 * Die Grafik (z.Bsp. den [Avatar](../?path=/docs/components-avatar-overview--docs)), welche das aktuell ausgewählte Objekt repräsentiert.
	 * Sie sollte mindestens 104x104 Pixel groß sein.
	 */

	graphic: React.ReactNode;

	/** Einstellungen für den Switcher welcher zwischen gleichartigen Objekten wechselt. */
	entitySwitcher: EntitySwitcherProps<T>;

	/**
	 * Eine Rolle die dieses Objekt einnimmt (bspw. Admin oder Standard).
	 */

	role?: string;
	/**
	 * Ein Zustand dieses Objekts (bspw. DND oder offline).
	 */
	status?: StatusConfiguration;

	/**
	 * Ein Array von `ContextMenu`-Items.
	 */
	contextMenu?: { label: string; onClick: () => void; critical?: boolean }[];
};

const renderRoleOrStatus = (role?: string, status?: StatusConfiguration) => {
	if (role) {
		return <div className={styles.label}>{role}</div>;
	}

	if (status) {
		return (
			<State size="medium" color={status.color}>
				{status.text}
			</State>
		);
	}

	return null;
};

export const SideBarEntityHeader = <T extends { id: string }>({
	heading,
	graphic,
	entitySwitcher,
	role,
	status,
	contextMenu,
}: Props<T>): JSX.Element => {
	const { languageKeys } = usePandaContext();

	return (
		<div className={styles.container}>
			<Headline className={styles.heading}>{heading}</Headline>
			<div className={styles.topRow(!!(role || status))}>
				{renderRoleOrStatus(role, status)}
				<a href="#content" className={styles.skipToContentLink}>
					{languageKeys.PANDA_SIDEBAR_NAVIGATION_SKIP_TO_CONTENT_LINK}
				</a>
				{contextMenu && (
					<ContextMenu>
						{contextMenu.map(contextMenuItem => (
							<MenuItem
								key={contextMenuItem.label}
								onClick={contextMenuItem.onClick}
								critical={contextMenuItem.critical}
							>
								{contextMenuItem.label}
							</MenuItem>
						))}
					</ContextMenu>
				)}
			</div>
			<div className={styles.avatarWrapper}>{graphic}</div>
			<EntitySwitcher
				entities={entitySwitcher.entities}
				onChange={entitySwitcher.onChange}
				searchAriaLabel={entitySwitcher.searchAriaLabel}
				searchPlaceholder={entitySwitcher.searchPlaceholder}
			/>
		</div>
	);
};

// Dummy Component for documentation only
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EntitySwitcherDocs = <T extends { id: string }>(props: EntitySwitcherProps<T>) => (
	<></>
);
