declare const ClientJS: {
	new (): {
		getFingerprint: () => string;
	};
};

export const getFingerprint = (): string => {
	try {
		const client = new ClientJS();
		return client.getFingerprint();
	} catch (e) {
		return '';
	}
};
