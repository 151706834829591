import React from 'react';
import { createRoot } from 'react-dom/client';

// Make sure styles are loaded
import './styles/app.scss';
import { Provider } from 'react-redux';
import setupStore from './redux/setupStore';
import SignupApp from './SignupApp';
import PandaProvider from './PandaProvider';

createRoot(document.getElementById('root')!).render(
	<>
		<Provider store={setupStore()}>
			<PandaProvider>
				<SignupApp />
			</PandaProvider>
		</Provider>
	</>
);
