import React from 'react';
import classnames from 'classnames';
import { useDisabled } from '../../contexts/disabledContext';

type Props = {
	children: string;
	critical?: boolean;
	onClick: () => void;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs) */
	disabled?: boolean;
};

const styles = {
	listItem: classnames('min-w-96', 'w-full'),
	button: (isCritical: boolean) =>
		classnames(
			'bg-neo-color-global-background-static-transparent',
			'cursor-pointer',
			'disabled:bg-neo-color-global-background-primary-intense-disabled',
			'disabled:cursor-not-allowed',
			'disabled:text-neo-color-global-content-primary-disabled',
			'duration-150',
			'ease-in-out',
			'focus:outline-none',
			'focus-visible:outline-none',
			'focus-visible:ring-focus-inset',
			'font-brand',
			'font-normal',
			'text-base/24',
			'h-full',
			'px-16',
			'py-12',
			'select-none',
			'text-left',
			'transition',
			'w-full',
			'whitespace-nowrap',
			!isCritical && [
				'text-neo-color-global-content-neutral-intense',
				'hover:text-neo-color-global-content-primary-intense',
				'hover:bg-neo-color-global-background-primary-soft-hover',
				'active:text-neo-color-global-content-primary-intense',
				'active:bg-neo-color-global-background-primary-soft-active',
			],
			isCritical && [
				'text-neo-color-global-content-critical-moderate',
				'hover:bg-neo-color-global-background-critical-soft-hover',
				'active:bg-neo-color-global-background-critical-soft-active',
			]
		),
};

const MenuItem = ({ onClick, children, critical = false, disabled: disabledProp }: Props) => {
	const disabled = useDisabled(disabledProp);

	return (
		<li role="menuitem" className={styles.listItem} aria-disabled={disabled}>
			<button
				className={styles.button(critical)}
				type="button"
				disabled={disabled}
				onClick={onClick}
				tabIndex={-1}
			>
				{children}
			</button>
		</li>
	);
};

export { MenuItem };
