import { HeadingBoundary, Headline, ManagedInput } from '@panda/ui';
import { ManagedForm } from '@web-apps/forms';
import { Translate } from '@web-apps/translations';
import classnames from 'classnames';
import { FormikContextType, useFormikContext } from 'formik';
import React from 'react';

import useSignupData from '../../../hooks/SignupData';
import useTranslations from '../../../hooks/translations';
import { SignupState } from '../../../redux/slices/signup';
import EmailField from '../../fields/EmailField';
import NQNupsi from '../../nqNupsi/NQNupsi';
import SocialUser from '../../social/SocialUser/SocialUser';
import classes from './LoginGroup.scss';

const getHeadline = (signupState: SignupState, translate: Translate, firstname: string) => {
	let headlineLk = 'SIGNUP_EMAIL_LOGIN_HEADLINE';

	if (signupState.identityProvider !== 'SIPGATE') {
		headlineLk = 'SIGNUP_SOCIAL_LOGIN_HEADLINE';
	}

	return (
		<>
			<NQNupsi />
			<Headline id="LoginGroupHeadline" className={classes.headline}>
				{translate(headlineLk, firstname)}
			</Headline>
			<p className={classes.subline}>{translate('SIGNUP_LOGIN_SUBLINE')}</p>
		</>
	);
};

const getContent = (
	signupState: SignupState,
	translate: Translate,
	formik: FormikContextType<{ email: string; password: string; firstname: string }>
) => {
	if (signupState.identityProvider !== 'SIPGATE') {
		return <SocialUser />;
	}

	return (
		<>
			<div className={classnames(classes.row, classes.width6, classes.mobilefw)}>
				<EmailField />
			</div>
			<div className={classnames(classes.row, classes.width6, classes.mobilefw)}>
				<ManagedInput
					managedField={ManagedForm.buildField(formik, 'password')}
					label={translate('SIGNUP_LOGIN_PASSWORD_LABEL')}
					placeholder=""
					type="password"
					description={translate('SIGNUP_LOGIN_PASSWORD_DESCRIPTION')}
					minLength={12}
				/>
			</div>
		</>
	);
};

const LoginGroup = () => {
	const { translate } = useTranslations();
	const formik = useFormikContext<{ email: string; password: string; firstname: string }>();
	const { signupData } = useSignupData();

	return (
		<div
			id="LoginGroup"
			className={classnames(
				classes.group,
				classes.column4,
				signupData.identityProvider && classes[`${signupData.identityProvider}`]
			)}
			role="region"
			aria-labelledby="LoginGroupHeadline"
		>
			{getHeadline(signupData, translate, formik.values.firstname)}
			<HeadingBoundary>
				<Headline>{translate('SIGNUP_LOGIN_HEADLINE')}</Headline>
				{getContent(signupData, translate, formik)}
			</HeadingBoundary>
		</div>
	);
};

export default LoginGroup;
