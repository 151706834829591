import { Tac } from '../redux/slices/tacs';
import { useAppSelector } from './redux';
import { getTacs } from '../redux/slices/tacs/selectors';

interface TacsHook {
	tacs: Tac[];
}

export const useTacs = (): TacsHook => {
	return {
		tacs: useAppSelector(getTacs),
	};
};

export default useTacs;
