import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { getCity, getZip, hasStreetsFetched, isFetching } from './selectors';
import { addressesSlice } from './reducer';
import { AddressError, AddressErrors, RootAddressState } from './types';
import { UkAddressResponse } from '../../../api/types/UkAddressResponse';
import { INVALID_ZIP_CODE } from '../../../api/clients/errors/AddressApiError';
import { isUKPostcode } from '../../../utils';

export const fetchCities = createAsyncThunk<
	string[],
	string,
	{ rejectValue: AddressError; state: RootAddressState }
>(
	'addresses/fetchCities',
	async (zip, { rejectWithValue }) => {
		const result = await api.getCities(zip);

		if (result.isOk()) {
			return result.value;
		}

		const error = result.error;
		if (error.apiErrorCode === INVALID_ZIP_CODE) {
			return rejectWithValue(AddressErrors.InvalidZipCodeError(error.message));
		}

		return rejectWithValue(AddressErrors.GetCitiesError(error.message));
	},
	{
		condition(data, { getState }) {
			return !isFetching(getState());
		},
	}
);

export const fetchStreets = createAsyncThunk<
	string[],
	{ zip: string; city: string },
	{ rejectValue: AddressError; state: RootAddressState }
>(
	'addresses/fetchStreets',
	async (data: { zip: string; city: string }, { rejectWithValue }) => {
		const result = await api.getStreets(data.zip, data.city);

		if (result.isOk()) {
			return result.value;
		}

		const error = result.error;
		return rejectWithValue(AddressErrors.GetStreetsError(error.message));
	},
	{
		condition(data, { getState }) {
			return !isFetching(getState()) && !(data.zip === '' || data.city === '');
		},
	}
);

export const setCity = createAsyncThunk<void, string, { state: RootAddressState }>(
	'addresses/setCity',
	async (city: string, { dispatch, getState }) => {
		dispatch(addressesSlice.actions.setCity(city));

		const zip = getZip(getState());

		await dispatch(fetchStreets({ zip, city }));
	},
	{
		condition(city, { getState }) {
			if (city === '') {
				return false;
			}

			if (getCity(getState()) === city && hasStreetsFetched(getState())) {
				return false;
			}

			return !isFetching(getState());
		},
	}
);

export const fetchUKAddresses = createAsyncThunk<
	UkAddressResponse[],
	string,
	{ rejectValue: AddressError; state: RootAddressState }
>(
	'addresses/fetchUKAddresses',
	async (postcode: string, { dispatch, rejectWithValue }) => {
		dispatch(addressesSlice.actions.setZip(postcode));

		const result = await api.getGbAddress(postcode);

		if (result.isOk()) {
			return result.value;
		}

		const error = result.error;
		return rejectWithValue(AddressErrors.GetUKAddressesError(error.message));
	},
	{
		condition(data, { getState }) {
			return !isFetching(getState());
		},
	}
);

export const setZip = createAsyncThunk<
	string,
	{ zip: string; country: string },
	{ state: RootAddressState }
>(
	'addresses/setZip',
	async (data: { zip: string; country: string }, { dispatch }) => {
		const zip = data.zip.trim();
		dispatch(addressesSlice.actions.setZip(zip));
		dispatch(addressesSlice.actions.setCountry(data.country));

		if (data.country === 'DE') {
			const result = await dispatch(fetchCities(zip));

			if (result.meta.requestStatus === 'fulfilled' && (result.payload as string[]).length === 1) {
				await dispatch(setCity((result.payload as string[])[0]));
			}
		} else if (data.country === 'GB') {
			await dispatch(fetchUKAddresses(zip));
		}

		return zip;
	},
	{
		condition(data, { getState }) {
			if (data.country === 'DE' && data.zip.length < 5) {
				return false;
			}

			if (data.country === 'GB' && !isUKPostcode(data.zip.trim())) {
				return false;
			}

			if (getZip(getState()) === data.zip.trim()) {
				return false;
			}

			return !isFetching(getState());
		},
	}
);
