import React from 'react';
import classnames from 'classnames';
import { ActionElement, ButtonDefinition, LinkDefinition } from '../ActionElement';
import { Headline } from '../heading/Headline';

type Props = {
	/**
	 * Überschrift für die kurze Erläuterung. Sollte nicht das selbe wie die Überschrift der Seite oder des Abschnitts sein.
	 */
	heading: string;
	/**
	 * Ein kurzer Text zur Erläuterung, um was es an dieser Stelle eigentlich geht.
	 */
	text: React.ReactNode;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Hinzufügen oder Buchen.
	 *
	 * **LinkDefinition**:
	 *
	 * `{ type: 'link'; to: string; label: string; loud?: boolean; direction?: 'internal' | 'external' }`
	 *
	 * **ButtonDefinition**:
	 *
	 * `{ type: 'button'; onClick: () => void; label: string; loud?: boolean; disabled?: boolean }`
	 */
	actionElements: (LinkDefinition | ButtonDefinition)[];
	/**
	 *
	 * Die Illustration die angezeigt werden soll.
	 */
	image: React.ReactNode;
};

const styles = {
	container: classnames(
		'flex',
		'max-w-full',
		'lg:gap-40',
		'flex-col',
		'lg:flex-row',
		'items-center',
		'justify-center'
	),
	imageContainer: classnames(
		'flex',
		'justify-around',
		'lg:justify-end ',
		'max-w-full',
		'sm:max-w-md',
		'select-none'
	),
	contentContainer: classnames('max-w-full', 'sm:max-w-md'),
	headline: classnames(
		'font-brand',
		'font-bold',
		'text-neo-color-global-content-neutral-intense',
		'text-lg/24',
		'lg:text-xl/24',
		'mt-16',
		'lg:mt-64',
		'mb-8',
		'hyphens'
	),
	text: classnames(
		'font-brand',
		'text-neo-color-global-content-neutral-intense',
		'text-base/24',
		'mt-8',
		'mb-16',
		'lg:my-16',
		'hyphens'
	),
	actionElements: classnames('flex', 'gap-8', 'flex-row', 'flex-wrap'),
};

const EmptyState = ({ heading, text, actionElements, image }: Props): JSX.Element => {
	const renderActionElements = () => {
		return (
			<div className={styles.actionElements}>
				{actionElements.map((element, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<ActionElement key={i} element={element} />
				))}
			</div>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.imageContainer}>{image}</div>
			<div className={styles.contentContainer}>
				<Headline className={styles.headline}>{heading}</Headline>
				<p className={styles.text}>{text}</p>
				<div>{renderActionElements()}</div>
			</div>
		</div>
	);
};

export { EmptyState };
